var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var fta,gta,ita,jta,kta,lta;fta=function(a,b){return $CLJS.EA(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};gta=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.hg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.ec($CLJS.N),b))};ita=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Rk.g($CLJS.iE(function(b){return $CLJS.RW(b,a)}),$CLJS.hf.h($CLJS.hd)),hta)};
jta=function(a){return new $CLJS.h(null,3,[$CLJS.nj,$CLJS.uB,$CLJS.T,a,$CLJS.$i,$CLJS.p.h($CLJS.KE())],null)};
kta=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.T);return $CLJS.il.l($CLJS.H([a,function(){var c=$CLJS.nh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.I(c,0,null),c=$CLJS.I(c,1,null),c=$CLJS.rW(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.nj,$CLJS.uN,$CLJS.bR,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.nj,$CLJS.FM,$CLJS.rM,c],null):null}(),$CLJS.n($CLJS.oE.h(a))?null:new $CLJS.h(null,
1,[$CLJS.oE,$CLJS.y1.g($CLJS.x1,b)],null)]))};$CLJS.J8=function(a){a=$CLJS.q1($CLJS.Q_(a));return $CLJS.n($CLJS.zA(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eA,lta],null)))?$CLJS.be.g($CLJS.bh,$CLJS.kO):$CLJS.bh};$CLJS.K8=function(a,b){var c=$CLJS.J8(a);return(0,$CLJS.OW)(a,0,function(d){var e=$CLJS.eu.g(mta,c);d=$CLJS.Se.j($CLJS.Jk,d,e);d=$CLJS.il.l($CLJS.H([d,$CLJS.gm(b,c)]));$CLJS.eu.g(c,$CLJS.fh($CLJS.Xg(b)));$CLJS.DW(a,0);return d})};$CLJS.L8=function(a){return $CLJS.Fe($CLJS.gm($CLJS.DW(a,0),$CLJS.J8(a)))};
$CLJS.M8=function(a){return $CLJS.hQ.h($CLJS.DW(a,0))};lta=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);$CLJS.nta=new $CLJS.M(null,"write","write",-1857649168);$CLJS.ota=new $CLJS.M(null,"native-permissions","native-permissions",1277672164);var hta,mta;hta=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.N8=function(){function a(d,e){d=$CLJS.Fe(ita(d));var f=$CLJS.Fe($CLJS.fh($CLJS.Xg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.eu.g(d,f);d=$CLJS.eu.g(f,d);if($CLJS.E.l(1,$CLJS.D(k),$CLJS.H([$CLJS.D(d)]))){d=$CLJS.A(d);k=$CLJS.A(k);f=$CLJS.J.g(e,d);var l=$CLJS.E.g($CLJS.oE.h(f),$CLJS.y1.g($CLJS.x1,d))?$CLJS.y1.g($CLJS.x1,k):$CLJS.oE.h(f);f=$CLJS.R.l($CLJS.Jk.l(f,$CLJS.rM,$CLJS.H([$CLJS.bR,$CLJS.SP])),$CLJS.oE,l,$CLJS.H([$CLJS.T,k]));e=$CLJS.R.j($CLJS.Jk.g(e,d),k,f)}else e=$CLJS.il.l($CLJS.H([fta($CLJS.Te(d),
e),gta($CLJS.T,$CLJS.hf.g(jta,k))]));e=$CLJS.zt(e,kta)}else e=$CLJS.N;return e}function b(d){return $CLJS.N8.g?$CLJS.N8.g(d,null):$CLJS.N8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();mta=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.kO,null],null),null);
$CLJS.X(new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.tE],null)],null));
$CLJS.O8=function(){function a(d,e,f,k){var l=$CLJS.N8.h(e);return $CLJS.K8($CLJS.u1.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.Xt,$CLJS.$V,$CLJS.mV,f,$CLJS.FQ,l,$CLJS.hQ,e],null)],null)),k)}function b(d,e){return $CLJS.O8.v?$CLJS.O8.v(d,e,null,null):$CLJS.O8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.v=a;return c}();
$CLJS.P8=function(){function a(d,e,f){$CLJS.DW(d,0);return $CLJS.K8($CLJS.u1.g(e,$CLJS.pL.h(d)),f)}function b(d,e){return $CLJS.P8.j?$CLJS.P8.j(d,e,null):$CLJS.P8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.t1.m(null,$CLJS.$V,function(a){return $CLJS.n1($CLJS.J8(a),$CLJS.fh($CLJS.Xg($CLJS.L8(a))))&&!$CLJS.DA($CLJS.M8(a))});