var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var QL,RL,SL,Jha,Kha,WL,Lha,ZL,$L,aM,bM,Mha,cM,dM,fM,gM,TL,hM,Nha,iM,Oha,jM,kM,lM,Pha,mM,nM,Qha,oM,Rha,pM,qM,Sha,sM,Tha,Uha,Vha,uM,vM,wM,xM,yM,zM,AM,BM,Wha,CM,DM,EM,GM,HM,Xha,IM,JM,KM,LM,Yha,MM,NM,OM,PM,Zha,QM,RM,SM,TM,$ha,UM,aia,VM,WM,XM,bia,YM,ZM,$M,aN,cN,dN,cia,eN,fN,dia,gN,hN,iN,jN,kN,eia,lN,fia,mN,nN,pN,gia,qN,rN,sN,hia,vN,wN,iia,jia,xN,zN,AN,BN,kia,EN,FN,lia,mia,nia,GN,HN,IN,JN,KN,LN,oia,MN,pia,NN,ON,QN,SN,TN,UN,VN,qia,ria,XN,sia,tia,YN,ZN,$N,aO,bO,uia,cO,via,wia,dO,eO,fO,gO,hO,
xia,yia,zia,iO,Aia,jO,Bia,lO,mO,Cia,oO,pO,qO,rO,Dia,uO,Eia,Fia,vO,wO,xO,Gia,yO,zO,AO,BO,Hia,DO,EO,FO,GO,HO,Iia,IO,JO,Jia,KO,LO,MO,NO,OO,PO,RO,Kia,SO,Lia,TO,UO,VO,Mia,Nia,Oia,XO,YO,ZO,Pia,$O,aP,bP,cP,Qia,Ria,eP,Sia,Tia,fP,gP,hP,iP,jP,Uia,kP,lP,mP,nP,Via,oP,pP,qP,rP,tP,Wia,uP,vP,Xia,wP,xP,yP,Yia,zP,Zia,BP,$ia,CP,DP,EP,FP,GP,HP,IP,aja,bja,cja,JP,dja,KP,LP,eja,fja,MP,NP,OP,gja,PP,hja,QP,ija,jja,RP,kja,lja,mja,UP,VP,nja,WP,XP,ZP,oja,$P,pja,aQ,bQ,cQ,qja,rja,dQ,eQ,fQ,gQ,sja,iQ,jQ,tja,kQ,lQ,mQ,nQ,oQ,pQ,rQ,
sQ,uja,tQ,uQ,vja,vQ,wja,wQ,xQ,yQ,xja,zQ,AQ,yja,zja,BQ,CQ,DQ,Aja,Bja,EQ,Cja,Dja,Eja,GQ,Fja,IQ,Gja,Hja,JQ,KQ,Ija,Jja,Kja,LQ,MQ,NQ,OQ,PQ,QQ,RQ,Lja,SQ,TQ,Mja,UQ,Nja,Oja,Pja,VQ,WQ,XQ,Qja,YQ,ZQ,Rja,$Q,Sja,aR,Tja,Uja,cR,dR,eR,fR,gR,Vja,hR,iR,Wja,jR,kR,lR,mR,nR,oR,pR,qR,Xja,rR,sR,uR,vR,wR,xR,yR,zR,AR,BR,CR,DR,Yja,ER,FR,GR,HR,IR,Zja,JR,KR,LR,MR,$ja,OR,aka,bka,QR,cka,RR,dka,SR,eka,fka,TR,gka,UR,WR,YR,hka,ika,bS,cS,jka,kka,dS,eS,fS,gS,hS,lka,iS,jS,lS,mS,mka;
$CLJS.PL=function(a){var b=$CLJS.Ao.g(a,null),c=$CLJS.nF(b,$CLJS.Ls,function(d){return $CLJS.zn(d,$CLJS.Cf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.sl,b,$CLJS.Ej,k,$CLJS.Rs,l],null):null}function e(k){return f.j(k,$CLJS.Cf,$CLJS.Cf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
QL=function(a,b){return $CLJS.xd(a)?$CLJS.J.g(a,b):a};RL=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Cn($CLJS.Ao.g($CLJS.sl.h(a),null));if($CLJS.n(b)){var e=QL($CLJS.rt.h(b),c);$CLJS.n(e)?(e=$CLJS.Kq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:QL($CLJS.pt.h(b),c)}return null};SL=function(a,b){return $CLJS.vd(a)||$CLJS.Ll(a)?$CLJS.J.g(a,b):$CLJS.wd(a)?$CLJS.J.g($CLJS.Df(a),b):null};
Jha=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.sl),d=$CLJS.J.g(a,$CLJS.nj);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Rs,$CLJS.tea),f=$CLJS.J.j(b,$CLJS.oea,!0),k=$CLJS.J.g(b,$CLJS.bC),l=$CLJS.J.j(b,$CLJS.$B,$CLJS.YB),m=RL(a,$CLJS.Qq.h(c),k,b);if($CLJS.n(m))return m;m=RL(a,$CLJS.ct.h(c),k,b);if($CLJS.n(m))return m;m=RL(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=RL(a,function(){var t=$CLJS.ho.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=RL(a,$CLJS.Qq.h(c),
l,b);if($CLJS.n(m))return m;m=RL(a,$CLJS.ct.h(c),l,b);if($CLJS.n(m))return m;d=RL(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=RL(a,function(){var t=$CLJS.ho.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?RL(a,e.h?e.h($CLJS.WB):e.call(null,$CLJS.WB),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?RL(a,e.h?e.h($CLJS.WB):e.call(null,$CLJS.WB),l,b):f};
Kha=function(a,b,c){a=$CLJS.Q;var d=$CLJS.eg.g;var e=$CLJS.ol.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.sl),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.bC);l=$CLJS.J.j(l,$CLJS.$B,$CLJS.YB);k=$CLJS.Qq.h(k);f=QL($CLJS.ZB.h(k),f);f=$CLJS.n(f)?f:QL($CLJS.ZB.h(k),l);d=d.call($CLJS.eg,e,f);return new $CLJS.P(null,2,5,a,[d,Jha(b,c)],null)};
$CLJS.UL=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Ej);a=$CLJS.J.g(b,$CLJS.Rs);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.pea,$CLJS.Ri),f=$CLJS.J.j(d,$CLJS.rea,Kha);return $CLJS.n(a)?$CLJS.eb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.Ri,m);l=e.h?e.h(l):e.call(null,l);return TL(k,c,t,l)},null,a):null};
WL=function(a,b,c){var d=$CLJS.zA($CLJS.q($CLJS.aE),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Oh.v($CLJS.aE,$CLJS.VL,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.XL=function(a){return WL($CLJS.zk,a,function(){return $CLJS.oF.h(a)})};Lha=function(){var a=YL;return WL($CLJS.Ls,a,function(){var b=$CLJS.oF.h(a),c=$CLJS.PL(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
ZL=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,$CLJS.me(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};$L=function(a){if($CLJS.zd(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,ZL(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,ZL(c)],null);default:return ZL(a)}}else return ZL(a)};
aM=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.A(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c:c};
bM=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Ve(aM,a)],null),$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ji,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,a],null)],null)],null),function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,$L(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[m,$L(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}($CLJS.ft(2,2,b))}())],null)};Mha=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M?$CLJS.A(a):null};
cM=function(a){return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.Pi,Mha,$CLJS.pt,["valid instance of one of these MBQL clauses: ",$CLJS.At(", ",$CLJS.hf.g($CLJS.A,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.me(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.me(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};
dM=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.OL],null)],null)};$CLJS.eM={};fM={};gM={};$CLJS.VL=function VL(a,b,c){var e=$CLJS.y(b);b=$CLJS.A(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=VL.j?VL.j(k,f,c):VL.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
TL=function TL(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.A(f);var k=$CLJS.B(f),l=SL(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.Cf:$CLJS.yd(b)?$CLJS.N:$CLJS.jd(b);return $CLJS.n($CLJS.n(c)?$CLJS.XB.h($CLJS.pd(f)):c)?a:$CLJS.n(c)?(b=SL(f,c),d=TL.v?TL.v(b,l,k,d):TL.call(null,b,l,k,d),$CLJS.Ql(c)&&$CLJS.wd(f)&&c>$CLJS.D(f)&&(b=$CLJS.df(c-$CLJS.D(f),null),b=$CLJS.gf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.eg.g($CLJS.jd(f),b)),null==f||$CLJS.Ll(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.be.g(f,d):$CLJS.Se.g($CLJS.U,$CLJS.R.j($CLJS.Df(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qea],null),TL.v?TL.v(f,b,c,d):TL.call(null,f,b,c,d)):$CLJS.n($CLJS.XB.h($CLJS.pd(f)))?$CLJS.be.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.od(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.XB,!0],null))};hM=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Nha=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
iM=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);Oha=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);jM=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);kM=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);lM=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
Pha=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);mM=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);nM=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Qha=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);oM=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
Rha=new $CLJS.M(null,"from","from",1815293044);pM=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);qM=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);Sha=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.rM=new $CLJS.M(null,"snippet-name","snippet-name",819240328);sM=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
Tha=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Uha=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.tM=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);Vha=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);uM=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
vM=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);wM=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);xM=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);yM=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);zM=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
AM=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);BM=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Wha=new $CLJS.M(null,"lon-max","lon-max",1590224717);CM=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);DM=new $CLJS.r(null,"stddev","stddev",775056588,null);EM=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.FM=new $CLJS.M(null,"snippet","snippet",953581994);GM=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);HM=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Xha=new $CLJS.M(null,"lat-field","lat-field",-830652957);IM=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);JM=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
KM=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);LM=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Yha=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);MM=new $CLJS.M("location","country","location/country",1666636202);NM=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
OM=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);PM=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Zha=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);QM=new $CLJS.M(null,"unary","unary",-989314568);RM=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
SM=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);TM=new $CLJS.r(null,"ceil","ceil",-184398425,null);$ha=new $CLJS.M(null,"lon-min","lon-min",-787291357);UM=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);aia=new $CLJS.M(null,"match","match",1220059550);VM=new $CLJS.r(null,"count-where","count-where",2025939247,null);WM=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
XM=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);bia=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);YM=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);ZM=new $CLJS.r(null,"sum","sum",1777518341,null);$M=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);aN=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.bN=new $CLJS.M("date","range","date/range",1647265776);cN=new $CLJS.r(null,"between","between",-1523336493,null);dN=new $CLJS.M(null,"clause-form","clause-form",1820463737);cia=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);eN=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);fN=new $CLJS.r(null,"field","field",338095027,null);dia=new $CLJS.M(null,"segment-id","segment-id",1810133590);
gN=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);hN=new $CLJS.r(null,"not-null","not-null",313812992,null);iN=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);jN=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);kN=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);eia=new $CLJS.M(null,"template-tag","template-tag",310841038);
lN=new $CLJS.M(null,"invalid","invalid",412869516);fia=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);mN=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);nN=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.oN=new $CLJS.M(null,"context","context",-830191113);pN=new $CLJS.r(null,"get-year","get-year",704520253,null);
gia=new $CLJS.M(null,"format-rows?","format-rows?",992129486);qN=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);rN=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);sN=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.tN=new $CLJS.M(null,"parameters","parameters",-1229919748);hia=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.uN=new $CLJS.M(null,"card","card",-1430355152);vN=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);wN=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);iia=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);jia=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);xN=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.yN=new $CLJS.M("date","month-year","date/month-year",1948031290);
zN=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);AN=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);BN=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.CN=new $CLJS.M(null,"joins","joins",1033962699);kia=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.DN=new $CLJS.M(null,"source-field","source-field",933829534);EN=new $CLJS.r(null,"Field","Field",430385967,null);
FN=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);lia=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);mia=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);nia=new $CLJS.M(null,"items","items",1031954938);GN=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);HN=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
IN=new $CLJS.M(null,"more","more",-2058821800);JN=new $CLJS.M(null,"first-clause","first-clause",-20953491);KN=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);LN=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);oia=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);MN=new $CLJS.r(null,"contains","contains",-1977535957,null);
pia=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);NN=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);ON=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.PN=new $CLJS.M(null,"widget-type","widget-type",1836256899);QN=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.RN=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
SN=new $CLJS.r(null,"is-null","is-null",-356519403,null);TN=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);UN=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);VN=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);qia=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.WN=new $CLJS.M(null,"required","required",1807647006);ria=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
XN=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);sia=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);tia=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);YN=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);ZN=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);$N=new $CLJS.M("string","contains","string/contains",1602423827);aO=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
bO=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);uia=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);cO=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);via=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);wia=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
dO=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);eO=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);fO=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);gO=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);hO=new $CLJS.r(null,"share","share",1051097594,null);
xia=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);yia=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);zia=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);iO=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Aia=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
jO=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Bia=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.kO=new $CLJS.M(null,"collection","collection",-683361892);lO=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);mO=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.nO=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Cia=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);oO=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);pO=new $CLJS.r(null,"metric","metric",2049329604,null);qO=new $CLJS.r(null,"concat","concat",-467652465,null);rO=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.sO=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
Dia=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.tO=new $CLJS.M("date","relative","date/relative",25987732);uO=new $CLJS.M("location","city","location/city",-1746973325);Eia=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Fia=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);vO=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);wO=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
xO=new $CLJS.M("number","between","number/between",97700581);Gia=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);yO=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);zO=new $CLJS.r(null,"sqrt","sqrt",370479598,null);AO=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);BO=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.CO=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Hia=new $CLJS.M(null,"metric-id","metric-id",-686486942);DO=new $CLJS.r(null,"*","*",345799209,null);EO=new $CLJS.r(null,"+","+",-740910886,null);FO=new $CLJS.r(null,"-","-",-471816912,null);GO=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);HO=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Iia=new $CLJS.M(null,"question","question",-1411720117);IO=new $CLJS.r(null,"asc","asc",1997386096,null);
JO=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Jia=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);KO=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);LO=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);MO=new $CLJS.r(null,"\x3c","\x3c",993667236,null);NO=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);OO=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
PO=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.QO=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);RO=new $CLJS.M("string","ends-with","string/ends-with",302681156);Kia=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);SO=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);Lia=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
TO=new $CLJS.r(null,"and","and",668631710,null);UO=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);VO=new $CLJS.r(null,"round","round",-645002441,null);Mia=new $CLJS.M(null,"to","to",192099007);$CLJS.WO=new $CLJS.M("date","single","date/single",1554682003);Nia=new $CLJS.M(null,"action-id","action-id",-1727958578);Oia=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);XO=new $CLJS.r(null,"exp","exp",1378825265,null);
YO=new $CLJS.r(null,"Filter","Filter",-424893332,null);ZO=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);Pia=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);$O=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);aP=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);bP=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
cP=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.dP=new $CLJS.M(null,"source-table","source-table",-225307692);Qia=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Ria=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);eP=new $CLJS.r(null,"floor","floor",-772394748,null);Sia=new $CLJS.M(null,"middleware","middleware",1462115504);
Tia=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);fP=new $CLJS.M(null,"requires-features","requires-features",-101116256);gP=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);hP=new $CLJS.M(null,"clause-name","clause-name",-996419059);iP=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);jP=new $CLJS.r(null,"now","now",-9994004,null);
Uia=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);kP=new $CLJS.r(null,"not","not",1044554643,null);lP=new $CLJS.r(null,"avg","avg",1837937727,null);mP=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);nP=new $CLJS.M(null,"max-results","max-results",-32858165);Via=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);oP=new $CLJS.r(null,"case","case",-1510733573,null);
pP=new $CLJS.r(null,"distinct","distinct",-148347594,null);qP=new $CLJS.r(null,"get-second","get-second",-425414791,null);rP=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.sP=new $CLJS.M(null,"join-alias","join-alias",1454206794);tP=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Wia=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
uP=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);vP=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Xia=new $CLJS.M(null,"original","original",-445386197);wP=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);xP=new $CLJS.r(null,"abs","abs",1394505050,null);yP=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Yia=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
zP=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Zia=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.AP=new $CLJS.M(null,"date","date",-1463434462);BP=new $CLJS.M(null,"second-clause","second-clause",-461435645);$ia=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);CP=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
DP=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);EP=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);FP=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);GP=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);HP=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);IP=new $CLJS.r(null,"or","or",1876275696,null);
aja=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);bja=new $CLJS.M(null,"constraints","constraints",422775616);cja=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);JP=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);dja=new $CLJS.M(null,"csv-download","csv-download",2141432084);KP=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
LP=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);eja=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);fja=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);MP=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);NP=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
OP=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);gja=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);PP=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);hja=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);QP=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);ija=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
jja=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);RP=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);kja=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);lja=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);mja=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.SP=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.TP=new $CLJS.M(null,"database","database",1849087575);UP=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);VP=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);nja=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);WP=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
XP=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.YP=new $CLJS.M(null,"expressions","expressions",255689909);ZP=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);oja=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);$P=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
pja=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);aQ=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);bQ=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);cQ=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);qja=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);rja=new $CLJS.M(null,"action","action",-811238024);
dQ=new $CLJS.r(null,"get-day","get-day",1768100384,null);eQ=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);fQ=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);gQ=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.hQ=new $CLJS.M(null,"native","native",-613060878);sja=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);iQ=new $CLJS.M(null,"page","page",849072397);
jQ=new $CLJS.r(null,"length","length",-2065447907,null);tja=new $CLJS.M(null,"dashboard","dashboard",-631747508);kQ=new $CLJS.r(null,"get-week","get-week",752472178,null);lQ=new $CLJS.r(null,"get-month","get-month",1271156796,null);mQ=new $CLJS.r(null,"dimension","dimension",-2111181571,null);nQ=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);oQ=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
pQ=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);rQ=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);sQ=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);uja=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);tQ=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);uQ=new $CLJS.r(null,"substring","substring",-1513569493,null);
vja=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);vQ=new $CLJS.M(null,"internal","internal",-854870097);wja=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);wQ=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);xQ=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
yQ=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);xja=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);zQ=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);AQ=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);yja=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
zja=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);BQ=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);CQ=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);DQ=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Aja=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Bja=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);EQ=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.FQ=new $CLJS.M(null,"template-tags","template-tags",1853115685);Cja=new $CLJS.M(null,"public-question","public-question",629369976);Dja=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
Eja=new $CLJS.M(null,"binary","binary",-1802232288);GQ=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);Fja=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.HQ=new $CLJS.M(null,"source-query","source-query",198004422);IQ=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Gja=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
Hja=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);JQ=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);KQ=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);Ija=new $CLJS.M(null,"executed-by","executed-by",-739811161);Jja=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
Kja=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);LQ=new $CLJS.M(null,"amount","amount",364489504);MQ=new $CLJS.r(null,"percentile","percentile",1039342775,null);NQ=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);OQ=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);PQ=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
QQ=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);RQ=new $CLJS.r(null,"trim","trim",-1880116002,null);Lja=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);SQ=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);TQ=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Mja=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
UQ=new $CLJS.M("string","\x3d","string/\x3d",983744235);Nja=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Oja=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);Pja=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);VQ=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);WQ=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
XQ=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Qja=new $CLJS.M(null,"lat-min","lat-min",1630784161);YQ=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);ZQ=new $CLJS.r(null,"inside","inside",-681932758,null);Rja=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);$Q=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
Sja=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);aR=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);Tja=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Uja=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.bR=new $CLJS.M(null,"card-id","card-id",-1770060179);cR=new $CLJS.M(null,"variadic","variadic",882626057);
dR=new $CLJS.r(null,"upper","upper",1886775433,null);eR=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);fR=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);gR=new $CLJS.r(null,"optional","optional",-600484260,null);Vja=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);hR=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
iR=new $CLJS.M(null,"sugar","sugar",-73788488);Wja=new $CLJS.M(null,"lat-max","lat-max",841568226);jR=new $CLJS.r(null,"power","power",702679448,null);kR=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);lR=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);mR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
nR=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);oR=new $CLJS.r(null,"median","median",-2084869638,null);pR=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);qR=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Xja=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);rR=new $CLJS.M(null,"y","y",-1757859776);sR=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.tR=new $CLJS.M(null,"binning","binning",1709835866);uR=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);vR=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);wR=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);xR=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);yR=new $CLJS.r(null,"Reference","Reference",2024574086,null);zR=new $CLJS.M(null,"b","b",1482224470);
AR=new $CLJS.M(null,"a","a",-2123407586);BR=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);CR=new $CLJS.r(null,"replace","replace",853943757,null);DR=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Yja=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);ER=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
FR=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);GR=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);HR=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);IR=new $CLJS.r(null,"segment","segment",675610331,null);Zja=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);JR=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
KR=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);LR=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);MR=new $CLJS.M("string","starts-with","string/starts-with",1266861170);$ja=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.NR=new $CLJS.M(null,"order-by","order-by",1527318070);OR=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
aka=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);bka=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.PR=new $CLJS.M(null,"condition","condition",1668437652);QR=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);cka=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);RR=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
dka=new $CLJS.M(null,"card-name","card-name",-2035606807);SR=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);eka=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);fka=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);TR=new $CLJS.r(null,"log","log",45015523,null);gka=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
UR=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.VR=new $CLJS.M(null,"database_type","database_type",-54700895);WR=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);YR=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.ZR=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.$R=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.aS=new $CLJS.M("date","all-options","date/all-options",549325958);
hka=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);ika=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);bS=new $CLJS.M("location","state","location/state",-114378652);cS=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);jka=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);kka=new $CLJS.M(null,"lon-field","lon-field",517872067);
dS=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);eS=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);fS=new $CLJS.M(null,"numeric","numeric",-1495594714);gS=new $CLJS.r(null,"variable","variable",1359185035,null);hS=new $CLJS.r(null,"lower","lower",-1534114948,null);lka=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
iS=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);jS=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.kS=new $CLJS.M(null,"limit","limit",-1355822363);lS=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);mS=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);mka=new $CLJS.M(null,"pulse","pulse",-244494476);var nS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.tE],null),oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CE],null),pS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.pE],null),qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lE],null),rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xE],null),sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gL],null),nka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null),oka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RK],null),tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.GI],null),uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.KI],null),vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BI],null),wS=new $CLJS.ah(null,new $CLJS.h(null,12,[$CLJS.pi,null,$CLJS.Yt,null,$CLJS.pj,null,$CLJS.ci,null,$CLJS.Gi,null,$CLJS.yj,null,$CLJS.nk,null,$CLJS.Hj,null,$CLJS.ji,null,$CLJS.Fj,null,$CLJS.qi,null,$CLJS.Ai,null],null),null),xS=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.rk,null,$CLJS.WG,null,$CLJS.ci,null,$CLJS.wi,null,$CLJS.ZG,null,$CLJS.jj,null,$CLJS.Vi,null],null),null),
yS=$CLJS.Zt.g(wS,xS),pka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"date bucketing unit"],null)],null),wS),qka=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"time bucketing unit"],null)],null),xS),zS=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"datetime bucketing unit"],null)],null),yS),AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kJ],null),rka=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,
1,[$CLJS.pt,"temporal extract unit"],null),$CLJS.$G,$CLJS.Ai,$CLJS.nk,$CLJS.WI,$CLJS.qJ,$CLJS.rJ,$CLJS.Hj,$CLJS.Gi,$CLJS.wi,$CLJS.jj,$CLJS.XG],null),ska=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"datetime-diff unit"],null),$CLJS.WG,$CLJS.Vi,$CLJS.rk,$CLJS.Yt,$CLJS.pj,$CLJS.yj,$CLJS.pi,$CLJS.ji],null),BS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"temporal-extract week extraction mode"],null),$CLJS.QI,$CLJS.ZI,$CLJS.dJ],null),CS=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Vi,$CLJS.rk,$CLJS.Yt,$CLJS.pj,$CLJS.yj,$CLJS.pi,$CLJS.ji],null),JS,uka,RS,SS,TS,US,VS,WS,XS,IU,wka,JU,xka,yka,KU,zka,Aka,Bka;$CLJS.X(SM,bM($CLJS.$I,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.Sv],null),$CLJS.Cs],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,CS],null)])));var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SM],null);
$CLJS.X(wP,bM($CLJS.YI,$CLJS.H(["n",$CLJS.Cs,"unit",CS])));var tka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wP],null);
$CLJS.X(mN,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"valid :absolute-datetime clause",$CLJS.Pi,function(a){if($CLJS.Va(aM($CLJS.UI,a)))a=lN;else{a=$CLJS.hd(a);var b=$CLJS.XL(tS);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.AP:$CLJS.aJ}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[lN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"not an :absolute-datetime clause"],null),$CLJS.Ue(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AP,bM($CLJS.UI,
$CLJS.H(["date",tS,"unit",pka]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,bM($CLJS.UI,$CLJS.H(["datetime",uS,"unit",zS]))],null)],null));var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);$CLJS.X(BN,bM($CLJS.Ey,$CLJS.H(["time",vS,"unit",qka])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null),GS=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.pt,"date or datetime literal"],null),ES,uS,tS],null);
$CLJS.X(mM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.pt,"time literal"],null),FS,vS],null));$CLJS.X(dS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.pt,"temporal literal"],null),GS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mM],null)],null));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dS],null);
$CLJS.X(Mja,cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof ES?new $CLJS.Gc(function(){return ES},$CLJS.od(lia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,vQ,$CLJS.oj,$CLJS.Ak,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),cQ,"metabase/mbql/schema.cljc",69,$CLJS.UI,1,!0,168,168,$CLJS.Kc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:cQ}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof DS?new $CLJS.Gc(function(){return DS},$CLJS.od(hka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,
$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uP,"metabase/mbql/schema.cljc",29,$CLJS.$I,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$I,$CLJS.sk,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.Sv],null),$CLJS.Cs],null)),$CLJS.si,$CLJS.U($CLJS.Rj,$CLJS.U(gR,SO))],null),$CLJS.Kc,"Schema for a valid relative-datetime clause.",
$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:uP}(),DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof FS?new $CLJS.Gc(function(){return FS},$CLJS.od(jja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,vQ,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UR,"metabase/mbql/schema.cljc",27,$CLJS.Ey,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ey,$CLJS.Ey,$CLJS.U($CLJS.Rj,Lja),$CLJS.si,$CLJS.U($CLJS.Rj,Dia)],null),$CLJS.Kc,"Schema for a valid time clause.",$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:UR}(),FS],null)])));
$CLJS.X(SR,bM($CLJS.Ej,$CLJS.H(["value",$CLJS.li,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,nS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dD,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,oS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ps,pS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.si,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,nS],null)],null)],null)],null)])));var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SR],null);$CLJS.X(BO,bM($CLJS.mB,$CLJS.H(["expression-name",nS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.jk],null)])));
JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BO],null);
uka=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.EE);c=$CLJS.J.g(c,$CLJS.mE);return $CLJS.E.g(b,$CLJS.mE)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.EE);c=$CLJS.J.g(c,$CLJS.DE);return $CLJS.E.g(b,$CLJS.DE)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jk,new $CLJS.h(null,1,[$CLJS.pt,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.EE,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"binning strategy"],null),$CLJS.mE,$CLJS.DE,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),qS],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.DE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.Dl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"bin width must be \x3e\x3d 0."],null),$CLJS.Te($CLJS.Yl)],null)],null)],null)],null)));$CLJS.KS=function KS(a){switch(arguments.length){case 1:return KS.h(arguments[0]);case 2:return KS.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.KS.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.pB);b=$CLJS.J.g(b,$CLJS.pG);return $CLJS.KS.g(a,b)};$CLJS.KS.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.qC(a,$CLJS.RC)?wS:$CLJS.qC(a,$CLJS.QD)?xS:$CLJS.qC(a,$CLJS.qD)?yS:null:null;return $CLJS.n(a)?$CLJS.Id(a,b):!0};$CLJS.KS.A=2;
$CLJS.X(hR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jk,new $CLJS.h(null,1,[$CLJS.pt,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pB,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,oS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,sS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,zS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,nS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,uka],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.KS],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Te($CLJS.EE)],null)],null));
$CLJS.X(JR,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.pB);return"string"===typeof c?b:!0}],null)],null)}(bM($CLJS.jG,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,sS,nS],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hR],null)],null)]))));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JR],null);
$CLJS.X(JP,cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof JS?new $CLJS.Gc(function(){return JS},$CLJS.od(NN,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xN,"metabase/mbql/schema.cljc",60,$CLJS.mB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mB,$CLJS.$R,$CLJS.U($CLJS.Rj,UM),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,$CLJS.jk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:xN}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof LS?new $CLJS.Gc(function(){return LS},
$CLJS.od(eO,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.rr,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,$CLJS.ui,$CLJS.lk,$CLJS.pk],["0.39.0",$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fN,"metabase/mbql/schema.cljc",51,$CLJS.jG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:fN}(),LS],null)])));
$CLJS.MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JP],null);$CLJS.X(hM,bM($CLJS.ZF,$CLJS.H(["aggregation-clause-index",$CLJS.Cs,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.jk],null)])));var NS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hM],null);
$CLJS.X(OM,cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof NS?new $CLJS.Gc(function(){return NS},$CLJS.od(Jja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sR,"metabase/mbql/schema.cljc",23,$CLJS.ZF,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ZF,jka,$CLJS.U($CLJS.Rj,$CLJS.Cs),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,$CLJS.jk))],null),$CLJS.Kc,"Schema for a valid aggregation clause.",$CLJS.n(NS)?NS.H:null])):null));return $CLJS.n(a)?a:sR}(),NS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof JS?new $CLJS.Gc(function(){return JS},
$CLJS.od(NN,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xN,"metabase/mbql/schema.cljc",60,$CLJS.mB,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.mB,$CLJS.$R,$CLJS.U($CLJS.Rj,UM),
$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,$CLJS.jk))],null),$CLJS.Kc,"Schema for a valid expression clause.",$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:xN}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof LS?new $CLJS.Gc(function(){return LS},$CLJS.od(eO,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.rr,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,
$CLJS.ui,$CLJS.lk,$CLJS.pk],["0.39.0",$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fN,"metabase/mbql/schema.cljc",51,$CLJS.jG,1,382,382,$CLJS.Kc,"Schema for a `:field` clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:fN}(),LS],null)])));
var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OM],null),PS=new $CLJS.ah(null,new $CLJS.h(null,11,[$CLJS.Ct,null,$CLJS.BG,null,$CLJS.CF,null,$CLJS.HG,null,$CLJS.JF,null,$CLJS.mG,null,$CLJS.TF,null,$CLJS.It,null,$CLJS.zG,null,$CLJS.lG,null,$CLJS.IG,null],null),null),QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);
$CLJS.X(oM,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Pi,function(a){return"string"===typeof a?$CLJS.ak:$CLJS.n(aM(PS,a))?HM:$CLJS.n(aM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,$CLJS.ak],null),new $CLJS.P(null,2,5,$CLJS.Q,[HM,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.MS],null)],null));RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oM],null);
SS=new $CLJS.ah(null,new $CLJS.h(null,26,[$CLJS.FF,null,$CLJS.pJ,null,$CLJS.Yr,null,$CLJS.lJ,null,$CLJS.iJ,null,$CLJS.XF,null,$CLJS.it,null,$CLJS.BF,null,$CLJS.mJ,null,$CLJS.fJ,null,$CLJS.mG,null,$CLJS.EG,null,$CLJS.jJ,null,$CLJS.oG,null,$CLJS.HF,null,$CLJS.SI,null,$CLJS.Xr,null,$CLJS.KF,null,$CLJS.TF,null,$CLJS.MI,null,$CLJS.Ox,null,$CLJS.TI,null,$CLJS.eJ,null,$CLJS.XI,null,$CLJS.GG,null,$CLJS.hG,null],null),null);
TS=new $CLJS.ah(null,new $CLJS.h(null,9,[$CLJS.Vr,null,$CLJS.Pr,null,$CLJS.qs,null,$CLJS.ss,null,$CLJS.Rr,null,$CLJS.AG,null,$CLJS.xs,null,$CLJS.uk,null,$CLJS.Tr,null],null),null);US=new $CLJS.ah(null,new $CLJS.h(null,17,[$CLJS.ao,null,$CLJS.yG,null,$CLJS.sG,null,$CLJS.kG,null,$CLJS.DG,null,$CLJS.FG,null,$CLJS.PF,null,$CLJS.VF,null,$CLJS.tM,null,$CLJS.QF,null,$CLJS.xG,null,$CLJS.qG,null,$CLJS.kk,null,$CLJS.Qx,null,$CLJS.dG,null,$CLJS.eG,null,$CLJS.wG,null],null),null);
VS=new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.bJ,null,$CLJS.OI,null,$CLJS.nJ,null,$CLJS.Xr,null,$CLJS.hJ,null],null),null);WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$Q],null);XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zN],null);$CLJS.YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);$CLJS.ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fR],null);
$CLJS.X(mR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"numeric expression argument",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.Rt:$CLJS.n(aM(SS,a))?cP:$CLJS.n(aM(US,a))?$CLJS.ZF:$CLJS.n(aM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.jG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.Dl],null),new $CLJS.P(null,2,5,$CLJS.Q,[cP,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,$CLJS.ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jG,
$CLJS.MS],null)],null));var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mR],null);
$CLJS.X(wQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"datetime expression argument",$CLJS.Pi,function(a){return $CLJS.n(aM(US,a))?$CLJS.ZF:$CLJS.n(aM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.n(aM(VS,a))?GN:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,$CLJS.ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[GN,$CLJS.YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,GS,$CLJS.MS],null)],null)],null));
var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wQ],null);
$CLJS.X(ZP,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"expression argument",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.Rt:$CLJS.Cd(a)?$CLJS.Gs:$CLJS.n(aM(TS,a))?nQ:$CLJS.n(aM(SS,a))?cP:$CLJS.n(aM(VS,a))?GN:"string"===typeof a?$CLJS.ak:$CLJS.n(aM(PS,a))?HM:$CLJS.n(aM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,$CLJS.Dl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,$CLJS.Gs],null),new $CLJS.P(null,2,5,$CLJS.Q,[nQ,XS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[cP,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[GN,$CLJS.YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,$CLJS.ak],null),new $CLJS.P(null,2,5,$CLJS.Q,[HM,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.MS],null)],null));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZP],null);$CLJS.X(XP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.pt,"numeric expression arg or interval"],null),tka,$S],null));
var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XP],null);$CLJS.X(zM,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"int greater than zero or numeric expression",$CLJS.Pi,function(a){return"number"===typeof a?$CLJS.Rt:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rt,qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,WS],null)],null));var vka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zM],null);
$CLJS.X(HN,bM($CLJS.mG,$CLJS.H(["a",bT,"b",bT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,bT],null)])));var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HN],null);$CLJS.X(lM,bM($CLJS.CF,$CLJS.H(["s",RS,"start",vka,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$S],null)])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lM],null);$CLJS.X(oQ,bM($CLJS.Ox,$CLJS.H(["s",RS])));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oQ],null);$CLJS.X(NM,bM($CLJS.zG,$CLJS.H(["s",RS])));
var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NM],null);$CLJS.X(RM,bM($CLJS.IG,$CLJS.H(["s",RS])));var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);$CLJS.X(jN,bM($CLJS.BG,$CLJS.H(["s",RS])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jN],null);$CLJS.X(VN,bM($CLJS.Ct,$CLJS.H(["s",RS])));var jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VN],null);$CLJS.X(EM,bM($CLJS.It,$CLJS.H(["s",RS])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EM],null);
$CLJS.X(lR,bM($CLJS.HG,$CLJS.H(["s",RS,"match",$CLJS.ak,"replacement",$CLJS.ak])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lR],null);$CLJS.X(iS,bM($CLJS.JF,$CLJS.H(["a",RS,"b",RS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,RS],null)])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iS],null);$CLJS.X(KO,bM($CLJS.lG,$CLJS.H(["s",RS,"pattern",$CLJS.ak])));var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KO],null);
$CLJS.X(HR,bM($CLJS.Xr,$CLJS.H(["x",cT,"y",cT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,cT],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HR],null);$CLJS.X(FR,bM($CLJS.it,$CLJS.H(["x",$S,"y",cT,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,cT],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FR],null);$CLJS.X(ER,bM($CLJS.EG,$CLJS.H(["x",$S,"y",$S,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$S],null)])));var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ER],null);
$CLJS.X(GR,bM($CLJS.Yr,$CLJS.H(["x",$S,"y",$S,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$S],null)])));var rT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GR],null);$CLJS.X(WP,bM($CLJS.hG,$CLJS.H(["x",$S])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WP],null);$CLJS.X(PQ,bM($CLJS.FF,$CLJS.H(["x",$S])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PQ],null);$CLJS.X(lS,bM($CLJS.oG,$CLJS.H(["x",$S])));var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lS],null);$CLJS.X(aP,bM($CLJS.KF,$CLJS.H(["x",$S])));
var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aP],null);$CLJS.X(gP,bM($CLJS.HF,$CLJS.H(["x",$S,"y",$S])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gP],null);$CLJS.X(DP,bM($CLJS.XF,$CLJS.H(["x",$S])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DP],null);$CLJS.X(AM,bM($CLJS.BF,$CLJS.H(["x",$S])));var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null);$CLJS.X(bQ,bM($CLJS.GG,$CLJS.H(["x",$S])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bQ],null);
$CLJS.X($M,bM($CLJS.XI,$CLJS.H(["datetime-x",aT,"datetime-y",aT,"unit",ska])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$M],null);$CLJS.X(rN,bM($CLJS.lJ,$CLJS.H(["datetime",aT,"unit",rka,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,BS],null)])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rN],null);$CLJS.X(iP,bM($CLJS.TI,$CLJS.H(["date",aT])));var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iP],null);$CLJS.X(KP,bM($CLJS.pJ,$CLJS.H(["date",aT])));
var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);$CLJS.X(nM,bM($CLJS.MI,$CLJS.H(["date",aT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);$CLJS.X(jS,bM($CLJS.fJ,$CLJS.H(["date",aT,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,BS],null)])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jS],null);$CLJS.X(vO,bM($CLJS.eJ,$CLJS.H(["date",aT])));var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vO],null);$CLJS.X(EP,bM($CLJS.jJ,$CLJS.H(["date",aT])));
var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EP],null);$CLJS.X(WM,bM($CLJS.mJ,$CLJS.H(["datetime",aT])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null);$CLJS.X(AO,bM($CLJS.iJ,$CLJS.H(["datetime",aT])));var JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AO],null);$CLJS.X(PM,bM($CLJS.SI,$CLJS.H(["datetime",aT])));var KT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PM],null);$CLJS.X(fO,bM($CLJS.OI,$CLJS.H(["datetime",aT,"to",AS,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,AS],null)])));
var LT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fO],null),MT=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"datetime arithmetic unit"],null),$CLJS.ZG,$CLJS.WG,$CLJS.Vi,$CLJS.rk,$CLJS.Yt,$CLJS.pj,$CLJS.yj,$CLJS.pi,$CLJS.ji],null);$CLJS.X(kM,bM($CLJS.hJ,$CLJS.H(["datetime",aT,"amount",$S,"unit",MT])));var NT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kM],null);
$CLJS.X(IM,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return bM(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.bJ));var OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IM],null);$CLJS.X(jM,bM($CLJS.nJ,$CLJS.H(["datetime",aT,"amount",$S,"unit",MT])));var PT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jM],null);
$CLJS.X(BQ,cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EO,"metabase/mbql/schema.cljc",51,$CLJS.Xr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Xr,$CLJS.BE,$CLJS.U($CLJS.Rj,UP),rR,$CLJS.U($CLJS.Rj,UP),IN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,UP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:EO}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof NT?new $CLJS.Gc(function(){return NT},
$CLJS.od(Uia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[ZN,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LP,"metabase/mbql/schema.cljc",67,$CLJS.hJ,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hJ,$CLJS.aJ,$CLJS.U($CLJS.Rj,eS),LQ,$CLJS.U($CLJS.Rj,
QQ),$CLJS.si,$CLJS.U($CLJS.Rj,YQ)],null),$CLJS.Kc,"Schema for a valid datetime-add clause.",$CLJS.n(NT)?NT.H:null])):null));return $CLJS.n(a)?a:LP}(),NT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof PT?new $CLJS.Gc(function(){return PT},$CLJS.od(Nha,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,
$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[ZN,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cS,"metabase/mbql/schema.cljc",72,$CLJS.nJ,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.nJ,$CLJS.aJ,$CLJS.U($CLJS.Rj,eS),LQ,$CLJS.U($CLJS.Rj,QQ),$CLJS.si,$CLJS.U($CLJS.Rj,YQ)],null),$CLJS.Kc,"Schema for a valid datetime-subtract clause.",$CLJS.n(PT)?PT.H:null])):null));
return $CLJS.n(a)?a:cS}(),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof LT?new $CLJS.Gc(function(){return LT},$CLJS.od(Sja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.OI,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FN,"metabase/mbql/schema.cljc",71,$CLJS.OI,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OI,$CLJS.aJ,$CLJS.U($CLJS.Rj,eS),Mia,$CLJS.U($CLJS.Rj,uR),Rha,$CLJS.U($CLJS.Rj,$CLJS.U(gR,uR))],null),$CLJS.Kc,"Schema for a valid convert-timezone clause.",$CLJS.n(LT)?LT.H:null])):null));return $CLJS.n(a)?a:FN}(),LT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&
"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof OT?new $CLJS.Gc(function(){return OT},$CLJS.od(uja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.bJ,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jP,"metabase/mbql/schema.cljc",
45,$CLJS.bJ,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.bJ],null),$CLJS.Kc,"Schema for a valid now clause.",$CLJS.n(OT)?OT.H:null])):null));return $CLJS.n(a)?a:jP}(),OT],null)])));$CLJS.QT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GP],null);$CLJS.X(pM,bM($CLJS.xs,$CLJS.H(["first-clause",$CLJS.QT,"second-clause",$CLJS.QT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$CLJS.QT],null)])));var RT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pM],null);
$CLJS.X(bO,bM($CLJS.qs,$CLJS.H(["first-clause",$CLJS.QT,"second-clause",$CLJS.QT,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,$CLJS.QT],null)])));var ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bO],null);$CLJS.X(KM,bM($CLJS.ss,$CLJS.H(["clause",$CLJS.QT])));
var TT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KM],null),UT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,3,[$CLJS.pt,":field or :expression reference or :relative-datetime",$CLJS.rt,$CLJS.Ue(":field or :expression reference or :relative-datetime"),$CLJS.Pi,function(a){return $CLJS.n(aM($CLJS.$I,a))?$CLJS.$I:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$I,DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.MS],null)],null);
$CLJS.X(yQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.h(null,1,[$CLJS.pt,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.qs,$CLJS.Gs,$CLJS.Dl,$CLJS.ak,HS,UT,bT,IS],null)],null));var VT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yQ],null);
$CLJS.X(PO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"order comparable",$CLJS.Pi,function(a){return $CLJS.n(aM($CLJS.Ej,a))?$CLJS.Ej:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.qs,$CLJS.Dl,$CLJS.ak,HS,bT,UT],null)],null)],null));var WT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PO],null);
$CLJS.X(xR,bM($CLJS.uk,$CLJS.H(["field",VT,"value-or-field",VT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,VT],null)])));var XT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xR],null);$CLJS.X(BR,bM($CLJS.AG,$CLJS.H(["field",VT,"value-or-field",VT,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qi,VT],null)])));var YT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BR],null);$CLJS.X(wR,bM($CLJS.Tr,$CLJS.H(["field",WT,"value-or-field",WT])));
var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wR],null);$CLJS.X(vR,bM($CLJS.Pr,$CLJS.H(["field",WT,"value-or-field",WT])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vR],null);$CLJS.X(kN,bM($CLJS.Vr,$CLJS.H(["field",WT,"value-or-field",WT])));var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);$CLJS.X(qN,bM($CLJS.Rr,$CLJS.H(["field",WT,"value-or-field",WT])));var bU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qN],null);$CLJS.X(fQ,bM($CLJS.MF,$CLJS.H(["field",WT,"min",WT,"max",WT])));
var cU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fQ],null);$CLJS.X(IQ,bM($CLJS.SF,$CLJS.H(["lat-field",WT,"lon-field",WT,"lat-max",WT,"lon-min",WT,"lat-min",WT,"lon-max",WT])));var dU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IQ],null);$CLJS.X(UN,bM($CLJS.LF,$CLJS.H(["field",$CLJS.MS])));var eU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UN],null);$CLJS.X(JO,bM($CLJS.WF,$CLJS.H(["field",$CLJS.MS])));var fU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JO],null);$CLJS.X(lO,bM($CLJS.NF,$CLJS.H(["field",$CLJS.MS])));
var gU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lO],null);$CLJS.X(sM,bM($CLJS.UF,$CLJS.H(["field",$CLJS.MS])));var hU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null),iU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null)],null);$CLJS.X(yO,bM($CLJS.aG,$CLJS.H(["field",RS,"string-or-field",RS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,iU],null)])));var jU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yO],null);
$CLJS.X(rP,bM($CLJS.uG,$CLJS.H(["field",RS,"string-or-field",RS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,iU],null)])));var kU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rP],null);$CLJS.X(jO,bM($CLJS.iG,$CLJS.H(["field",RS,"string-or-field",RS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,iU],null)])));var lU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null);$CLJS.X($P,bM($CLJS.bG,$CLJS.H(["field",RS,"string-or-field",RS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,iU],null)])));
var mU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$P],null);$CLJS.X(BM,bM($CLJS.vG,$CLJS.H(["field",$CLJS.MS,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.Cs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.Sv,$CLJS.Ux,$CLJS.RF],null)],null),"unit",CS,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hk,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null)],null)],null)])));var nU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BM],null);
$CLJS.X(rQ,bM($CLJS.IF,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PK],null),nS],null)])));var oU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rQ],null);
$CLJS.X(zN,cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(kR,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),TO,"metabase/mbql/schema.cljc",15,$CLJS.xs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,JN,$CLJS.U($CLJS.Rj,YO),BP,$CLJS.U($CLJS.Rj,YO),TN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,YO))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:TO}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},
$CLJS.od(FP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",14,$CLJS.qs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qs,JN,$CLJS.U($CLJS.Rj,YO),BP,$CLJS.U($CLJS.Rj,YO),TN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,YO))],null),$CLJS.Kc,
"Schema for a valid or clause.",$CLJS.n(ST)?ST.H:null])):null));return $CLJS.n(a)?a:IP}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(sQ,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kP,"metabase/mbql/schema.cljc",15,$CLJS.ss,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.ZR,$CLJS.U($CLJS.Rj,YO)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:kP}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==
typeof ZT?new $CLJS.Gc(function(){return ZT},$CLJS.od(OP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",13,$CLJS.Tr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.jG,$CLJS.U($CLJS.Rj,LN),WQ,$CLJS.U($CLJS.Rj,LN)],
null),$CLJS.Kc,"Schema for a valid \x3c clause.",$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:MO}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(RR,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,
1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",14,$CLJS.Vr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.jG,$CLJS.U($CLJS.Rj,LN),WQ,$CLJS.U($CLJS.Rj,LN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:LO}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&
"undefined"!==typeof gM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(MP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NO,"metabase/mbql/schema.cljc",13,$CLJS.Pr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Pr,$CLJS.jG,$CLJS.U($CLJS.Rj,
LN),WQ,$CLJS.U($CLJS.Rj,LN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:NO}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(KR,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],
[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UO,"metabase/mbql/schema.cljc",14,$CLJS.Rr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Rr,$CLJS.jG,$CLJS.U($CLJS.Rj,LN),WQ,$CLJS.U($CLJS.Rj,LN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:UO}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&
"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(NP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",13,$CLJS.uk,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.uk,$CLJS.jG,$CLJS.U($CLJS.Rj,WR),WQ,$CLJS.U($CLJS.Rj,WR),zQ,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,WR))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(XT)?XT.H:null])):null));return $CLJS.n(a)?a:OO}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(aN,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,
$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wN,"metabase/mbql/schema.cljc",14,$CLJS.AG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AG,$CLJS.jG,$CLJS.U($CLJS.Rj,WR),WQ,$CLJS.U($CLJS.Rj,WR),zQ,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,WR))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(YT)?YT.H:null])):null));
return $CLJS.n(a)?a:wN}(),YT],null)])));
$CLJS.X(GP,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"valid filter expression",$CLJS.Pi,function(a){return $CLJS.n(aM(VS,a))?$CLJS.aJ:$CLJS.n(aM(SS,a))?fS:$CLJS.n(aM(PS,a))?$CLJS.ak:$CLJS.n(aM(TS,a))?$CLJS.Gs:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,$CLJS.YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[fS,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,cM($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof RT?new $CLJS.Gc(function(){return RT},$CLJS.od(kR,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TO,"metabase/mbql/schema.cljc",
15,$CLJS.xs,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xs,JN,$CLJS.U($CLJS.Rj,YO),BP,$CLJS.U($CLJS.Rj,YO),TN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,YO))],null),$CLJS.Kc,"Schema for a valid and clause.",$CLJS.n(RT)?RT.H:null])):null));return $CLJS.n(a)?a:TO}(),RT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof ST?new $CLJS.Gc(function(){return ST},$CLJS.od(FP,new $CLJS.h(null,1,[$CLJS.ck,
!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IP,"metabase/mbql/schema.cljc",14,$CLJS.qs,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qs,JN,$CLJS.U($CLJS.Rj,YO),BP,$CLJS.U($CLJS.Rj,YO),TN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,YO))],null),$CLJS.Kc,"Schema for a valid or clause.",$CLJS.n(ST)?
ST.H:null])):null));return $CLJS.n(a)?a:IP}(),ST],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof TT?new $CLJS.Gc(function(){return TT},$CLJS.od(sQ,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),kP,"metabase/mbql/schema.cljc",15,$CLJS.ss,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.ZR,$CLJS.U($CLJS.Rj,YO)],null),$CLJS.Kc,"Schema for a valid not clause.",$CLJS.n(TT)?TT.H:null])):null));return $CLJS.n(a)?a:kP}(),TT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof XT?new $CLJS.Gc(function(){return XT},$CLJS.od(NP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,
$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OO,"metabase/mbql/schema.cljc",13,$CLJS.uk,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uk,$CLJS.jG,$CLJS.U($CLJS.Rj,WR),WQ,$CLJS.U($CLJS.Rj,WR),zQ,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,WR))],null),$CLJS.Kc,"Schema for a valid \x3d clause.",$CLJS.n(XT)?XT.H:null])):
null));return $CLJS.n(a)?a:OO}(),XT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof YT?new $CLJS.Gc(function(){return YT},$CLJS.od(aN,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wN,"metabase/mbql/schema.cljc",14,$CLJS.AG,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.AG,$CLJS.jG,$CLJS.U($CLJS.Rj,WR),WQ,$CLJS.U($CLJS.Rj,WR),zQ,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,WR))],null),$CLJS.Kc,"Schema for a valid !\x3d clause.",$CLJS.n(YT)?YT.H:null])):null));return $CLJS.n(a)?a:wN}(),YT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof ZT?new $CLJS.Gc(function(){return ZT},
$CLJS.od(OP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",13,$CLJS.Tr,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Tr,$CLJS.jG,$CLJS.U($CLJS.Rj,LN),WQ,$CLJS.U($CLJS.Rj,LN)],null),$CLJS.Kc,"Schema for a valid \x3c clause.",
$CLJS.n(ZT)?ZT.H:null])):null));return $CLJS.n(a)?a:MO}(),ZT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof $T?new $CLJS.Gc(function(){return $T},$CLJS.od(MP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),NO,"metabase/mbql/schema.cljc",13,$CLJS.Pr,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Pr,$CLJS.jG,$CLJS.U($CLJS.Rj,LN),WQ,$CLJS.U($CLJS.Rj,LN)],null),$CLJS.Kc,"Schema for a valid \x3e clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:NO}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof aU?new $CLJS.Gc(function(){return aU},$CLJS.od(RR,new $CLJS.h(null,
1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),LO,"metabase/mbql/schema.cljc",14,$CLJS.Vr,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Vr,$CLJS.jG,$CLJS.U($CLJS.Rj,LN),WQ,$CLJS.U($CLJS.Rj,LN)],null),$CLJS.Kc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(aU)?aU.H:null])):
null));return $CLJS.n(a)?a:LO}(),aU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof bU?new $CLJS.Gc(function(){return bU},$CLJS.od(KR,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UO,"metabase/mbql/schema.cljc",14,$CLJS.Rr,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Rr,$CLJS.jG,$CLJS.U($CLJS.Rj,LN),WQ,$CLJS.U($CLJS.Rj,LN)],null),$CLJS.Kc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(bU)?bU.H:null])):null));return $CLJS.n(a)?a:UO}(),bU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof cU?new $CLJS.Gc(function(){return cU},$CLJS.od(lja,new $CLJS.h(null,
1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),cN,"metabase/mbql/schema.cljc",19,$CLJS.MF,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.MF,$CLJS.jG,$CLJS.U($CLJS.Rj,LN),$CLJS.ao,$CLJS.U($CLJS.Rj,LN),$CLJS.kk,$CLJS.U($CLJS.Rj,LN)],null),$CLJS.Kc,"Schema for a valid between clause.",
$CLJS.n(cU)?cU.H:null])):null));return $CLJS.n(a)?a:cN}(),cU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof jU?new $CLJS.Gc(function(){return jU},$CLJS.od(nja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aR,"metabase/mbql/schema.cljc",23,$CLJS.aG,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.aG,$CLJS.jG,$CLJS.U($CLJS.Rj,VQ),vN,$CLJS.U($CLJS.Rj,VQ),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,pQ))],null),$CLJS.Kc,"Schema for a valid starts-with clause.",$CLJS.n(jU)?jU.H:null])):null));return $CLJS.n(a)?a:aR}(),jU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof kU?new $CLJS.Gc(function(){return kU},
$CLJS.od(oia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AN,"metabase/mbql/schema.cljc",21,$CLJS.uG,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.uG,$CLJS.jG,$CLJS.U($CLJS.Rj,VQ),vN,$CLJS.U($CLJS.Rj,VQ),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,pQ))],null),
$CLJS.Kc,"Schema for a valid ends-with clause.",$CLJS.n(kU)?kU.H:null])):null));return $CLJS.n(a)?a:AN}(),kU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof lU?new $CLJS.Gc(function(){return lU},$CLJS.od(Bia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,
1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MN,"metabase/mbql/schema.cljc",20,$CLJS.iG,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.iG,$CLJS.jG,$CLJS.U($CLJS.Rj,VQ),vN,$CLJS.U($CLJS.Rj,VQ),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,pQ))],null),$CLJS.Kc,"Schema for a valid contains clause.",$CLJS.n(lU)?lU.H:null])):null));return $CLJS.n(a)?a:MN}(),lU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==
typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof mU?new $CLJS.Gc(function(){return mU},$CLJS.od(fka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qM,"metabase/mbql/schema.cljc",36,$CLJS.bG,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.bG,$CLJS.jG,$CLJS.U($CLJS.Rj,VQ),vN,$CLJS.U($CLJS.Rj,VQ),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,pQ))],null),$CLJS.Kc,"Schema for a valid does-not-contain clause.",$CLJS.n(mU)?mU.H:null])):null));return $CLJS.n(a)?a:qM}(),mU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof dU?new $CLJS.Gc(function(){return dU},$CLJS.od(Pja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),
$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZQ,"metabase/mbql/schema.cljc",26,$CLJS.SF,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.SF,Xha,$CLJS.U($CLJS.Rj,LN),kka,$CLJS.U($CLJS.Rj,LN),Wja,$CLJS.U($CLJS.Rj,LN),$ha,$CLJS.U($CLJS.Rj,LN),Qja,$CLJS.U($CLJS.Rj,LN),Wha,$CLJS.U($CLJS.Rj,
LN)],null),$CLJS.Kc,"Schema for a valid inside clause.",$CLJS.n(dU)?dU.H:null])):null));return $CLJS.n(a)?a:ZQ}(),dU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof gU?new $CLJS.Gc(function(){return gU},$CLJS.od(Cia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,
1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),tP,"metabase/mbql/schema.cljc",28,$CLJS.NF,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NF,$CLJS.jG,$CLJS.U($CLJS.Rj,EN)],null),$CLJS.Kc,"Schema for a valid is-empty clause.",$CLJS.n(gU)?gU.H:null])):null));return $CLJS.n(a)?a:tP}(),gU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&
"undefined"!==typeof hU?new $CLJS.Gc(function(){return hU},$CLJS.od(Aja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gQ,"metabase/mbql/schema.cljc",29,$CLJS.UF,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UF,$CLJS.jG,$CLJS.U($CLJS.Rj,EN)],null),
$CLJS.Kc,"Schema for a valid not-empty clause.",$CLJS.n(hU)?hU.H:null])):null));return $CLJS.n(a)?a:gQ}(),hU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof eU?new $CLJS.Gc(function(){return eU},$CLJS.od(aka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,
1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SN,"metabase/mbql/schema.cljc",27,$CLJS.LF,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LF,$CLJS.jG,$CLJS.U($CLJS.Rj,EN)],null),$CLJS.Kc,"Schema for a valid is-null clause.",$CLJS.n(eU)?eU.H:null])):null));return $CLJS.n(a)?a:SN}(),eU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&
"undefined"!==typeof fU?new $CLJS.Gc(function(){return fU},$CLJS.od(Oia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hN,"metabase/mbql/schema.cljc",28,$CLJS.WF,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,$CLJS.jG,$CLJS.U($CLJS.Rj,EN)],null),
$CLJS.Kc,"Schema for a valid not-null clause.",$CLJS.n(fU)?fU.H:null])):null));return $CLJS.n(a)?a:hN}(),fU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof nU?new $CLJS.Gc(function(){return nU},$CLJS.od(Oha,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,
1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GQ,"metabase/mbql/schema.cljc",33,$CLJS.vG,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.vG,$CLJS.jG,$CLJS.U($CLJS.Rj,EN),$CLJS.sk,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.Cs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ns,$CLJS.Sv,$CLJS.Ux,$CLJS.RF],null)],null)),$CLJS.si,$CLJS.U($CLJS.Rj,SO),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,qja))],null),$CLJS.Kc,"Schema for a valid time-interval clause.",
$CLJS.n(nU)?nU.H:null])):null));return $CLJS.n(a)?a:GQ}(),nU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof oU?new $CLJS.Gc(function(){return oU},$CLJS.od(Wia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IR,"metabase/mbql/schema.cljc",27,$CLJS.IF,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,dia,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,Gja,UM],null))],null),$CLJS.Kc,"Schema for a valid segment clause.",$CLJS.n(oU)?oU.H:null])):null));return $CLJS.n(a)?a:IR}(),oU],null)]))],null)],null));
$CLJS.X(tQ,bM($CLJS.TF,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.h(null,1,[$CLJS.pt,":case subclause"],null),$CLJS.QT,bT],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.h(null,1,[$CLJS.pt,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),bT],null)],null)],null)])));
var pU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tQ],null);
$CLJS.X($Q,cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof oT?new $CLJS.Gc(function(){return oT},$CLJS.od(QP,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EO,"metabase/mbql/schema.cljc",51,$CLJS.Xr,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Xr,$CLJS.BE,$CLJS.U($CLJS.Rj,UP),rR,$CLJS.U($CLJS.Rj,UP),IN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,UP))],null),$CLJS.Kc,"Schema for a valid + clause.",$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:EO}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof pT?new $CLJS.Gc(function(){return pT},
$CLJS.od(hja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FO,"metabase/mbql/schema.cljc",51,$CLJS.it,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.it,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ),
rR,$CLJS.U($CLJS.Rj,UP),IN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,UP))],null),$CLJS.Kc,"Schema for a valid - clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:FO}(),pT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM?new $CLJS.Gc(function(){return qT},$CLJS.od(gja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,hP,$CLJS.Ui,$CLJS.oj,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],
[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.cA,"metabase/mbql/schema.cljc",$CLJS.EG,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EG,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ),rR,$CLJS.U($CLJS.Rj,QQ),IN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,QQ))],null),$CLJS.Kc,"Schema for a valid / clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?
a:$CLJS.cA}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof rT?new $CLJS.Gc(function(){return rT},$CLJS.od(ija,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DO,"metabase/mbql/schema.cljc",51,$CLJS.Yr,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Yr,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ),rR,$CLJS.U($CLJS.Rj,QQ),IN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,QQ))],null),$CLJS.Kc,"Schema for a valid * clause.",$CLJS.n(rT)?rT.H:null])):null));return $CLJS.n(a)?a:DO}(),rT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof dT?new $CLJS.Gc(function(){return dT},
$CLJS.od(DQ,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SQ,"metabase/mbql/schema.cljc",58,$CLJS.mG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mG,AR,$CLJS.U($CLJS.Rj,vM),zR,$CLJS.U($CLJS.Rj,
vM),IN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,vM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:SQ}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof fT?new $CLJS.Gc(function(){return fT},$CLJS.od(zja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,
$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",56,$CLJS.Ox,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ox,$CLJS.ky,$CLJS.U($CLJS.Rj,VQ)],null),$CLJS.Kc,"Schema for a valid length clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:jQ}(),fT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof sT?new $CLJS.Gc(function(){return sT},$CLJS.od(pia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eP,"metabase/mbql/schema.cljc",55,$CLJS.hG,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hG,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ)],null),$CLJS.Kc,"Schema for a valid floor clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:eP}(),sT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof tT?new $CLJS.Gc(function(){return tT},$CLJS.od(jia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),
$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TM,"metabase/mbql/schema.cljc",54,$CLJS.FF,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FF,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ)],null),$CLJS.Kc,"Schema for a valid ceil clause.",$CLJS.n(tT)?
tT.H:null])):null));return $CLJS.n(a)?a:TM}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof uT?new $CLJS.Gc(function(){return uT},$CLJS.od(yia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,
1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VO,"metabase/mbql/schema.cljc",55,$CLJS.oG,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oG,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ)],null),$CLJS.Kc,"Schema for a valid round clause.",$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:VO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&
"undefined"!==typeof vT?new $CLJS.Gc(function(){return vT},$CLJS.od(gka,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",53,$CLJS.KF,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.KF,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ)],null),$CLJS.Kc,"Schema for a valid abs clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:xP}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof wT?new $CLJS.Gc(function(){return wT},$CLJS.od(Uja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,
dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[OR,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jR,"metabase/mbql/schema.cljc",69,$CLJS.HF,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.HF,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ),rR,$CLJS.U($CLJS.Rj,QQ)],null),$CLJS.Kc,"Schema for a valid power clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:jR}(),wT],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof xT?new $CLJS.Gc(function(){return xT},$CLJS.od(Ria,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[OR,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zO,"metabase/mbql/schema.cljc",68,$CLJS.XF,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XF,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ)],null),$CLJS.Kc,"Schema for a valid sqrt clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:zO}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof yT?new $CLJS.Gc(function(){return yT},$CLJS.od(Vja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),
$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[OR,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XO,"metabase/mbql/schema.cljc",67,$CLJS.BF,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ)],null),$CLJS.Kc,"Schema for a valid exp clause.",$CLJS.n(yT)?
yT.H:null])):null));return $CLJS.n(a)?a:XO}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof zT?new $CLJS.Gc(function(){return zT},$CLJS.od(cia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[OR,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,
1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TR,"metabase/mbql/schema.cljc",67,$CLJS.GG,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GG,$CLJS.BE,$CLJS.U($CLJS.Rj,QQ)],null),$CLJS.Kc,"Schema for a valid log clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:TR}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&
"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(GM,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",61,$CLJS.TF,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.TF,$CLJS.ax,$CLJS.U($CLJS.Rj,YN),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,HP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:oP}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof AT?new $CLJS.Gc(function(){return AT},$CLJS.od(fia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,
$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.XI,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yP,"metabase/mbql/schema.cljc",65,$CLJS.XI,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.XI,sia,$CLJS.U($CLJS.Rj,eS),tia,$CLJS.U($CLJS.Rj,eS),$CLJS.si,$CLJS.U($CLJS.Rj,Zia)],null),$CLJS.Kc,"Schema for a valid datetime-diff clause.",
$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:yP}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof BT?new $CLJS.Gc(function(){return BT},$CLJS.od(Dja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(OQ,
new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",71,$CLJS.lJ,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lJ,$CLJS.aJ,$CLJS.U($CLJS.Rj,eS),$CLJS.si,$CLJS.U($CLJS.Rj,$ja),$CLJS.yi,$CLJS.U($CLJS.Rj,$CLJS.U(gR,mS))],null),$CLJS.Kc,"Schema for a valid temporal-extract clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:RP}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof CT?new $CLJS.Gc(function(){return CT},$CLJS.od(Kia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pN,"metabase/mbql/schema.cljc",71,$CLJS.TI,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TI,$CLJS.AP,$CLJS.U($CLJS.Rj,eS)],null),$CLJS.Kc,"Schema for a valid get-year clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:pN}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof DT?new $CLJS.Gc(function(){return DT},$CLJS.od(zia,new $CLJS.h(null,1,[$CLJS.ck,!0],
null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PP,"metabase/mbql/schema.cljc",74,$CLJS.pJ,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pJ,$CLJS.AP,$CLJS.U($CLJS.Rj,eS)],null),$CLJS.Kc,"Schema for a valid get-quarter clause.",
$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:PP}(),DT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof ET?new $CLJS.Gc(function(){return ET},$CLJS.od(fja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),
$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lQ,"metabase/mbql/schema.cljc",72,$CLJS.MI,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MI,$CLJS.AP,$CLJS.U($CLJS.Rj,eS)],null),$CLJS.Kc,"Schema for a valid get-month clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:lQ}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==
typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof FT?new $CLJS.Gc(function(){return FT},$CLJS.od(Zja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kQ,"metabase/mbql/schema.cljc",
71,$CLJS.fJ,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.fJ,$CLJS.AP,$CLJS.U($CLJS.Rj,eS),$CLJS.yi,$CLJS.U($CLJS.Rj,$CLJS.U(gR,mS))],null),$CLJS.Kc,"Schema for a valid get-week clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:kQ}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof GT?new $CLJS.Gc(function(){return GT},$CLJS.od(Uha,new $CLJS.h(null,1,[$CLJS.ck,
!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dQ,"metabase/mbql/schema.cljc",70,$CLJS.eJ,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eJ,$CLJS.AP,$CLJS.U($CLJS.Rj,eS)],null),$CLJS.Kc,"Schema for a valid get-day clause.",
$CLJS.n(GT)?GT.H:null])):null));return $CLJS.n(a)?a:dQ}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof HT?new $CLJS.Gc(function(){return HT},$CLJS.od($ia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),
$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VP,"metabase/mbql/schema.cljc",78,$CLJS.jJ,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jJ,$CLJS.AP,$CLJS.U($CLJS.Rj,eS)],null),$CLJS.Kc,"Schema for a valid get-day-of-week clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:VP}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==
typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof IT?new $CLJS.Gc(function(){return IT},$CLJS.od(Yia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),wM,"metabase/mbql/schema.cljc",
71,$CLJS.mJ,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mJ,$CLJS.aJ,$CLJS.U($CLJS.Rj,eS)],null),$CLJS.Kc,"Schema for a valid get-hour clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:wM}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof JT?new $CLJS.Gc(function(){return JT},$CLJS.od(Hja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,
$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YR,"metabase/mbql/schema.cljc",73,$CLJS.iJ,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iJ,$CLJS.aJ,$CLJS.U($CLJS.Rj,eS)],null),$CLJS.Kc,"Schema for a valid get-minute clause.",$CLJS.n(JT)?JT.H:null])):null));
return $CLJS.n(a)?a:YR}(),JT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof KT?new $CLJS.Gc(function(){return KT},$CLJS.od(Via,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.lJ,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,
[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qP,"metabase/mbql/schema.cljc",73,$CLJS.SI,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SI,$CLJS.aJ,$CLJS.U($CLJS.Rj,eS)],null),$CLJS.Kc,"Schema for a valid get-second clause.",$CLJS.n(KT)?KT.H:null])):null));return $CLJS.n(a)?a:qP}(),KT],null)])));
$CLJS.X(LM,cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof eT?new $CLJS.Gc(function(){return eT},$CLJS.od(xja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uQ,"metabase/mbql/schema.cljc",59,$CLJS.CF,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CF,$CLJS.ky,$CLJS.U($CLJS.Rj,VQ),$CLJS.xv,$CLJS.U($CLJS.Rj,via),$CLJS.Ox,$CLJS.U($CLJS.Rj,$CLJS.U(gR,QQ))],null),$CLJS.Kc,"Schema for a valid substring clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:uQ}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof gT?new $CLJS.Gc(function(){return gT},
$CLJS.od(Xja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RQ,"metabase/mbql/schema.cljc",54,$CLJS.zG,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zG,$CLJS.ky,$CLJS.U($CLJS.Rj,VQ)],
null),$CLJS.Kc,"Schema for a valid trim clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:RQ}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof iT?new $CLJS.Gc(function(){return iT},$CLJS.od(aja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,
1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AQ,"metabase/mbql/schema.cljc",55,$CLJS.BG,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BG,$CLJS.ky,$CLJS.U($CLJS.Rj,VQ)],null),$CLJS.Kc,"Schema for a valid ltrim clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:AQ}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&
"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof hT?new $CLJS.Gc(function(){return hT},$CLJS.od(yja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CP,"metabase/mbql/schema.cljc",
55,$CLJS.IG,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IG,$CLJS.ky,$CLJS.U($CLJS.Rj,VQ)],null),$CLJS.Kc,"Schema for a valid rtrim clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:CP}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof lT?new $CLJS.Gc(function(){return lT},$CLJS.od(Pha,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,
$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CR,"metabase/mbql/schema.cljc",57,$CLJS.HG,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HG,$CLJS.ky,$CLJS.U($CLJS.Rj,VQ),aia,$CLJS.U($CLJS.Rj,$CLJS.ak),$CLJS.jA,$CLJS.U($CLJS.Rj,$CLJS.ak)],null),$CLJS.Kc,"Schema for a valid replace clause.",
$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:CR}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof kT?new $CLJS.Gc(function(){return kT},$CLJS.od(Bja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,
new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hS,"metabase/mbql/schema.cljc",55,$CLJS.It,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.It,$CLJS.ky,$CLJS.U($CLJS.Rj,VQ)],null),$CLJS.Kc,"Schema for a valid lower clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:hS}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==
typeof gM&&"undefined"!==typeof jT?new $CLJS.Gc(function(){return jT},$CLJS.od(Yja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dR,"metabase/mbql/schema.cljc",55,$CLJS.Ct,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ct,$CLJS.ky,$CLJS.U($CLJS.Rj,VQ)],null),$CLJS.Kc,"Schema for a valid upper clause.",$CLJS.n(jT)?jT.H:null])):null));return $CLJS.n(a)?a:dR}(),jT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof mT?new $CLJS.Gc(function(){return mT},$CLJS.od(pja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,
$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",56,$CLJS.JF,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JF,AR,$CLJS.U($CLJS.Rj,VQ),zR,$CLJS.U($CLJS.Rj,VQ),IN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,VQ))],null),$CLJS.Kc,"Schema for a valid concat clause.",
$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:qO}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof nT?new $CLJS.Gc(function(){return nT},$CLJS.od(wja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.YP,"null",$CLJS.Vda,"null"],
null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nN,"metabase/mbql/schema.cljc",74,$CLJS.lG,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.lG,$CLJS.ky,$CLJS.U($CLJS.Rj,VQ),$CLJS.iA,$CLJS.U($CLJS.Rj,$CLJS.ak)],null),$CLJS.Kc,"Schema for a valid regex-match-first clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:nN}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==
typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof dT?new $CLJS.Gc(function(){return dT},$CLJS.od(DQ,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.YP,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
SQ,"metabase/mbql/schema.cljc",58,$CLJS.mG,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mG,AR,$CLJS.U($CLJS.Rj,vM),zR,$CLJS.U($CLJS.Rj,vM),IN,$CLJS.U($CLJS.Rj,$CLJS.U($CLJS.Ni,vM))],null),$CLJS.Kc,"Schema for a valid coalesce clause.",$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:SQ}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},
$CLJS.od(GM,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",61,$CLJS.TF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.TF,$CLJS.ax,$CLJS.U($CLJS.Rj,YN),
$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,HP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:oP}(),pU],null)])));
$CLJS.qU=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,":field or :expression reference or expression",$CLJS.Pi,function(a){return $CLJS.n(aM(SS,a))?fS:$CLJS.n(aM(PS,a))?$CLJS.ak:$CLJS.n(aM(TS,a))?$CLJS.Gs:$CLJS.n(aM(VS,a))?$CLJS.aJ:$CLJS.n(aM($CLJS.TF,a))?$CLJS.TF:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[fS,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ak,QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Gs,XS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aJ,$CLJS.YS],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,$CLJS.MS],null)],null);$CLJS.X(LR,bM($CLJS.Qx,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.MS],null)])));var rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LR],null);$CLJS.X(zP,bM($CLJS.kG,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ks,$CLJS.MS],null)])));var sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zP],null);$CLJS.X(JM,bM($CLJS.dG,$CLJS.H(["field-or-expression",$CLJS.qU])));
var tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JM],null);$CLJS.X(DR,bM($CLJS.FG,$CLJS.H(["field-or-expression",$CLJS.qU])));var uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DR],null);$CLJS.X(iO,bM($CLJS.PF,$CLJS.H(["field-or-expression",$CLJS.qU])));var vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iO],null);$CLJS.X(TQ,bM($CLJS.wG,$CLJS.H(["field-or-expression",$CLJS.qU])));var wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TQ],null);$CLJS.X(dO,bM($CLJS.ao,$CLJS.H(["field-or-expression",$CLJS.qU])));
var xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dO],null);$CLJS.X(QN,bM($CLJS.kk,$CLJS.H(["field-or-expression",$CLJS.qU])));var yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QN],null);$CLJS.X(KN,bM($CLJS.DG,$CLJS.H(["field-or-expression",$CLJS.qU,"pred",$CLJS.QT])));var zU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KN],null);$CLJS.X(aO,bM($CLJS.sG,$CLJS.H(["pred",$CLJS.QT])));var AU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aO],null);$CLJS.X(KQ,bM($CLJS.qG,$CLJS.H(["pred",$CLJS.QT])));
var BU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KQ],null);$CLJS.X(gN,bM($CLJS.yG,$CLJS.H(["field-or-expression",$CLJS.qU])));var CU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gN],null);$CLJS.X(iM,bM($CLJS.QF,$CLJS.H(["field-or-expression",$CLJS.qU])));var DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iM],null);$CLJS.X(mO,bM($CLJS.xG,$CLJS.H(["field-or-expression",$CLJS.qU])));var EU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mO],null);$CLJS.X($O,bM($CLJS.VF,$CLJS.H(["field-or-expression",$CLJS.qU,"percentile",$S])));
var FU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$O],null);$CLJS.X(NQ,bM($CLJS.eG,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NK],null),nS],null)])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NQ],null);
$CLJS.X(JQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"unnamed aggregation clause or numeric expression",$CLJS.Pi,function(a){return $CLJS.n(aM(SS,a))?cP:$CLJS.Et}],null),new $CLJS.P(null,2,5,$CLJS.Q,[cP,WS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Et,cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof tU?new $CLJS.Gc(function(){return tU},$CLJS.od(vja,
new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",60,$CLJS.dG,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dG,eR,$CLJS.U($CLJS.Rj,XQ)],null),$CLJS.Kc,"Schema for a valid avg clause.",
$CLJS.n(tU)?tU.H:null])):null));return $CLJS.n(a)?a:lP}(),tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof uU?new $CLJS.Gc(function(){return uU},$CLJS.od(Gia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,
new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",64,$CLJS.FG,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,eR,$CLJS.U($CLJS.Rj,XQ)],null),$CLJS.Kc,"Schema for a valid cum-sum clause.",$CLJS.n(uU)?uU.H:null])):null));return $CLJS.n(a)?a:qR}(),uU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==
typeof gM&&"undefined"!==typeof vU?new $CLJS.Gc(function(){return vU},$CLJS.od(xia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pP,"metabase/mbql/schema.cljc",65,$CLJS.PF,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PF,eR,$CLJS.U($CLJS.Rj,XQ)],null),$CLJS.Kc,"Schema for a valid distinct clause.",$CLJS.n(vU)?vU.H:null])):null));return $CLJS.n(a)?a:pP}(),vU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof CU?new $CLJS.Gc(function(){return CU},$CLJS.od(Rja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,
$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.tG,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DM,"metabase/mbql/schema.cljc",76,$CLJS.yG,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yG,eR,$CLJS.U($CLJS.Rj,XQ)],null),$CLJS.Kc,"Schema for a valid stddev clause.",$CLJS.n(CU)?CU.H:null])):null));return $CLJS.n(a)?a:DM}(),CU],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof wU?new $CLJS.Gc(function(){return wU},$CLJS.od(kja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ZM,"metabase/mbql/schema.cljc",60,$CLJS.wG,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wG,eR,$CLJS.U($CLJS.Rj,XQ)],null),$CLJS.Kc,"Schema for a valid sum clause.",$CLJS.n(wU)?wU.H:null])):null));return $CLJS.n(a)?a:ZM}(),wU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof xU?new $CLJS.Gc(function(){return xU},$CLJS.od(Yha,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,
$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Ws,"metabase/mbql/schema.cljc",60,$CLJS.ao,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ao,eR,$CLJS.U($CLJS.Rj,XQ)],null),$CLJS.Kc,"Schema for a valid min clause.",$CLJS.n(xU)?xU.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Ws}(),xU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof yU?new $CLJS.Gc(function(){return yU},$CLJS.od(Tha,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,
1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.$s,"metabase/mbql/schema.cljc",60,$CLJS.kk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kk,eR,$CLJS.U($CLJS.Rj,XQ)],null),$CLJS.Kc,"Schema for a valid max clause.",$CLJS.n(yU)?yU.H:null])):null));return $CLJS.n(a)?a:$CLJS.$s}(),yU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&
"undefined"!==typeof GU?new $CLJS.Gc(function(){return GU},$CLJS.od(uia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",18,$CLJS.eG,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eG,Hia,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.qs,Lia,UM],null))],null),$CLJS.Kc,"Schema for a valid metric clause.",$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:pO}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof BU?new $CLJS.Gc(function(){return BU},$CLJS.od(Tia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,
$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hO,"metabase/mbql/schema.cljc",62,$CLJS.qG,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,$CLJS.to,$CLJS.U($CLJS.Rj,YO)],null),$CLJS.Kc,"Schema for a valid share clause.",$CLJS.n(BU)?BU.H:null])):null));return $CLJS.n(a)?a:hO}(),BU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof AU?new $CLJS.Gc(function(){return AU},$CLJS.od(Zha,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VM,"metabase/mbql/schema.cljc",68,$CLJS.sG,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sG,$CLJS.to,$CLJS.U($CLJS.Rj,YO)],null),$CLJS.Kc,"Schema for a valid count-where clause.",$CLJS.n(AU)?AU.H:null])):null));return $CLJS.n(a)?a:VM}(),AU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof zU?new $CLJS.Gc(function(){return zU},$CLJS.od(eka,new $CLJS.h(null,1,[$CLJS.ck,!0],
null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",66,$CLJS.DG,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.DG,eR,$CLJS.U($CLJS.Rj,XQ),$CLJS.to,$CLJS.U($CLJS.Rj,YO)],null),$CLJS.Kc,"Schema for a valid sum-where clause.",
$CLJS.n(zU)?zU.H:null])):null));return $CLJS.n(a)?a:mP}(),zU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof pU?new $CLJS.Gc(function(){return pU},$CLJS.od(GM,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,
new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oP,"metabase/mbql/schema.cljc",61,$CLJS.TF,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.TF,$CLJS.ax,$CLJS.U($CLJS.Rj,YN),$CLJS.gj,$CLJS.U($CLJS.Rj,$CLJS.U(gR,HP))],null),$CLJS.Kc,"Schema for a valid case clause.",$CLJS.n(pU)?pU.H:null])):null));return $CLJS.n(a)?a:oP}(),pU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&
"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof EU?new $CLJS.Gc(function(){return EU},$CLJS.od(Kja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fG,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oR,"metabase/mbql/schema.cljc",
68,$CLJS.xG,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xG,eR,$CLJS.U($CLJS.Rj,XQ)],null),$CLJS.Kc,"Schema for a valid median clause.",$CLJS.n(EU)?EU.H:null])):null));return $CLJS.n(a)?a:oR}(),EU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof FU?new $CLJS.Gc(function(){return FU},$CLJS.od(eja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,
hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.fG,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",72,$CLJS.VF,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.VF,eR,$CLJS.U($CLJS.Rj,XQ),$CLJS.VF,$CLJS.U($CLJS.Rj,QQ)],null),$CLJS.Kc,"Schema for a valid percentile clause.",$CLJS.n(FU)?FU.H:null])):
null));return $CLJS.n(a)?a:MQ}(),FU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof DU?new $CLJS.Gc(function(){return DU},$CLJS.od(iia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yM,"metabase/mbql/schema.cljc",77,$CLJS.QF,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QF,eR,$CLJS.U($CLJS.Rj,XQ)],null),$CLJS.Kc,"Schema for a valid var clause.",$CLJS.n(DU)?DU.H:null])):null));return $CLJS.n(a)?a:yM}(),DU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof sU?new $CLJS.Gc(function(){return sU},$CLJS.od(Vha,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,
$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZO,"metabase/mbql/schema.cljc",74,$CLJS.kG,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kG,$CLJS.jG,$CLJS.U($CLJS.Rj,$CLJS.U(gR,EN))],null),$CLJS.Kc,"Schema for a valid cum-count clause.",
$CLJS.n(sU)?sU.H:null])):null));return $CLJS.n(a)?a:ZO}(),sU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof rU?new $CLJS.Gc(function(){return rU},$CLJS.od(wia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([fP,$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,iR,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.EF,"null"],null),null),
$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.xi,"metabase/mbql/schema.cljc",70,$CLJS.Qx,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qx,$CLJS.jG,$CLJS.U($CLJS.Rj,$CLJS.U(gR,EN))],null),$CLJS.Kc,"Schema for a valid count clause.",$CLJS.n(rU)?rU.H:null])):null));return $CLJS.n(a)?a:$CLJS.xi}(),rU],null)]))],null)],null));
$CLJS.X(aQ,bM($CLJS.tM,$CLJS.H(["aggregation",JQ,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jk,new $CLJS.h(null,1,[$CLJS.pt,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),nS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),nS],null)],null)])));
$CLJS.X(fR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.pt,"aggregation clause or numeric expression",$CLJS.Pi,function(a){return $CLJS.n(aM($CLJS.tM,a))?$CLJS.tM:XN}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aQ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[XN,JQ],null)],null));$CLJS.X(CM,bM($CLJS.gG,$CLJS.H(["field",OS])));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CM],null);$CLJS.X(bP,bM($CLJS.$F,$CLJS.H(["field",OS])));
IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bP],null);
wka=cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof HU?new $CLJS.Gc(function(){return HU},$CLJS.od(Oja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
IO,"metabase/mbql/schema.cljc",15,$CLJS.gG,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gG,$CLJS.jG,$CLJS.U($CLJS.Rj,yR)],null),$CLJS.Kc,"Schema for a valid asc clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:IO}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof IU?new $CLJS.Gc(function(){return IU},$CLJS.od(Fia,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,
$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EQ,"metabase/mbql/schema.cljc",16,$CLJS.$F,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$F,$CLJS.jG,$CLJS.U($CLJS.Rj,yR)],null),$CLJS.Kc,"Schema for a valid desc clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:EQ}(),IU],null)]));
JU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ns,$CLJS.FM,$CLJS.uN,$CLJS.DF,$CLJS.Rt,$CLJS.uB,$CLJS.AP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oE,nS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),nS],null)],null);
xka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,JU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.FM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rM,nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SP,qS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),qS],null)],null)],null);
yka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,JU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.uN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bR,qS],null)],null)],null);
KU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,JU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.li],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null)],null)],null);zka=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pR],null);
Aka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,KU,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.DF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DF,LS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YM],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.Yi,$CLJS.li],
null)],null)],null)],null)],null);$CLJS.LU=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.AP,null,$CLJS.Rt,null,$CLJS.Gs,null,$CLJS.uB,null],null),null);Bka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ns],null),$CLJS.LU);
$CLJS.X(xQ,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DF,Aka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FM,xka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uN,yka],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bp,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,KU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,Bka],null)],null)],null)],null)],null));
var MU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,nS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.T.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,nS],null)],null)],null),Cka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,MU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GF,$CLJS.li],null)],null)],null),NU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eQ],null),OU=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Pi,function(a){return $CLJS.n($CLJS.hE($CLJS.xd,
$CLJS.hQ)(a))?$CLJS.hQ:$CLJS.nE}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,MU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hQ,$CLJS.li],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nE,NU],null)],null),PU=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dD,oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[mja,nS],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.CO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,pS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.jk],null)],null)],null),QU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,oka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rs,new $CLJS.h(null,1,[$CLJS.pt,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Dka=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ns],null),
new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.iF,null,$CLJS.SE,null,$CLJS.bF,null,$CLJS.fF,null],null),null)),RU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nR],null);
$CLJS.X(gO,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),QU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PR,$CLJS.QT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Dka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.pt,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,$CLJS.vy,$CLJS.dA],null),RU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),nS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$K,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,sS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QO,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,PU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.hE($CLJS.yA($CLJS.dP,$CLJS.HQ),$CLJS.Te($CLJS.hE($CLJS.dP,$CLJS.HQ)))],null)],null));var Eka=$CLJS.Q,SU;var TU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gO],null)],null);
if($CLJS.wd(TU)&&$CLJS.E.g($CLJS.A(TU),$CLJS.Aj)){var UU=$CLJS.y(TU);$CLJS.A(UU);var VU=$CLJS.B(UU),Fka=$CLJS.xd($CLJS.A(VU))?VU:$CLJS.ee(null,VU),WU=$CLJS.y(Fka),Gka=$CLJS.A(WU),Hka=$CLJS.B(WU);SU=$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.R.j(Gka,$CLJS.ao,1)],null),Hka)}else SU=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,TU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.X(iN,new $CLJS.P(null,3,5,Eka,[$CLJS.xs,SU,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"All join aliases must be unique."],null),function(a){return $CLJS.NL($CLJS.lf($CLJS.Wa,$CLJS.hf.g($CLJS.XE,a)))}],null)],null));var Ika=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iN],null);
$CLJS.X(nR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sl,new $CLJS.h(null,1,[$CLJS.pt,"Distinct, non-empty sequence of Field clauses"],null),dM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),$CLJS.MS],null))],null));
$CLJS.X(eQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),OU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),QU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),$CLJS.ZS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,new $CLJS.h(null,1,
[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),$CLJS.MS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,nS,$CLJS.qU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),RU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.QT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kS,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),rS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),dM(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),wka],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[iQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[iQ,qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[nia,qS],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Ika],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,PU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.gm(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HQ,$CLJS.dP],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,
new $CLJS.h(null,1,[$CLJS.pt,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.YF);b=$CLJS.J.g(b,$CLJS.TE);return $CLJS.td($CLJS.fu.g($CLJS.fh(a),$CLJS.fh(b)))}],null)],null));
$CLJS.XU=$CLJS.Ig([$CLJS.CG,$CLJS.AP,cO,ON,RO,bS,xO,$CLJS.aS,$CLJS.Rt,wO,MM,UQ,QR,$CLJS.bN,MR,$N,$CLJS.WO,uO,$CLJS.$i,$CLJS.tO,uM,$CLJS.yN,$CLJS.sO,vP,$CLJS.Gs,$CLJS.uB,oO],[new $CLJS.h(null,1,[HO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.CG,null,$CLJS.AP,null,$CLJS.Rt,null,$CLJS.Gs,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.AP,HO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.CG,null,$CLJS.AP,null,$CLJS.aS,null,$CLJS.WO,null,$CLJS.$i,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.nj,$CLJS.ak,$CLJS.wE,cR,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[cO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,fS,$CLJS.wE,cR,HO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.CG,null,ON,null,$CLJS.Rt,null,$CLJS.$i,null,uM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.ak,$CLJS.wE,QM,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[RO,null],null),null)],null),new $CLJS.h(null,1,[HO,new $CLJS.ah(null,new $CLJS.h(null,1,[bS,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,
fS,$CLJS.wE,Eja,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[xO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.AP,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.aS,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,fS,HO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.CG,null,ON,null,$CLJS.Rt,null,$CLJS.$i,null,uM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,fS,$CLJS.wE,QM,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[wO,null],null),null)],null),new $CLJS.h(null,1,[HO,new $CLJS.ah(null,
new $CLJS.h(null,1,[MM,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.ak,$CLJS.wE,cR,HO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.CG,null,bS,null,MM,null,UQ,null,uO,null,$CLJS.$i,null,uM,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.ak,$CLJS.wE,QM,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[QR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.AP,HO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.aS,null,$CLJS.bN,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.nj,$CLJS.ak,$CLJS.wE,QM,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[MR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,$CLJS.ak,$CLJS.wE,QM,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[$N,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.AP,HO,new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.CG,null,$CLJS.AP,null,$CLJS.aS,null,$CLJS.WO,null,$CLJS.$i,null],null),null)],null),new $CLJS.h(null,1,[HO,new $CLJS.ah(null,new $CLJS.h(null,1,[uO,null],null),null)],null),new $CLJS.h(null,1,[HO,
new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.$i,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.AP,HO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.aS,null,$CLJS.tO,null],null),null)],null),new $CLJS.h(null,1,[HO,new $CLJS.ah(null,new $CLJS.h(null,1,[uM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.AP,HO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.aS,null,$CLJS.yN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.AP,HO,new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.aS,
null,$CLJS.sO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,fS,$CLJS.wE,cR,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[vP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.Gs,HO,new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.CG,null,$CLJS.$i,null,$CLJS.Gs,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.nj,$CLJS.ak,HO,new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.CG,null,bS,null,MM,null,UQ,null,uO,null,$CLJS.$i,null,uM,null,$CLJS.uB,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.nj,
fS,$CLJS.wE,QM,HO,new $CLJS.ah(null,new $CLJS.h(null,1,[oO,null],null),null)],null)]);$CLJS.X(pR,$CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"valid parameter type"],null)],null),$CLJS.Xg($CLJS.XU)));$CLJS.X(YM,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"valid template tag widget type"],null),$CLJS.dA],null),$CLJS.Xg($CLJS.XU)));
$CLJS.X(CQ,bM(eia,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,nS,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$i,nS],null)],null)],null)])));var YU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CQ],null);$CLJS.X(xM,bM($CLJS.DF,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.MS,YU],null)])));var ZU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xM],null);$CLJS.X(sN,bM(rO,$CLJS.H(["target",YU])));
var $U=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sN],null),Jka=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.MS,cM($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof ZU?new $CLJS.Gc(function(){return ZU},$CLJS.od(cja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,
1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mQ,"metabase/mbql/schema.cljc",21,$CLJS.DF,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.sJ,$CLJS.U($CLJS.Rj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,EN,GO],null))],null),$CLJS.Kc,"Schema for a valid dimension clause.",$CLJS.n(ZU)?ZU.H:null])):null));return $CLJS.n(a)?a:mQ}(),ZU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=hP.h($CLJS.pd("undefined"!==typeof $CLJS.eM&&
"undefined"!==typeof fM&&"undefined"!==typeof gM&&"undefined"!==typeof $U?new $CLJS.Gc(function(){return $U},$CLJS.od(sja,new $CLJS.h(null,1,[$CLJS.ck,!0],null)),$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,hP,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,dN,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.od(OQ,new $CLJS.h(null,1,[$CLJS.lk,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gS,"metabase/mbql/schema.cljc",20,rO,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[rO,
$CLJS.sJ,$CLJS.U($CLJS.Rj,GO)],null),$CLJS.Kc,"Schema for a valid variable clause.",$CLJS.n($U)?$U.H:null])):null));return $CLJS.n(a)?a:gS}(),$U],null)]))],null),YL=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eN],null);
$CLJS.X(eN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.hE($CLJS.yA($CLJS.hQ,$CLJS.GF),$CLJS.Te($CLJS.hE($CLJS.hQ,$CLJS.GF)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.hQ);var d=$CLJS.J.g(c,$CLJS.GF);c=$CLJS.J.g(c,$CLJS.nj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qs,new $CLJS.h(null,1,[$CLJS.pt,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dL],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.YK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,$CLJS.GF,$CLJS.hQ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hQ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Cka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),NU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,zka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),nS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sJ,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),Jka],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.li],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),nS],null),new $CLJS.P(null,3,5,$CLJS.Q,[kia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),
nS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.li],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bL,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[cka,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),AS],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[bja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[nP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),rS],null),new $CLJS.P(null,3,5,$CLJS.Q,[XM,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),rS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,nP);b=$CLJS.J.g(b,XM);return $CLJS.Va($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[Nja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[gia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qha,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[mia,new $CLJS.h(null,1,[$CLJS.Ks,
!0],null),$CLJS.Gs],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sha,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Tja,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Gs],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fy,new $CLJS.h(null,
1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.ns,rja,oja,$CLJS.kO,qia,mka,tja,Iia,dja,Aia,hia,Eia,Cja,Qia,bia,ria,lka,ika],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ija,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,qS],null)],null),new $CLJS.P(null,3,5,
$CLJS.Q,[Nia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,qS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,nka],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[dka,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,nS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,qS],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[bka,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.li,$CLJS.li],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Jia,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,qS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.vs,$CLJS.li,$CLJS.li],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LK,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.li],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Te($CLJS.QO)],null)],null));var aV=$CLJS.XL(YL);
(function(){var a=Lha();return function(b){if($CLJS.n(aV.h?aV.h(b):aV.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.UL(b);throw $CLJS.hi($CLJS.jE("Invalid query: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.By,c,Xia,b],null));}})();