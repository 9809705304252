var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var lfa,mfa,nfa,ofa,pfa,qfa,rfa,PG,sfa,tfa,ufa,vfa,wfa,xfa,yfa,zfa,RG,Afa;$CLJS.KG=new $CLJS.M(null,"supported-field","supported-field",-2061545519);lfa=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.LG=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);mfa=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
nfa=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.MG=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.NG=new $CLJS.M(null,"column-name","column-name",551523580);ofa=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.OG=new $CLJS.M(null,"display-info","display-info",-816930907);pfa=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
qfa=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);rfa=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);PG=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);sfa=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);tfa=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);ufa=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
vfa=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);wfa=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);xfa=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);yfa=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.QG=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);zfa=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
RG=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.SG=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);Afa=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.AF($CLJS.Qx,$CLJS.H([$CLJS.it,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)],null)],null)]));$CLJS.AF($CLJS.kG,$CLJS.H([$CLJS.it,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)],null)],null)]));
$CLJS.yF($CLJS.dG,$CLJS.H([$CLJS.it,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.yF($CLJS.PF,$CLJS.H([$CLJS.it,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.ZE],null)],null)]));$CLJS.yF($CLJS.sG,$CLJS.H([$CLJS.it,$CLJS.xj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)],null)]));
$CLJS.yF($CLJS.kk,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)]));$CLJS.rF($CLJS.kk,$CLJS.dF);$CLJS.yF($CLJS.xG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.rF($CLJS.xG,$CLJS.dF);$CLJS.yF($CLJS.ao,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eF],null)],null)]));$CLJS.rF($CLJS.ao,$CLJS.dF);
$CLJS.X(PG,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.xs,new $CLJS.h(null,1,[$CLJS.pt,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.yF($CLJS.VF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PG],null)]));$CLJS.rF($CLJS.VF,$CLJS.dF);
$CLJS.yF($CLJS.qG,$CLJS.H([$CLJS.it,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)],null)]));$CLJS.yF($CLJS.yG,$CLJS.H([$CLJS.it,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.yF($CLJS.wG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));
$CLJS.yF($CLJS.FG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.rF($CLJS.wG,$CLJS.dF);$CLJS.rF($CLJS.FG,$CLJS.dF);$CLJS.yF($CLJS.DG,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)],null)]));$CLJS.rF($CLJS.DG,$CLJS.dF);
$CLJS.yF($CLJS.QF,$CLJS.H([$CLJS.it,$CLJS.ND,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null)]));$CLJS.X(RG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.qs,zfa,xfa,qfa,mfa,lfa,pfa,rfa,sfa,tfa,wfa,ufa,Afa,yfa,nfa,vfa,$CLJS.Fl],null));$CLJS.X($CLJS.LG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RG],null)],null));
$CLJS.TG=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.cG,$CLJS.Qx,$CLJS.MG,!1,$CLJS.QG,$CLJS.EF,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Count of rows"),$CLJS.NG,$CLJS.LE("Count"),$CLJS.Jt,$CLJS.LE("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cG,$CLJS.wG,$CLJS.KG,$CLJS.gk,$CLJS.MG,!0,$CLJS.QG,$CLJS.EF,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Sum of ..."),$CLJS.NG,$CLJS.LE("Sum"),$CLJS.Jt,$CLJS.LE("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.cG,$CLJS.dG,$CLJS.KG,$CLJS.gk,$CLJS.MG,!0,$CLJS.QG,$CLJS.EF,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Average of ..."),$CLJS.NG,$CLJS.LE("Average"),$CLJS.Jt,$CLJS.LE("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.cG,$CLJS.xG,$CLJS.KG,$CLJS.gk,$CLJS.MG,!0,$CLJS.QG,$CLJS.fG,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Median of ..."),$CLJS.NG,$CLJS.LE("Median"),$CLJS.Jt,$CLJS.LE("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.cG,$CLJS.PF,$CLJS.KG,$CLJS.li,$CLJS.MG,!0,$CLJS.QG,$CLJS.EF,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Number of distinct values of ..."),$CLJS.NG,$CLJS.LE("Distinct values"),$CLJS.Jt,$CLJS.LE("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cG,$CLJS.FG,$CLJS.KG,$CLJS.gk,$CLJS.MG,!0,$CLJS.QG,$CLJS.EF,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Cumulative sum of ..."),
$CLJS.NG,$CLJS.LE("Sum"),$CLJS.Jt,$CLJS.LE("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.cG,$CLJS.kG,$CLJS.MG,!1,$CLJS.QG,$CLJS.EF,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Cumulative count of rows"),$CLJS.NG,$CLJS.LE("Count"),$CLJS.Jt,$CLJS.LE("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cG,$CLJS.yG,$CLJS.KG,$CLJS.gk,$CLJS.MG,
!0,$CLJS.QG,$CLJS.tG,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Standard deviation of ..."),$CLJS.NG,$CLJS.LE("SD"),$CLJS.Jt,$CLJS.LE("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.cG,$CLJS.ao,$CLJS.KG,$CLJS.Zi,$CLJS.MG,!0,$CLJS.QG,$CLJS.EF,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Minimum of ..."),$CLJS.NG,$CLJS.LE("Min"),$CLJS.Jt,$CLJS.LE("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.cG,$CLJS.kk,$CLJS.KG,$CLJS.Zi,$CLJS.MG,!0,$CLJS.QG,$CLJS.EF,$CLJS.OG,function(){return new $CLJS.h(null,3,[$CLJS.oE,$CLJS.LE("Maximum of ..."),$CLJS.NG,$CLJS.LE("Max"),$CLJS.Jt,$CLJS.LE("Maximum value of a column")],null)}],null)],null);
$CLJS.X(ofa,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.SG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG,$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ns],null),$CLJS.hf.h($CLJS.cG),$CLJS.TG)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.Yi],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MG,$CLJS.Gs],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.QG,$CLJS.Yi],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OG,$CLJS.md],null)],null));