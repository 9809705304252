var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var c4,e4,f4,g4,h4,i4,Tpa;c4=function(a,b,c){var d=$CLJS.DW(a,b);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.ZF);d=$CLJS.tH($CLJS.Rk.j($CLJS.ch([c]),$CLJS.AE,$CLJS.hd),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.jE("No aggregation with uuid {0}",$CLJS.H([c])),new $CLJS.h(null,3,[$CLJS.Fs,c,$CLJS.GF,a,$CLJS.DL,b],null));return d};$CLJS.d4=function(a){return $CLJS.X1($CLJS.wG,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};
e4=new $CLJS.M("metabase.lib.aggregation","no-semantic-type","metabase.lib.aggregation/no-semantic-type",-789440092);f4=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);g4=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);h4=new $CLJS.M("metabase.lib.aggregation","quantity-aggregation","metabase.lib.aggregation/quantity-aggregation",411632918);
i4=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);Tpa=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.z0.m(null,$CLJS.ZF,function(a,b){var c=$CLJS.Fe($CLJS.ZF.h($CLJS.DW(a,b)));return $CLJS.n(c)?$CLJS.Z1($CLJS.LE("and"),function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.kd(l,u);v=$CLJS.X_.v(a,b,v,$CLJS.Y_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),null)}t=$CLJS.A(k);return $CLJS.ee($CLJS.X_.v(a,
b,t,$CLJS.Y_),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):null});$CLJS.C0.m(null,$CLJS.ZF,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.pB);e=$CLJS.J.g(e,$CLJS.Fi);c=$CLJS.I(c,2,null);c=c4(a,b,c);return $CLJS.il.l($CLJS.H([$CLJS.W_.j(a,b,c),new $CLJS.h(null,2,[$CLJS.OK,$CLJS.iL,$CLJS.$0,$CLJS.AE.h($CLJS.hd(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.pB,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Fi,e],null):null]))});
$CLJS.w0.m(null,$CLJS.ZF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return $CLJS.X_.v(a,b,c4(a,b,c),d)});$CLJS.rF(g4,i4);
for(var j4=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Qx,$CLJS.kG,$CLJS.sG],null)),k4=null,l4=0,m4=0;;)if(m4<l4){var Upa=k4.X(null,m4);$CLJS.rF(Upa,g4);m4+=1}else{var n4=$CLJS.y(j4);if(n4){var o4=n4;if($CLJS.Ad(o4)){var p4=$CLJS.lc(o4),Vpa=$CLJS.mc(o4),Wpa=p4,Xpa=$CLJS.D(p4);j4=Vpa;k4=Wpa;l4=Xpa}else{var Ypa=$CLJS.A(o4);$CLJS.rF(Ypa,g4);j4=$CLJS.B(o4);k4=null;l4=0}m4=0}else break}
$CLJS.w0.m(null,g4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);if($CLJS.n(c))switch(a=$CLJS.X_.v(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.jE("Count of {0}",$CLJS.H([a]));case "cum-count":return $CLJS.jE("Cumulative count of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.LE("Count");case "cum-count":return $CLJS.LE("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.x0.m(null,g4,function(a,b,c){a=$CLJS.I(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";case "count-where":return"count_where";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.C0.m(null,h4,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.mI($CLJS.C0,i4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Wj,$CLJS.SC)});
$CLJS.rF(h4,i4);$CLJS.rF(g4,h4);$CLJS.rF($CLJS.PF,h4);$CLJS.w0.m(null,$CLJS.TF,function(){return $CLJS.LE("Case")});$CLJS.x0.m(null,$CLJS.TF,function(){return"case"});$CLJS.rF(f4,i4);
for(var q4=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dG,$CLJS.FG,$CLJS.PF,$CLJS.kk,$CLJS.xG,$CLJS.ao,$CLJS.yG,$CLJS.wG,$CLJS.QF],null)),r4=null,s4=0,t4=0;;)if(t4<s4){var Zpa=r4.X(null,t4);$CLJS.rF(Zpa,f4);t4+=1}else{var u4=$CLJS.y(q4);if(u4){var v4=u4;if($CLJS.Ad(v4)){var w4=$CLJS.lc(v4),$pa=$CLJS.mc(v4),aqa=w4,bqa=$CLJS.D(w4);q4=$pa;r4=aqa;s4=bqa}else{var cqa=$CLJS.A(v4);$CLJS.rF(cqa,f4);q4=$CLJS.B(v4);r4=null;s4=0}t4=0}else break}
$CLJS.x0.m(null,f4,function(a,b,c){a=$CLJS.I(c,0,null);$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.w0.m(null,f4,function(a,b,c,d){var e=$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);a=$CLJS.X_.v(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.jE("Average of {0}",$CLJS.H([a]));case "cum-sum":return $CLJS.jE("Cumulative sum of {0}",$CLJS.H([a]));case "distinct":return $CLJS.jE("Distinct values of {0}",$CLJS.H([a]));case "max":return $CLJS.jE("Max of {0}",$CLJS.H([a]));case "median":return $CLJS.jE("Median of {0}",$CLJS.H([a]));case "min":return $CLJS.jE("Min of {0}",
$CLJS.H([a]));case "stddev":return $CLJS.jE("Standard deviation of {0}",$CLJS.H([a]));case "sum":return $CLJS.jE("Sum of {0}",$CLJS.H([a]));case "var":return $CLJS.jE("Variance of {0}",$CLJS.H([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.w0.m(null,$CLJS.VF,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);c=$CLJS.I(c,3,null);return $CLJS.jE("{0}th percentile of {1}",$CLJS.H([c,$CLJS.X_.v(a,b,e,d)]))});$CLJS.x0.m(null,$CLJS.VF,function(){return"percentile"});
$CLJS.rF(e4,i4);for(var x4=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VF,$CLJS.QF],null)),y4=null,z4=0,A4=0;;)if(A4<z4){var dqa=y4.X(null,A4);$CLJS.rF(dqa,e4);A4+=1}else{var B4=$CLJS.y(x4);if(B4){var C4=B4;if($CLJS.Ad(C4)){var D4=$CLJS.lc(C4),eqa=$CLJS.mc(C4),fqa=D4,gqa=$CLJS.D(D4);x4=eqa;y4=fqa;z4=gqa}else{var hqa=$CLJS.A(C4);$CLJS.rF(hqa,e4);x4=$CLJS.B(C4);y4=null;z4=0}A4=0}else break}
$CLJS.C0.m(null,e4,function(a,b,c){var d=$CLJS.Jk.g,e=$CLJS.mI($CLJS.C0,i4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.Jk,a,$CLJS.Wj)});$CLJS.w0.m(null,$CLJS.DG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.jE("Sum of {0} matching condition",$CLJS.H([$CLJS.X_.v(a,b,e,d)]))});
$CLJS.x0.m(null,$CLJS.DG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.y0.j(a,b,d))].join("")});$CLJS.rF($CLJS.DG,i4);$CLJS.w0.m(null,$CLJS.qG,function(){return $CLJS.LE("Share of rows matching condition")});$CLJS.x0.m(null,$CLJS.qG,function(){return"share"});$CLJS.C0.m(null,$CLJS.qG,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.mI($CLJS.C0,i4);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Wj,$CLJS.kD)});
$CLJS.rF($CLJS.qG,i4);$CLJS.w0.m(null,$CLJS.sG,function(){return $CLJS.LE("Count of rows matching condition")});$CLJS.C0.m(null,i4,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.il.l;d=$CLJS.n(d)?$CLJS.gm($CLJS.W_.j(a,b,d),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bL,$CLJS.Wj],null)):null;var f=$CLJS.mI($CLJS.C0,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.il,$CLJS.H([d,a]))});
$CLJS.iqa=function(){function a(d){return $CLJS.X1($CLJS.Qx,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.X1($CLJS.Qx,$CLJS.Cf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.mW.m(null,$CLJS.ZF,function(a){return a});
$CLJS.E4=function(){function a(d,e,f){for(;;)if($CLJS.E.g($CLJS.cC(f),$CLJS.SK))f=$CLJS.oW(f);else return $CLJS.$1(d,e,$CLJS.ZF,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.F4=function(){function a(d,e){return $CLJS.Fe($CLJS.ZF.h($CLJS.DW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.G4=function(){function a(d,e){var f=$CLJS.Fe($CLJS.ZF.h($CLJS.DW(d,e)));return null==f?null:$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(k){var l=$CLJS.W_.j(d,e,k),m=$CLJS.R.l,t=$CLJS.pB.h(l);return m.call($CLJS.R,$CLJS.W1(l,$CLJS.Fi,$CLJS.n(t)?t:$CLJS.Bj),$CLJS.OK,$CLJS.iL,$CLJS.H([$CLJS.$0,$CLJS.AE.h($CLJS.hd(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.w0.m(null,$CLJS.SG,function(a,b,c){a=$CLJS.O(c);a=$CLJS.J.g(a,$CLJS.OG);return $CLJS.oE.h(a.o?a.o():a.call(null))});$CLJS.D0.m(null,$CLJS.SG,function(a,b,c){var d=$CLJS.O(c);a=$CLJS.J.g(d,$CLJS.cG);b=$CLJS.J.g(d,$CLJS.OG);c=$CLJS.J.g(d,$CLJS.MG);d=$CLJS.J.g(d,$CLJS.IZ);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.u0,$CLJS.WA(a),$CLJS.H([$CLJS.s0,c]));return null!=d?$CLJS.R.j(a,$CLJS.e0,d):a});
$CLJS.jqa=function(){function a(d,e){var f=function(){var m=$CLJS.eA.h($CLJS.q1($CLJS.Q_(d)));return $CLJS.n(m)?m:$CLJS.bh}(),k=$CLJS.DW(d,e),l=$CLJS.H0.j(d,e,k);return $CLJS.Fe($CLJS.eg.j($CLJS.Cf,$CLJS.Rk.j($CLJS.kf(function(m){m=$CLJS.QG.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Zl(function(m){m=$CLJS.O(m);var t=$CLJS.J.g(m,$CLJS.MG),u=$CLJS.J.g(m,$CLJS.KG);if($CLJS.Va(t))return m;if($CLJS.E.g(u,$CLJS.li))return $CLJS.R.j(m,$CLJS.AW,l);t=$CLJS.Fe($CLJS.V0(function(v){return $CLJS.s_(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.AW,t):null}),$CLJS.hf.h(function(m){return $CLJS.R.j(m,$CLJS.Xt,$CLJS.SG)})),$CLJS.TG))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.kqa=function(){function a(d,e){return $CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cG.h(d),$CLJS.N,$CLJS.pW.h(e)],null))}function b(d){if($CLJS.Va($CLJS.MG.h(d)))return $CLJS.nW(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cG.h(d),$CLJS.N],null));var e=$CLJS.cG.h(d);e=$CLJS.fa.g?$CLJS.fa.g("aggregation operator %s requires an argument",e):$CLJS.fa.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[Tpa,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();