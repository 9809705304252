var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var r1;$CLJS.n1=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Qe(function(c){return $CLJS.Id(b,c)},a)};$CLJS.o1=function(a,b){return $CLJS.FA($CLJS.Te(a),b)};$CLJS.p1=function(a){var b;a:for(b=a;;)if($CLJS.xd(b))b=$CLJS.$i.h(b);else{b=$CLJS.Fd(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.jE("Not something with an ID: {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a]))])));};
$CLJS.q1=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=$CLJS.q1[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=$CLJS.q1._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.bb("MetadataProvider.database",a);}return a};r1=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=r1[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=r1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.bb("MetadataProvider.field",a);}return a};
$CLJS.s1=function(a,b){return r1($CLJS.Q_(a),b)};$CLJS.m1.m(null,$CLJS.fW,function(a){return $CLJS.l1(a,new $CLJS.h(null,2,[$CLJS.nj,$CLJS.zh,$CLJS.pL,$CLJS.Ve($CLJS.Tk,$CLJS.m1)],null))});$CLJS.C0.m(null,$CLJS.fW,function(){throw $CLJS.hi("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.E0.m(null,$CLJS.fW,function(a,b,c,d){return $CLJS.F0.v(a,b,$CLJS.DW(c,b),d)});$CLJS.w0.m(null,$CLJS.fW,function(a,b,c,d){return $CLJS.X_.v(a,b,$CLJS.DW(c,b),d)});
$CLJS.t1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.Xt.h($CLJS.DW(f,0))},e,a,b,c,d)}();$CLJS.t1.m(null,$CLJS.ci,function(){return!0});
$CLJS.u1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.Xt,$CLJS.fW,$CLJS.kE,$CLJS.Q_(e),$CLJS.TP,d,$CLJS.pL,f],null)}function b(d,e){var f=$CLJS.$i.h($CLJS.q1($CLJS.Q_(d)));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.v1=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.eC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.cC(k)},e,a,b,c,d)}();$CLJS.v1.m(null,$CLJS.lB,function(a,b){b=$CLJS.WY.h(b);return $CLJS.u1.g(a,$CLJS.pL.h(b))});
$CLJS.v1.m(null,$CLJS.fW,function(a,b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.SY);var d=$CLJS.Q_(a),e=$CLJS.Jk.g($CLJS.R.j(c,$CLJS.kE,d),$CLJS.SY);a=$CLJS.pL.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.pL,$CLJS.eg.j($CLJS.Cf,$CLJS.hf.h(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.zd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.he(v,$CLJS.jG))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.Te($CLJS.yA($CLJS.pB,$CLJS.Fi))(x)))try{var z=$CLJS.F(t,2);if($CLJS.n($CLJS.hE($CLJS.Dl,
$CLJS.Wl)(z))){var C=$CLJS.F(t,2),G=$CLJS.F(t,1),K=$CLJS.gm($CLJS.s1(d,C),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.Fi],null));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,$CLJS.il.l($CLJS.H([K,G])),C],null)}throw $CLJS.Y;}catch(ha){if(ha instanceof Error){var S=ha;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Y)throw $CLJS.Y;throw S;}throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(S=ha,S===$CLJS.Y)try{if(v=
$CLJS.F(t,0),$CLJS.he(v,$CLJS.mB))try{x=$CLJS.F(t,1);if($CLJS.n($CLJS.Te($CLJS.yA($CLJS.pB,$CLJS.Fi))(x))){G=$CLJS.F(t,1);var V=$CLJS.F(t,2);K=function(){try{return $CLJS.o1(function(ra){return $CLJS.E.g($CLJS.Bj,ra)},$CLJS.gm($CLJS.hd($CLJS.Yna.j(e,k,V)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.Fi],null)))}catch(ra){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mB,$CLJS.il.l($CLJS.H([K,G])),V],null)}throw $CLJS.Y;}catch(ra){if(ra instanceof Error){var Z=ra;if(Z===$CLJS.Y)throw $CLJS.Y;
throw Z;}throw ra;}else throw $CLJS.Y;}catch(ra){if(ra instanceof Error){Z=ra;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw ra;}else throw S;else throw ha;}else throw $CLJS.Y;}catch(ha){if(ha instanceof Error){S=ha;if(S===$CLJS.Y)return $CLJS.WW(u,m,t);throw S;}throw ha;}}($CLJS.Cf,f)}),$CLJS.fm($CLJS.Js,a))):e});$CLJS.v1.m(null,$CLJS.TK,function(a,b){return $CLJS.u1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.TV,$CLJS.dP,$CLJS.p1(b)],null)],null))});
$CLJS.v1.m(null,$CLJS.hL,function(a,b){return $CLJS.u1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Xt,$CLJS.TV,$CLJS.MV,$CLJS.p1(b)],null)],null))});