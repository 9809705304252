var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var UW,VW,XW,YW,nla,$W,aX,TW,bX,ola,pla,qla,rla,fX,gX,iX,jX,kX,lX,mX,nX,tla,ula,pX,vla,qX,wla,sX,xla,yla,zla,Ala,Bla,QW;$CLJS.RW=function(a,b){if("string"===typeof b)return QW(a,b);throw new TypeError("re-seq must match against a string.");};$CLJS.SW=function(a,b,c){var d=$CLJS.hm(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Fb(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};
UW=function(a){var b=TW;return $CLJS.EA(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};VW=function(a){if($CLJS.md(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Hi,a],null));};
$CLJS.WW=function(a,b,c){return $CLJS.xd(c)?$CLJS.eg.g(c,function(){return function f(e){return new $CLJS.ne(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null),K=$CLJS.I(C,1,null);C=t;var S=$CLJS.Q,V=G;G=$CLJS.be.g(b,G);G=a.g?a.g(G,K):a.call(null,G,K);C.add(new $CLJS.P(null,2,5,S,[V,G],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var z=$CLJS.be.g(b,v),C=x;return a.g?a.g(z,C):a.call(null,z,C)}()],null),f($CLJS.Lc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Tk.g($CLJS.Ve(a,$CLJS.A(c)instanceof $CLJS.M?$CLJS.be.g(b,$CLJS.A(c)):b),c):c};XW=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ie(a):b;return $CLJS.n(b)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.gh(a)};
YW=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.zf(b)&&$CLJS.A(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.A(b),a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.A(b)):c};
nla=function(a,b){return $CLJS.jf(function(c){var d=$CLJS.lf;a:try{if($CLJS.zd(c)&&1<=$CLJS.D(c))try{var e=$CLJS.Yk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.D(e))try{var f=$CLJS.F(e,0);if($CLJS.n($CLJS.Ve($CLJS.E,a)(f))){var k=$CLJS.Yk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.A($CLJS.y(d($CLJS.Wa,l)))},$CLJS.H([b]))};
$W=function(a,b){for(;;)switch(b=$CLJS.kl.h($CLJS.lf($CLJS.Wa,b)),$CLJS.D(b)){case 0:return null;case 1:return a=$CLJS.A(b),ZW.h?ZW.h(a):ZW.call(null,a);default:if($CLJS.n($CLJS.Re($CLJS.Ve(YW,a),b))){var c=a;b=nla(a,b);a=c}else{c=$CLJS.hf.g(ZW,b);if($CLJS.E.g(c,b))return $CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),b);b=c}}};aX=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.zf(a))?$CLJS.yA($CLJS.ge,$CLJS.Ya)($CLJS.A(a)):b:b};
TW=function(a){return $CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))?$CLJS.zh.h($CLJS.CA(XW(a).toLowerCase(),/_/,"-")):a};bX=function(a,b){var c=aX(b);return $CLJS.n(c)?(b=TW($CLJS.A(b)),$CLJS.ud(a)?(a=$CLJS.fh(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,b)):c};ola=function(a){return $CLJS.J.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Td,$CLJS.nj,TW,$CLJS.PN,TW],null),a,function(b){var c=$CLJS.J,d=c.g;b=$CLJS.Ee([a,b]);b=cX.g?cX.g(b,dX):cX.call(null,b,dX);return d.call(c,b,a)})};
pla=function(a){a=$CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=TW(c);var d=ola(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.E.g($CLJS.nj.h(a),$CLJS.DF)&&$CLJS.Va($CLJS.PN.h(a))?$CLJS.R.j(a,$CLJS.PN,$CLJS.CG):a};qla=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=XW(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(pla(b),$CLJS.T,c)],null)}),a)};
rla=function(a){a=cX.h?cX.h(a):cX.call(null,a);return eX.h?eX.h(a):eX.call(null,a)};fX=function(a){return $CLJS.SW($CLJS.SW($CLJS.eb(function(b,c){return $CLJS.SW(b,c,$CLJS.zh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.dD,$CLJS.Lea,$CLJS.CO,sla,$CLJS.Zz,$CLJS.si],null)),$CLJS.dC,rla),$CLJS.RN,$CLJS.tV)};gX=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,a,null],null):a};iX=function(a){return hX.h(gX(a))};
jX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return ZW($CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.g(hX,b)))};kX=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,iX(b)],null),$CLJS.hf.g(hX,c))};lX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,iX(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};
mX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,iX(a)],null)};nX=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,hX.h(a)],null)};tla=function(a){return $CLJS.Df($CLJS.em(eX,oX(a)))};ula=function(a){for(;;)if($CLJS.n(aX(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Tk.g(gX,a))};
pX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.DF);return $CLJS.n(b)?$CLJS.Uk.j(a,$CLJS.DF,hX):a};
vla=function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,pX(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,
2,5,$CLJS.Q,[l,pX(f)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())};qX=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.FQ);return $CLJS.n(b)?$CLJS.Uk.j(a,$CLJS.FQ,vla):a};wla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.hQ);b=$CLJS.Va(a)?rX.h?rX.h(b):rX.call(null,b):b;return $CLJS.n(a)?qX(b):b};sX=function(a){return $CLJS.ud(a)?$CLJS.y(a):null!=a};
xla=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.GF);var c=$CLJS.J.g(b,$CLJS.tN),d=$CLJS.J.g(b,$CLJS.QO),e=$CLJS.J.g(b,$CLJS.hQ);try{if($CLJS.n(d)){var f=$CLJS.O(b),k=$CLJS.J.g(f,$CLJS.QO);var l=$CLJS.VL($CLJS.Jk.g(f,$CLJS.QO),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GF,$CLJS.QO],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Uk.j(l,$CLJS.GF,rX):l,t=$CLJS.n(c)?$CLJS.Uk.j(m,$CLJS.tN,$CLJS.Ve($CLJS.Tk,eX)):m,u=$CLJS.n(e)?$CLJS.Uk.j(t,$CLJS.hQ,qX):t,v=$CLJS.O(u),x=$CLJS.J.g(v,$CLJS.hQ);return $CLJS.n(x)?$CLJS.R.j(eX($CLJS.Jk.g(v,
$CLJS.hQ)),$CLJS.hQ,x):eX(v)}catch(z){if(z instanceof Error)throw m=z,$CLJS.gi($CLJS.jE("Error canonicalizing query: {0}",$CLJS.H([$CLJS.rV(m)])),new $CLJS.h(null,1,[$CLJS.GF,a],null),m);throw z;}};
yla=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.GF),c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.YF);c=$CLJS.J.g(c,$CLJS.TE);return $CLJS.y(b)&&$CLJS.y(c)?(b=$CLJS.eg.j($CLJS.bh,$CLJS.pC,$CLJS.y($CLJS.lf($CLJS.Wa,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.D(f))try{var l=$CLJS.F(f,0);if($CLJS.he(l,$CLJS.jG)){var m=$CLJS.F(f,1),t=$CLJS.F(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,m,$CLJS.Jk.g(t,$CLJS.pG)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.yV(k,e,f);throw l;}throw u;}}($CLJS.Cf,b)))),$CLJS.tX.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GF,$CLJS.TE],null),$CLJS.Rk.g($CLJS.Df,$CLJS.Ve($CLJS.Sk,b)))):a};
zla=function(a){try{return yla(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.LE("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.GF,a],null),b);}throw c;}};
Ala=function(a,b){var c=$CLJS.eg.g($CLJS.jd(a),function(){return function f(e){return new $CLJS.ne(null,function(){for(var k=e;;)if(k=$CLJS.y(k)){if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var z=0;;)if(z<m){var C=$CLJS.kd(l,z),G=$CLJS.I(C,0,null);C=$CLJS.I(C,1,null);var K=$CLJS.be.g(b,G);C=uX.g?uX.g(C,K):uX.call(null,C,K);null!=C&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[G,C],null));z+=1}else return!0}()?$CLJS.te($CLJS.ve(t),f($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k),v=$CLJS.I(u,0,null),x=$CLJS.I(u,1,null);u=function(){var z=x,C=$CLJS.be.g(b,v);return uX.g?uX.g(z,C):uX.call(null,z,C)}();if(null!=u)return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Lc(k)));k=$CLJS.Lc(k)}else return null},null,null)}(a)}());return $CLJS.y(c)?c:null};Bla=function(a,b){a=$CLJS.Tk.g(function(c){var d=$CLJS.be.g(b,vX);return uX.g?uX.g(c,d):uX.call(null,c,d)},a);return $CLJS.n($CLJS.Re($CLJS.Wa,a))?a:null};
QW=function QW(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Df(d);return $CLJS.ee(f,new $CLJS.ne(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),QW.g?QW.g(a,k):QW.call(null,a,k)):null},null,null))};
$CLJS.tX=function tX(a){switch(arguments.length){case 3:return tX.j(arguments[0],arguments[1],arguments[2]);case 4:return tX.v(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return tX.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return tX.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return tX.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.w(c.slice(6),0,null))}};$CLJS.tX.j=function(a,b,c){var d=$CLJS.y(b);b=$CLJS.A(d);if(d=$CLJS.B(d))a=$CLJS.R.j(a,b,$CLJS.tX.j($CLJS.J.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.J.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.tX.v=function(a,b,c,d){var e=$CLJS.y(b);b=$CLJS.A(e);if(e=$CLJS.B(e))a=$CLJS.R.j(a,b,$CLJS.tX.v($CLJS.J.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.J.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.tX.N=function(a,b,c,d,e){var f=$CLJS.y(b);b=$CLJS.A(f);if(f=$CLJS.B(f))a=$CLJS.R.j(a,b,$CLJS.tX.N($CLJS.J.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.tX.W=function(a,b,c,d,e,f){var k=$CLJS.y(b);b=$CLJS.A(k);if(k=$CLJS.B(k))a=$CLJS.R.j(a,b,$CLJS.tX.W($CLJS.J.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.J.g(a,b);c=c.v?c.v(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.tX.l=function(a,b,c,d,e,f,k){var l=$CLJS.y(b);b=$CLJS.A(l);return(l=$CLJS.B(l))?$CLJS.R.j(a,b,$CLJS.Se.l($CLJS.tX,$CLJS.J.g(a,b),l,c,d,$CLJS.H([e,f,k]))):$CLJS.R.j(a,b,$CLJS.Se.l(c,$CLJS.J.g(a,b),d,e,f,$CLJS.H([k])))};
$CLJS.tX.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);var e=$CLJS.B(d);d=$CLJS.A(e);var f=$CLJS.B(e);e=$CLJS.A(f);var k=$CLJS.B(f);f=$CLJS.A(k);k=$CLJS.B(k);return this.l(b,a,c,d,e,f,k)};$CLJS.tX.A=6;
var ZW=function ZW(a){for(;;){if($CLJS.xd(a))return $CLJS.zt(a,ZW);if($CLJS.Dd(a))a=$CLJS.Df(a);else if($CLJS.zd(a)){if($CLJS.Va($CLJS.Re($CLJS.Wa,a)))return null;var c=a,d=$CLJS.y(c),e=$CLJS.A(d),f=$CLJS.B(d),k=e,l=f,m=$CLJS.I(l,0,null),t=l,u=k,v=u instanceof $CLJS.M?u.T:null;switch(v){case "not":if($CLJS.Ol(m)){var x=$CLJS.A(m),z=x instanceof $CLJS.M?x.T:null;switch(z){case "not":a=$CLJS.hd(m);continue;case "and":return $W($CLJS.qs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.ss,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));case "or":return $W($CLJS.xs,$CLJS.hf.g(function(){return function(C){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ss,C],null)}}(a,x,z,u,v,c,d,e,f,k,l,m,t),$CLJS.Lc(m)));default:return a}}else return a;case "and":return $W($CLJS.xs,t);case "or":return $W($CLJS.qs,t);default:return $CLJS.Tk.g(ZW,a)}}else return a}},Cla=new $CLJS.M(null,"value_field","value_field",-980977878),wX=new $CLJS.M(null,"ascending","ascending",-988350486),
xX=new $CLJS.M(null,"named","named",-422393479),yX=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),zX=new $CLJS.M(null,"descending","descending",-24766135),AX=new $CLJS.M(null,"datetime-field","datetime-field",21731696),Dla=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",686752941),BX=new $CLJS.M(null,"field-literal","field-literal",-1295883554),CX=new $CLJS.M(null,"viz-settings","viz-settings",256055379),Ela=new $CLJS.M(null,"rows","rows",850049680),Fla=
new $CLJS.M(null,"special-fn","special-fn",1290649344),vX=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),dX=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Gla=new $CLJS.M(null,"label_field","label_field",-1573182765),Hla=new $CLJS.M(null,"fk-\x3e","fk-\x3e",-499026738),Ila=new $CLJS.M(null,"joined-field","joined-field",-2048778268),sla=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var DX,Jla=$CLJS.Xe($CLJS.N),Kla=$CLJS.Xe($CLJS.N),Lla=$CLJS.Xe($CLJS.N),Mla=$CLJS.Xe($CLJS.N),Nla=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));DX=new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Rk.g(TW,$CLJS.A),Nla,Jla,Kla,Lla,Mla);DX.m(null,$CLJS.ZF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,b],null);return null!=a?$CLJS.be.g(b,a):b});
DX.m(null,$CLJS.mB,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mB,a instanceof $CLJS.M?XW(a):a],null)});DX.m(null,$CLJS.AV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?$CLJS.be.g(DX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AV,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AV,cX.g?cX.g(b,dX):cX.call(null,b,dX),TW(c)],null)});
DX.m(null,$CLJS.jG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=cX.g?cX.g(a,dX):cX.call(null,a,dX);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,b,function(){var d=$CLJS.n($CLJS.pB.h(c))?$CLJS.Uk.j(c,$CLJS.pB,$CLJS.zh):c;d=$CLJS.n($CLJS.pG.h(c))?$CLJS.Uk.j(d,$CLJS.pG,$CLJS.zh):d;return $CLJS.n($CLJS.tR.h(c))?$CLJS.Uk.j(d,$CLJS.tR,function(e){return $CLJS.n($CLJS.EE.h(e))?$CLJS.Uk.j(e,$CLJS.EE,$CLJS.zh):e}):d}()],null)});
DX.m(null,BX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[BX,b instanceof $CLJS.M?XW(b):b,$CLJS.zh.h(a)],null)});DX.m(null,AX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[AX,cX.g?cX.g(b,dX):cX.call(null,b,dX),$CLJS.gt,TW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[AX,cX.g?cX.g(b,dX):cX.call(null,b,dX),TW(c)],null)});
DX.m(null,$CLJS.vG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);return $CLJS.n(a)?$CLJS.be.g(DX.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vG,b,c,d],null)),cX.g?cX.g(a,dX):cX.call(null,a,dX)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vG,cX.g?cX.g(b,dX):cX.call(null,b,dX),$CLJS.Fd(c)?c:TW(c),TW(d)],null)});
DX.m(null,$CLJS.$I,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$I,b,TW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$I,$CLJS.Sv],null)});DX.m(null,$CLJS.YI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YI,b,TW(a)],null)});
DX.m(null,$CLJS.hJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hJ,cX.g?cX.g(b,dX):cX.call(null,b,dX),c,TW(a)],null)});DX.m(null,$CLJS.nJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.nJ,cX.g?cX.g(b,dX):cX.call(null,b,dX),c,TW(a)],null)});
DX.m(null,$CLJS.fJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fJ,cX.g?cX.g(b,dX):cX.call(null,b,dX),TW(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fJ,cX.g?cX.g(b,dX):cX.call(null,b,dX)],null)});
DX.m(null,$CLJS.lJ,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lJ,cX.g?cX.g(b,dX):cX.call(null,b,dX),TW(c),TW(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lJ,cX.g?cX.g(b,dX):cX.call(null,b,dX),TW(c)],null)});
DX.m(null,$CLJS.XI,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.XI,cX.g?cX.g(b,dX):cX.call(null,b,dX),cX.g?cX.g(c,dX):cX.call(null,c,dX),TW(a)],null)});DX.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,b,a],null)});
DX.m(null,$CLJS.ci,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[TW(a)],null),$CLJS.hf.h(function(c){return cX.g?cX.g(c,dX):cX.call(null,c,dX)}),b)});
var EX=function EX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a))){var c=TW(a);var d=new $CLJS.ah(null,new $CLJS.h(null,18,[$CLJS.ao,null,$CLJS.Yr,null,$CLJS.yG,null,$CLJS.sG,null,$CLJS.it,null,$CLJS.kG,null,$CLJS.DG,null,$CLJS.EG,null,$CLJS.PF,null,$CLJS.VF,null,$CLJS.Xr,null,$CLJS.QF,null,$CLJS.xG,null,$CLJS.qG,null,$CLJS.kk,null,$CLJS.Qx,null,$CLJS.dG,null,$CLJS.wG,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(aX(a))?(a=$CLJS.A(a),EX.h?EX.h(a):EX.call(null,
a)):null},Ola=new $CLJS.h(null,8,[$CLJS.nj,TW,$CLJS.hQ,function(a){a=UW(a);return $CLJS.y($CLJS.FQ.h(a))?$CLJS.Uk.j(a,$CLJS.FQ,qla):a},$CLJS.GF,new $CLJS.h(null,6,[$CLJS.ZF,function FX(a){if($CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(a)))return TW(a);if($CLJS.n(bX(xX,a))){a=$CLJS.y(a);$CLJS.A(a);var c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[xX,FX.h?FX.h(a):FX.call(null,a)],null),c)}return $CLJS.n($CLJS.n(aX(a))?EX($CLJS.hd(a)):null)?$CLJS.Tk.g(FX,a):cX.g?cX.g(a,
dX):cX.call(null,a,dX)},$CLJS.YP,function(a){return $CLJS.eg.g($CLJS.N,function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[XW(u),cX.g?cX.g(t,dX):cX.call(null,t,dX)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}f=
$CLJS.A(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[XW(l),cX.g?cX.g(f,dX):cX.call(null,f,dX)],null),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.NR,function(a){return $CLJS.Df(function(){return function d(c){return new $CLJS.ne(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.lc(e),k=$CLJS.D(f),l=$CLJS.qe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.kd(f,m);t=$CLJS.n(aX(t))?DX.h(t):$CLJS.ce(DX.h($CLJS.ce(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.te($CLJS.ve(l),d($CLJS.mc(e))):$CLJS.te($CLJS.ve(l),null)}l=$CLJS.A(e);return $CLJS.ee($CLJS.n(aX(l))?DX.h(l):$CLJS.ce(DX.h($CLJS.ce(l))),d($CLJS.Lc(e)))}return null}},null,null)}(a)}())},$CLJS.HQ,function(a){a=UW(a);a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.hQ);if($CLJS.n(b))return a=$CLJS.xV(a,new $CLJS.h(null,1,[$CLJS.hQ,$CLJS.GF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hQ],null),a=cX.g?cX.g(a,b):cX.call(null,a,b),$CLJS.xV(a,new $CLJS.h(null,1,[$CLJS.GF,$CLJS.hQ],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.GF],null);return cX.g?cX.g(a,b):cX.call(null,a,b)},$CLJS.QO,new $CLJS.h(null,1,[vX,fX],null),$CLJS.CN,new $CLJS.h(null,1,[vX,function(a){a=cX.g?cX.g(a,$CLJS.GF):cX.call(null,a,$CLJS.GF);var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.EE),d=$CLJS.J.g(b,$CLJS.TE);a=$CLJS.J.g(b,$CLJS.XE);b=$CLJS.n(c)?$CLJS.Uk.j(b,$CLJS.EE,TW):b;d=$CLJS.n($CLJS.yA($CLJS.ge,$CLJS.Ya)(d))?$CLJS.Uk.j(b,$CLJS.TE,TW):b;return $CLJS.n(a)?$CLJS.Uk.j(d,$CLJS.XE,XW):d}],null)],null),$CLJS.Fy,
new $CLJS.h(null,1,[$CLJS.nO,$CLJS.Td],null),$CLJS.tN,new $CLJS.h(null,1,[vX,function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.nj),d=$CLJS.J.g(b,$CLJS.sJ),e=$CLJS.J.g(b,$CLJS.$i);a=$CLJS.J.g(b,yX);b=$CLJS.n(e)?$CLJS.Uk.j(b,$CLJS.$i,XW):b;c=$CLJS.n(c)?$CLJS.Uk.j(b,$CLJS.nj,TW):b;d=$CLJS.n(d)?$CLJS.Uk.j(c,$CLJS.sJ,function(f){return cX.g?cX.g(f,dX):cX.call(null,f,dX)}):c;d=$CLJS.n(a)?$CLJS.tX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[yX,Gla],null),function(f){return cX.g?cX.g(f,dX):cX.call(null,f,dX)}):d;return $CLJS.n(a)?
$CLJS.tX.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[yX,Cla],null),function(f){return cX.g?cX.g(f,dX):cX.call(null,f,dX)}):d}],null),$CLJS.oN,function(a){return null==a?null:TW(a)},$CLJS.QO,new $CLJS.h(null,1,[vX,fX],null),CX,TW],null),cX=function cX(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return cX.l(arguments[0],1<c.length?new $CLJS.w(c.slice(1),0,null):null)};
cX.l=function(a,b){b=$CLJS.I(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Df(b);b=$CLJS.y(c)?$CLJS.zA(Ola,c):null;try{return $CLJS.md(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.eg.g($CLJS.N,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v),z=$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);z=TW(z);z=new $CLJS.P(null,
2,5,$CLJS.Q,[z,cX.l(x,$CLJS.H([$CLJS.be.g($CLJS.Df(c),z)]))],null);u.add(z);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}m=$CLJS.A(l);u=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);u=TW(u);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[u,cX.l(m,$CLJS.H([$CLJS.be.g($CLJS.Df(c),u)]))],null),k($CLJS.Lc(l)))}return null}},null,null)}(a)}()):$CLJS.n(aX(a))?DX.h(a):$CLJS.wd(a)?$CLJS.Tk.g(function(e){return cX.l(e,$CLJS.H([$CLJS.be.g($CLJS.Df(c),vX)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.jE("Error normalizing form: {0}",$CLJS.H([$CLJS.rV(d)])),new $CLJS.h(null,3,[$CLJS.Xz,a,$CLJS.rl,c,Fla,b],null),d);}throw e;}};cX.A=1;cX.B=function(a){var b=$CLJS.A(a);a=$CLJS.B(a);return this.l(b,a)};
var hX=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j($CLJS.N,$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(aX(f))?$CLJS.A(f):null},e,a,b,c,d)}();hX.m(null,$CLJS.ci,function(a){return a});
hX.m(null,$CLJS.jG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(bX($CLJS.jG,b))){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);return hX.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,c,$CLJS.Fe($CLJS.il.l($CLJS.H([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,b,$CLJS.Fe(a)],null)});
hX.m(null,$CLJS.ZF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZF,b,a],null)});hX.m(null,$CLJS.WK,function(a){$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.n(aX(a))?hX.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,a,null],null)});
hX.m(null,BX,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,b,new $CLJS.h(null,1,[$CLJS.pB,a],null)],null)});hX.m(null,Hla,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);var c=iX(b);$CLJS.I(c,0,null);b=$CLJS.I(c,1,null);$CLJS.I(c,2,null);c=iX(a);$CLJS.I(c,0,null);a=$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,a,$CLJS.R.j(c,$CLJS.DN,b)],null)});
hX.m(null,Ila,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=iX(a);return $CLJS.Se.v($CLJS.FV,a,$CLJS.R,$CLJS.H([$CLJS.sP,b]))});
hX.m(null,AX,function(a){var b=$CLJS.D(a);switch(b){case 3:$CLJS.I(a,0,null);b=$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null);a=iX(b);b=c;$CLJS.I(a,0,null);$CLJS.I(a,1,null);c=$CLJS.I(a,2,null);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.pB);$CLJS.Va(d)||$CLJS.KS.g(d,b)?a=$CLJS.Se.v($CLJS.FV,a,$CLJS.R,$CLJS.H([$CLJS.pG,b])):(c=$CLJS.IA($CLJS.Dy),$CLJS.n($CLJS.HA("metabase.mbql.util",c))&&(b=$CLJS.jE("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.H([b,d,$CLJS.Mh.l($CLJS.H([a]))])),
b instanceof Error?$CLJS.GA("metabase.mbql.util",c,$CLJS.zw(),b):$CLJS.GA("metabase.mbql.util",c,$CLJS.zw.l($CLJS.H([b])),null)));return a;case 4:return $CLJS.I(a,0,null),b=$CLJS.I(a,1,null),$CLJS.I(a,2,null),c=$CLJS.I(a,3,null),hX.h(new $CLJS.P(null,3,5,$CLJS.Q,[AX,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
hX.m(null,$CLJS.AV,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null),d=$CLJS.I(a,3,null);a=$CLJS.I(a,4,null);var e=iX(b);$CLJS.I(e,0,null);b=$CLJS.I(e,1,null);e=$CLJS.I(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,b,$CLJS.R.j(e,$CLJS.tR,$CLJS.il.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.EE,c],null),$CLJS.n(d)?$CLJS.Ee([c,d]):null,a])))],null)});
for(var GX=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.qs,$CLJS.ss],null)),HX=null,IX=0,JX=0;;)if(JX<IX){var KX=HX.X(null,JX);hX.m(null,KX,function(){return function(a){return jX(a)}}(GX,HX,IX,JX,KX));JX+=1}else{var LX=$CLJS.y(GX);if(LX){var MX=LX;if($CLJS.Ad(MX)){var NX=$CLJS.lc(MX),Pla=$CLJS.mc(MX),Qla=NX,Rla=$CLJS.D(NX);GX=Pla;HX=Qla;IX=Rla}else{var OX=$CLJS.A(MX);hX.m(null,OX,function(){return function(a){return jX(a)}}(GX,HX,IX,JX,OX,MX,LX));GX=$CLJS.B(MX);HX=null;IX=0}JX=0}else break}
hX.m(null,$CLJS.SF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,iX(a),iX(b)],null),c)});hX.m(null,$CLJS.vG,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=iX(a);a=$CLJS.n(YW($CLJS.jG,a))?$CLJS.FV.l(c,$CLJS.Jk,$CLJS.H([$CLJS.pG])):c;return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vG,a],null),b)});
for(var PX=$CLJS.y(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.aG,$CLJS.uG,$CLJS.iG,$CLJS.bG,$CLJS.uk,$CLJS.AG,$CLJS.Tr,$CLJS.Vr,$CLJS.Pr,$CLJS.Rr,$CLJS.NF,$CLJS.UF,$CLJS.LF,$CLJS.WF,$CLJS.MF],null)),QX=null,RX=0,SX=0;;)if(SX<RX){var TX=QX.X(null,SX);hX.m(null,TX,function(){return function(a){return kX(a)}}(PX,QX,RX,SX,TX));SX+=1}else{var UX=$CLJS.y(PX);if(UX){var VX=UX;if($CLJS.Ad(VX)){var WX=$CLJS.lc(VX),Sla=$CLJS.mc(VX),Tla=WX,Ula=$CLJS.D(WX);PX=Sla;QX=Tla;RX=Ula}else{var XX=$CLJS.A(VX);hX.m(null,XX,
function(){return function(a){return kX(a)}}(PX,QX,RX,SX,XX,VX,UX));PX=$CLJS.B(VX);QX=null;RX=0}SX=0}else break}hX.m(null,Ela,function(){return null});hX.m(null,$CLJS.tM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tM,hX.h(b),a],null)});
hX.m(null,xX,function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=hX.h;var e=$CLJS.Q;b=hX.h(b);d=$CLJS.I(d,0,null);d=$CLJS.O(d);a=!1===$CLJS.J.g(d,Dla)?new $CLJS.h(null,1,[$CLJS.T,a],null):new $CLJS.h(null,1,[$CLJS.oE,a],null);return c.call(hX,new $CLJS.P(null,3,5,e,[$CLJS.tM,b,a],null))});
for(var YX=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qx,$CLJS.kG],null)),ZX=null,$X=0,aY=0;;)if(aY<$X){var bY=ZX.X(null,aY);hX.m(null,bY,function(){return function(a){return lX(a)}}(YX,ZX,$X,aY,bY));aY+=1}else{var cY=$CLJS.y(YX);if(cY){var dY=cY;if($CLJS.Ad(dY)){var eY=$CLJS.lc(dY),Vla=$CLJS.mc(dY),Wla=eY,Xla=$CLJS.D(eY);YX=Vla;ZX=Wla;$X=Xla}else{var fY=$CLJS.A(dY);hX.m(null,fY,function(){return function(a){return lX(a)}}(YX,ZX,$X,aY,fY,dY,cY));YX=$CLJS.B(dY);ZX=null;$X=0}aY=0}else break}
for(var gY=$CLJS.y(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dG,$CLJS.FG,$CLJS.PF,$CLJS.yG,$CLJS.wG,$CLJS.ao,$CLJS.kk,$CLJS.xG,$CLJS.QF],null)),hY=null,iY=0,jY=0;;)if(jY<iY){var kY=hY.X(null,jY);hX.m(null,kY,function(){return function(a){return mX(a)}}(gY,hY,iY,jY,kY));jY+=1}else{var lY=$CLJS.y(gY);if(lY){var mY=lY;if($CLJS.Ad(mY)){var nY=$CLJS.lc(mY),Yla=$CLJS.mc(mY),Zla=nY,$la=$CLJS.D(nY);gY=Yla;hY=Zla;iY=$la}else{var oY=$CLJS.A(mY);hX.m(null,oY,function(){return function(a){return mX(a)}}(gY,hY,iY,jY,
oY,mY,lY));gY=$CLJS.B(mY);hY=null;iY=0}jY=0}else break}hX.m(null,$CLJS.VF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VF,iX(b),a],null)});
for(var pY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,$CLJS.sG],null)),qY=null,rY=0,sY=0;;)if(sY<rY){var tY=qY.X(null,sY);hX.m(null,tY,function(){return function(a){return nX(a)}}(pY,qY,rY,sY,tY));sY+=1}else{var uY=$CLJS.y(pY);if(uY){var vY=uY;if($CLJS.Ad(vY)){var wY=$CLJS.lc(vY),ama=$CLJS.mc(vY),bma=wY,cma=$CLJS.D(wY);pY=ama;qY=bma;rY=cma}else{var xY=$CLJS.A(vY);hX.m(null,xY,function(){return function(a){return nX(a)}}(pY,qY,rY,sY,xY,vY,uY));pY=$CLJS.B(vY);qY=null;rY=0}sY=0}else break}
hX.m(null,$CLJS.DG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DG,hX.h(b),hX.h(a)],null)});
hX.m(null,$CLJS.TF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.n(a)?$CLJS.be.g(hX.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,b],null)),cX.l(a,$CLJS.H([dX]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,$CLJS.Df(function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.kd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[hX.h(v),hX.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}k=$CLJS.A(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[hX.h(m),hX.h(k)],null),e($CLJS.Lc(f)))}return null}},null,null)}(b)}())],null)});
hX.m(null,$CLJS.CF,function(a){a=$CLJS.y(a);$CLJS.A(a);var b=$CLJS.B(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);return $CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CF,hX.h(a),$CLJS.E.g(0,b)?1:hX.h(b)],null),$CLJS.hf.g(hX,c))});
var eX=function eX(a){if($CLJS.yd(a))return $CLJS.Sd(function(d,e,f){return $CLJS.R.j(d,e,eX.h?eX.h(f):eX.call(null,f))},a,a);if($CLJS.xd(a))return $CLJS.zt(a,eX);if($CLJS.n(aX(a))){var c=function(){try{return hX.h(a)}catch(k){if(k instanceof Error){var d=k,e=$CLJS.IA($CLJS.By);if($CLJS.n($CLJS.HA("metabase.mbql.normalize",e))){var f=$CLJS.LE("Invalid clause:");f instanceof Error?$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([a])),f):$CLJS.GA("metabase.mbql.normalize",e,$CLJS.zw.l($CLJS.H([f,
a])),null)}throw $CLJS.gi($CLJS.jE("Invalid MBQL clause: {0}",$CLJS.H([$CLJS.rV(d)])),new $CLJS.h(null,1,[$CLJS.ZR,a],null),d);}throw k;}}();return $CLJS.y(c)?$CLJS.eg.j($CLJS.be.g($CLJS.jd(c),$CLJS.A(c)),$CLJS.hf.h(eX),$CLJS.Lc(c)):c}return $CLJS.Dd(a)?$CLJS.Tk.g(eX,a):$CLJS.ud(a)?$CLJS.eg.j($CLJS.jd(a),$CLJS.hf.h(eX),a):a},oX=function oX(a){return function f(d,e){try{if($CLJS.n(function(){var x=VW($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(x){if(x instanceof
Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var z=VW($CLJS.ge);return z.h?z.h(e):z.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(z){if(z instanceof Error)if(d=z,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.D(e))try{var k=$CLJS.Yk.j(e,0,2);if($CLJS.zd(k)&&2===$CLJS.D(k))try{var l=$CLJS.F(k,0);if($CLJS.n($CLJS.hE($CLJS.ge,$CLJS.Te(new $CLJS.ah(null,new $CLJS.h(null,5,[$CLJS.Yr,null,$CLJS.it,null,$CLJS.EG,null,$CLJS.Xr,null,
xX,null],null),null)))(l)))try{var m=$CLJS.F(k,1);if($CLJS.n(EX(m)))return $CLJS.eg.j($CLJS.Cf,$CLJS.iE(oX),a);throw $CLJS.Y;}catch(C){if(C instanceof Error){var t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error){t=C;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw C;}else throw $CLJS.Y;}catch(C){if(C instanceof Error)if(t=C,t===$CLJS.Y)try{if($CLJS.zd(e)&&
1<=$CLJS.D(e))try{var u=$CLJS.Yk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.D(u))try{if($CLJS.F(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(G){if(G instanceof Error){var v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error){v=G;if(v===$CLJS.Y)return e;throw v;}throw G;}else throw t;else throw C;}else throw d;else throw z;
}else throw d;else throw x;}}($CLJS.Cf,a)},yY=function yY(a){return function f(d,e){try{var k=VW($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Df(e));throw $CLJS.Y;}catch(C){if(C instanceof Error)if(l=C,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.D(e))try{var m=$CLJS.F(e,1);if($CLJS.he(m,$CLJS.gG)){var t=$CLJS.F(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,t],null))}throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,
$CLJS.$F))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,wX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,t],null));throw $CLJS.Y;}catch(S){if(S instanceof Error)if(l=S,l===$CLJS.Y)try{m=$CLJS.F(e,1);if($CLJS.he(m,zX))return t=$CLJS.F(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,t],null));throw $CLJS.Y;}catch(V){if(V instanceof Error)if(m=V,m===
$CLJS.Y)try{var v=$CLJS.F(e,0);if($CLJS.he(v,wX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,zX))return t=$CLJS.F(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,t],null));throw $CLJS.Y;}catch(ha){if(ha instanceof Error)if(m=ha,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.gG))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gG,iX(t)],null);throw $CLJS.Y;}catch(ra){if(ra instanceof
Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.F(e,0);if($CLJS.he(v,$CLJS.$F))return t=$CLJS.F(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$F,iX(t)],null);throw $CLJS.Y;}catch(Na){if(Na instanceof Error){t=Na;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw Na;}else throw m;else throw ra;}else throw m;else throw ha;}else throw m;else throw Z;}else throw m;else throw V;}else throw l;else throw S;}else throw u;}else throw K;}else throw l;else throw G;}else throw $CLJS.Y;}catch(G){if(G instanceof Error)if(l=G,l===$CLJS.Y)try{if($CLJS.zd(e)&&
0<=$CLJS.D(e))try{var x=$CLJS.Yk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.D(x))try{var z=$CLJS.Yk.g(e,0);if($CLJS.E.g(z,a))return $CLJS.Df($CLJS.kl.h($CLJS.hf.g(yY,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.WW(f,d,e);throw u;}throw K;}else throw l;else throw G;
}else throw l;else throw C;}}($CLJS.Cf,a)},rX=$CLJS.Rk.g(eX,function(a){var b=sX($CLJS.ZF.h(a))?$CLJS.Uk.j(a,$CLJS.ZF,tla):a;b=sX($CLJS.YF.h(a))?$CLJS.Uk.j(b,$CLJS.YF,ula):b;b=sX($CLJS.TE.h(a))?$CLJS.Uk.j(b,$CLJS.TE,$CLJS.Ve($CLJS.Tk,gX)):b;b=sX($CLJS.NR.h(a))?$CLJS.Uk.j(b,$CLJS.NR,yY):b;return sX($CLJS.HQ.h(a))?$CLJS.Uk.j(b,$CLJS.HQ,wla):b}),dma=new $CLJS.h(null,3,[$CLJS.hQ,$CLJS.Td,$CLJS.GF,new $CLJS.h(null,2,[$CLJS.HQ,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.hQ);if($CLJS.n(b))return a=
$CLJS.xV(a,new $CLJS.h(null,1,[$CLJS.hQ,$CLJS.GF],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hQ],null),a=uX.g?uX.g(a,b):uX.call(null,a,b),$CLJS.xV(a,new $CLJS.h(null,1,[$CLJS.GF,$CLJS.hQ],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.GF],null);return uX.g?uX.g(a,b):uX.call(null,a,b)},$CLJS.CN,new $CLJS.h(null,1,[vX,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.GF],null);return uX.g?uX.g(a,b):uX.call(null,a,b)}],null)],null),CX,$CLJS.Td],null),uX=function uX(a){switch(arguments.length){case 1:return uX.h(arguments[0]);
case 2:return uX.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};uX.h=function(a){return uX.g(a,$CLJS.Cf)};uX.g=function(a,b){try{var c=$CLJS.y(b)?$CLJS.zA(dma,b):null;return $CLJS.md(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?Ala(a,b):$CLJS.wd(a)?Bla(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Xz,a,$CLJS.rl,b],null),d);throw d;}};uX.A=2;
$CLJS.zY=function(){var a=$CLJS.Rk.l(uX,zla,xla,$CLJS.H([cX]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.jE("Error normalizing query: {0}",$CLJS.H([$CLJS.rV(c)])),new $CLJS.h(null,1,[$CLJS.GF,b],null),c);}throw d;}}}();$CLJS.AY=function AY(a,b){if($CLJS.Va($CLJS.y(a)))a=(0,$CLJS.zY)(b);else{var d=$CLJS.J,e=d.g;var f=$CLJS.xt(a);b=$CLJS.Ee([$CLJS.id(a),b]);f=AY.g?AY.g(f,b):AY.call(null,f,b);a=e.call(d,f,$CLJS.id(a))}return a};