var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");
'use strict';var X0,Ona,Y0,Z0;$CLJS.V0=function(a,b){return $CLJS.gc($CLJS.eb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.fc(c,d):c},$CLJS.ec($CLJS.Cf),b))};X0=function(a){return $CLJS.n(W0)?["(",$CLJS.p.h(a),")"].join(""):a};Ona=function(a,b){var c=$CLJS.Uk.v(a,$CLJS.YP,$CLJS.SD($CLJS.be,$CLJS.Cf),b);return $CLJS.zd($CLJS.TE.h(a))?$CLJS.Uk.v(c,$CLJS.TE,$CLJS.be,$CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mB,$CLJS.N,$CLJS.wW(b)],null))):c};
Y0=function(a,b,c){var d=$CLJS.wW(c);return $CLJS.R.l($CLJS.W_.j(a,b,c),$CLJS.OK,$CLJS.BZ,$CLJS.H([$CLJS.T,d,$CLJS.oE,d]))};Z0=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);$CLJS.$0=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var a1=function(){function a(d,e,f){var k=$CLJS.DW(d,e);k=$CLJS.tH($CLJS.Rk.g($CLJS.ch([f]),$CLJS.wW),$CLJS.YP.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.jE("No expression named {0}",$CLJS.H([$CLJS.Mh.l($CLJS.H([f]))])),new $CLJS.h(null,3,[$CLJS.$R,f,$CLJS.GF,d,$CLJS.DL,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.A0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=$CLJS.I(c,2,null);c=a1.j(a,b,c);return $CLJS.B0.j(a,b,c)});$CLJS.C0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.I(c,2,null);return new $CLJS.h(null,7,[$CLJS.Xt,$CLJS.MK,$CLJS.$0,$CLJS.AE.h(d),$CLJS.T,e,$CLJS.sE,e,$CLJS.oE,$CLJS.X_.j(a,b,c),$CLJS.pB,$CLJS.B0.j(a,b,c),$CLJS.OK,$CLJS.BZ],null)});$CLJS.w0.m(null,$CLJS.hB,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.w0.m(null,$CLJS.fB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.w0.m(null,$CLJS.gB,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.w0.m(null,$CLJS.wB,function(a,b,c){return $CLJS.p.h(c)});$CLJS.w0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});$CLJS.x0.m(null,$CLJS.mB,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);return $CLJS.I(c,2,null)});
for(var W0=!1,Pna=new $CLJS.h(null,4,[$CLJS.Xr,"+",$CLJS.it,"-",$CLJS.Yr,"×",$CLJS.EG,"÷"],null),b1=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Xr,$CLJS.it,$CLJS.EG,$CLJS.Yr],null)),c1=null,d1=0,e1=0;;)if(e1<d1){var Qna=c1.X(null,e1);$CLJS.rF(Qna,Z0);e1+=1}else{var f1=$CLJS.y(b1);if(f1){var g1=f1;if($CLJS.Ad(g1)){var h1=$CLJS.lc(g1),Rna=$CLJS.mc(g1),Sna=h1,Tna=$CLJS.D(h1);b1=Rna;c1=Sna;d1=Tna}else{var Una=$CLJS.A(g1);$CLJS.rF(Una,Z0);b1=$CLJS.B(g1);c1=null;d1=0}e1=0}else break}
$CLJS.w0.m(null,Z0,function(a,b,c){var d=$CLJS.y(c);c=$CLJS.A(d);d=$CLJS.B(d);$CLJS.A(d);d=$CLJS.B(d);c=$CLJS.J.g(Pna,c);a:{var e=W0;W0=!0;try{var f=$CLJS.At([" ",$CLJS.gh(c)," "].join(""),$CLJS.hf.g($CLJS.$t($CLJS.X_,a,b),d));break a}finally{W0=e}f=void 0}return X0(f)});$CLJS.x0.m(null,Z0,function(){return"expression"});
$CLJS.A0.m(null,$CLJS.AH,function(a,b,c){c=$CLJS.y(c);$CLJS.A(c);c=$CLJS.B(c);$CLJS.A(c);var d=$CLJS.B(c);return $CLJS.Qd($CLJS.tF,function(){return function k(f){return new $CLJS.ne(null,function(){for(;;){var l=$CLJS.y(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.lc(l),t=$CLJS.D(m),u=$CLJS.qe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.kd(m,v);x=$CLJS.B0.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.te($CLJS.ve(u),k($CLJS.mc(l))):$CLJS.te($CLJS.ve(u),null)}u=$CLJS.A(l);return $CLJS.ee($CLJS.B0.j(a,
b,u),k($CLJS.Lc(l)))}return null}},null,null)}(d)}())});$CLJS.w0.m(null,$CLJS.hJ,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null),f=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.X_.v(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.P0.g(f,c).toLowerCase();0<f?f=$CLJS.fa.j?$CLJS.fa.j("+ %d %s",f,c):$CLJS.fa.call(null,"+ %d %s",f,c):(f=$CLJS.xA(f),f=$CLJS.fa.j?$CLJS.fa.j("- %d %s",f,c):$CLJS.fa.call(null,"- %d %s",f,c));f=X0(f);return[a," ",d.call(b,f)].join("")});
$CLJS.x0.m(null,$CLJS.hJ,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null),e=$CLJS.I(c,3,null);c=$CLJS.I(c,4,null);a=$CLJS.p.h($CLJS.y0.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.P0.g(e,c).toLowerCase();0<e?e=$CLJS.fa.j?$CLJS.fa.j("plus_%s_%s",e,c):$CLJS.fa.call(null,"plus_%s_%s",e,c):(e=$CLJS.xA(e),e=$CLJS.fa.j?$CLJS.fa.j("minus_%d_%s",e,c):$CLJS.fa.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.w0.m(null,$CLJS.mG,function(a,b,c,d){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var e=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.X_.v(a,b,e,d)});$CLJS.x0.m(null,$CLJS.mG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);var d=$CLJS.I(c,2,null);$CLJS.I(c,3,null);return $CLJS.y0.j(a,b,d)});
$CLJS.Vna=function(){function a(d,e,f,k){return $CLJS.OW.l(d,$CLJS.n(e)?e:-1,Ona,$CLJS.H([$CLJS.xW($CLJS.pW.h(k),f)]))}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();
$CLJS.Wna=function(){function a(d,e){var f=$CLJS.Fe($CLJS.YP.h($CLJS.DW(d,e)));return null==f?null:$CLJS.Tk.g($CLJS.$t(Y0,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.i1=function(){function a(d,e){return $CLJS.Fe($CLJS.YP.h($CLJS.DW(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.mW.m(null,$CLJS.mB,function(a){return a});
$CLJS.Xna=function(){function a(d,e,f){var k=$CLJS.eg.g($CLJS.N,$CLJS.fm(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wW(t),m],null)},$CLJS.i1.g(d,e))),l=$CLJS.DW(d,e);d=$CLJS.H0.j(d,e,l);return $CLJS.Fe($CLJS.V0(function(m){return $CLJS.Va(f)||$CLJS.Pk.g($CLJS.OK.h(m),$CLJS.BZ)||$CLJS.J.g(k,$CLJS.T.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.Yna=function(){function a(d,e,f){return $CLJS.oW(Y0(d,e,a1.j(d,e,f)))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();