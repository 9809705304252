var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var V1,hoa,a2,b2,d2,ioa,joa,koa,loa,moa,ooa,poa,qoa,noa,g2,h2;V1=function(a,b){var c=$CLJS.Gd,d=U1;for(a=$CLJS.y(a);;)if(null!=a){d=$CLJS.J.j(d,$CLJS.A(a),c);if(c===d)return b;a=$CLJS.B(a)}else return d};$CLJS.W1=function(a,b,c){return null==c||$CLJS.Id(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.X1=function(a,b){return $CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.AE,$CLJS.p.h($CLJS.KE())],null)],null),$CLJS.hf.h($CLJS.pW),b)};
$CLJS.Y1=function(a,b){a=$CLJS.CW(a,b);return 0<a?a-1:null};$CLJS.Z1=function(a,b){if($CLJS.y(b)){if($CLJS.E.g($CLJS.D(b),1))return $CLJS.A(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.E.g($CLJS.D(b),2)?[$CLJS.p.h($CLJS.A(b)),a,$CLJS.p.h($CLJS.hd(b))].join(""):[$CLJS.At(", ",$CLJS.xt(b)),",",a,$CLJS.p.h($CLJS.id(b))].join("")}return null};
$CLJS.$1=function(a,b,c,d){a=$CLJS.BW(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.DW(a,b);e=!($CLJS.y($CLJS.ZF.h(e))||$CLJS.y($CLJS.YF.h(e)));c=$CLJS.OW.l(a,b,$CLJS.Uk,$CLJS.H([c,function(f){return $CLJS.be.g($CLJS.Df(f),$CLJS.pW.h(d))}]));return e?$CLJS.Uk.N((0,$CLJS.OW)(c,b,function(f){return $CLJS.SW($CLJS.Jk.l(f,$CLJS.NR,$CLJS.H([$CLJS.TE])),$CLJS.CN,function(k){return $CLJS.Tk.g(function(l){return $CLJS.Jk.g(l,$CLJS.TE)},k)})}),$CLJS.pL,$CLJS.Rk.g(function(f){return $CLJS.eg.g($CLJS.Cf,f)},$CLJS.Yk),0,
$CLJS.CW(a,b)+1):c};hoa=function(a,b){b=$CLJS.T_(a,b);return $CLJS.n(b)?$CLJS.G1(a,b):null};a2=function(a){return $CLJS.eg.j($CLJS.bh,$CLJS.Sk.h(function(b){return $CLJS.me(b)&&$CLJS.Pk.g(b,$CLJS.Xt)}),$CLJS.Xg(a))};b2=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.im(function(b,c){$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.AE);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.ZF.h(a))};
$CLJS.c2=function(a,b,c){var d=$CLJS.il.l;b=$CLJS.Va($CLJS.Y1(a,b))?function(){var f=$CLJS.MV.h($CLJS.A($CLJS.pL.h(a)));return $CLJS.n(f)?(f=hoa(a,f),$CLJS.n(f)?$CLJS.tH(function(k){return $CLJS.E.g($CLJS.$i.h(k),c)},f):null):null}():null;try{var e=$CLJS.s1(a,c)}catch(f){e=null}return d.call($CLJS.il,$CLJS.H([b,e]))};
d2=function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null),d=$CLJS.O(c);c=$CLJS.J.g(d,$CLJS.sP);d=$CLJS.J.g(d,$CLJS.DN);$CLJS.I(a,2,null);a=$CLJS.n(d)?$CLJS.E.g(d,$CLJS.$K.h(b)):d;return $CLJS.n(a)?a:$CLJS.E.g($CLJS.yA($CLJS.VZ,$CLJS.TZ)(b),c)};
ioa=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var c=$CLJS.I(a,2,null),d=$CLJS.Fe($CLJS.lf(function(e){var f=$CLJS.E.g($CLJS.HZ.h(e),c);return f?d2(a,e):f},b));return $CLJS.n(d)?d:$CLJS.lf(function(e){var f=$CLJS.E.g($CLJS.T.h(e),c);return f?d2(a,e):f},b)};
joa=function(a,b,c){$CLJS.I(a,0,null);var d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.Fe($CLJS.lf(function(k){var l=$CLJS.E.g($CLJS.$i.h(k),e);if(l){if(l=$CLJS.Va($CLJS.sP.h(d))){l=$CLJS.OK.h(k);var m=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.KZ,null,$CLJS.PZ,null],null),null);l=m.h?m.h(l):m.call(null,l)}return $CLJS.n(l)?l:d2(a,k)}return l},b));if($CLJS.n(f))return f;b=$CLJS.n(c)?$CLJS.Fe($CLJS.lf(function(k){return $CLJS.E.g($CLJS.$i.h(k),e)},b)):null;return $CLJS.n(b)?b:$CLJS.Cf};
koa=function(a){var b=e2.g($CLJS.OK.h(a),$CLJS.BZ);return $CLJS.n(b)?b:$CLJS.sE.h(a)};loa=function(a,b){b=$CLJS.Fe($CLJS.Sk.g($CLJS.$K,b));if($CLJS.n(b))if($CLJS.Va($CLJS.B(b)))a=$CLJS.A(b);else{if($CLJS.n(e2.g($CLJS.A(a),$CLJS.jG))){var c=$CLJS.Fe($CLJS.Sk.g(koa,b));c=$CLJS.n(c)?$CLJS.B(c)?null:$CLJS.A(c):null}else c=null;a=$CLJS.n(c)?c:console.warn($CLJS.hi("Ambiguous match! Implement more logic in disambiguate-matches.",new $CLJS.h(null,2,[$CLJS.W,a,$CLJS.AW,b],null)))}else a=null;return a};
moa=function(a,b){b=$CLJS.Fe($CLJS.Sk.g(function(c){var d=$CLJS.yA($CLJS.VZ,$CLJS.TZ)(c);$CLJS.n(d)?(c=$CLJS.OK.h(c),d=new $CLJS.ah(null,new $CLJS.h(null,1,[$CLJS.KZ,null],null),null),c=d.h?d.h(c):d.call(null,c),c=$CLJS.Va(c)):c=d;return c},b));return $CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):loa(a,b):null};
ooa=function(a,b){var c=$CLJS.HE(a);c=$CLJS.O(c);var d=$CLJS.J.g(c,$CLJS.sP);return $CLJS.n(d)?(b=$CLJS.Fe($CLJS.lf(function(e){return $CLJS.E.g($CLJS.yA($CLJS.VZ,$CLJS.TZ)(e),d)},b)),$CLJS.n(b)?$CLJS.Va($CLJS.B(b))?$CLJS.A(b):console.warn("Multiple plausible matches with the same :join-alias - more disambiguation needed",new $CLJS.h(null,2,[$CLJS.W,a,noa,b],null)):null):moa(a,b)};poa=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
qoa=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);$CLJS.f2=new $CLJS.M(null,"generous?","generous?",1247836118);$CLJS.roa=new $CLJS.M(null,"matching-refs","matching-refs",324693611);noa=new $CLJS.M(null,"matches","matches",635497998);g2=new $CLJS.M(null,"left","left",-399115937);h2=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var e2=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.eC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.cC(f);k=$CLJS.cC(k);return $CLJS.Pk.g(f,k)?h2:f},e,a,b,c,d)}();e2.m(null,h2,function(){return!1});
e2.m(null,$CLJS.lB,function(a,b){var c=a2(a),d=a2(b);return $CLJS.E.g(c,d)&&$CLJS.Qe(function(e){return e2.g($CLJS.J.g(a,e),$CLJS.J.g(b,e))},c)});e2.m(null,$CLJS.jB,function(a,b){var c=$CLJS.E.g($CLJS.D(a),$CLJS.D(b));if(c)for(c=$CLJS.y(a),$CLJS.A(c),$CLJS.B(c),c=$CLJS.y(b),$CLJS.A(c),$CLJS.B(c),c=a,a=b;;){c=$CLJS.y(c);b=$CLJS.A(c);var d=$CLJS.B(c);c=b;b=d;a=$CLJS.y(a);d=$CLJS.A(a);a=$CLJS.B(a);c=e2.g(c,d);if($CLJS.n(c)){if(c=$CLJS.td(b))return c;c=b}else return c}else return c});var U1=null;
e2.m(null,$CLJS.TV,function(a,b){var c=U1;U1=new $CLJS.h(null,2,[g2,b2(a),$CLJS.tx,b2(b)],null);try{var d=$CLJS.mI(e2,$CLJS.lB);return d.g?d.g(a,b):d.call(null,a,b)}finally{U1=c}});
e2.m(null,$CLJS.ZF,function(a,b){var c=$CLJS.I(a,0,null),d=$CLJS.I(a,1,null),e=$CLJS.I(a,2,null),f=$CLJS.I(b,0,null),k=$CLJS.I(b,1,null),l=$CLJS.I(b,2,null);return(a=$CLJS.E.l(3,$CLJS.D(a),$CLJS.H([$CLJS.D(b)])))?(c=$CLJS.E.g(c,f))?(d=e2.g(d,k),$CLJS.n(d)?$CLJS.n(U1)?$CLJS.E.g(V1(new $CLJS.P(null,2,5,$CLJS.Q,[g2,e],null),qoa),V1(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tx,l],null),poa)):$CLJS.E.g(e,l):d):c:a});
e2.m(null,$CLJS.ci,function(a,b){if($CLJS.xd(a)){var c=$CLJS.mI(e2,$CLJS.lB);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.wd(a)?(c=$CLJS.mI(e2,$CLJS.jB),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.E.g(a,b)});
$CLJS.i2=function(){function a(f,k,l,m,t){var u=$CLJS.vW(l)?l:$CLJS.oW(l);$CLJS.I(u,0,null);$CLJS.I(u,1,null);var v=$CLJS.I(u,2,null),x=e.j?e.j(u,m,t):e.call(null,u,m,t);return $CLJS.n(x)?x:$CLJS.n($CLJS.n(f)?"number"===typeof v:f)&&(m=$CLJS.Fe($CLJS.Sk.g($CLJS.$i,m)),$CLJS.n(m)&&(k=$CLJS.vW(l)?$CLJS.c2(f,k,v):l,$CLJS.n(k)))?(f=$CLJS.R.j,l=$CLJS.HZ.h(k),k=$CLJS.n(l)?l:$CLJS.T.h(k),u=$CLJS.JE(f.call($CLJS.R,u,2,k),$CLJS.Ve($CLJS.il,new $CLJS.h(null,1,[$CLJS.pB,$CLJS.Bj],null))),e.j?e.j(u,m,t):e.call(null,
u,m,t)):null}function b(f,k,l,m){return e.N?e.N(f,k,l,m,$CLJS.N):e.call(null,f,k,l,m,$CLJS.N)}function c(f,k,l){var m=$CLJS.I(f,0,null);$CLJS.I(f,1,null);var t=$CLJS.I(f,2,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.f2);switch(m instanceof $CLJS.M?m.T:null){case "aggregation":return $CLJS.tH(function(u){return $CLJS.E.g($CLJS.OK.h(u),$CLJS.iL)&&$CLJS.E.g($CLJS.$0.h(u),t)},k);case "expression":case "field":switch(k="string"===typeof t?ioa(f,k):joa(f,k,l),$CLJS.D(k)){case 0:return null;case 1:return $CLJS.A(k);
default:return ooa(f,k)}default:throw $CLJS.hi("Unknown type of ref",new $CLJS.h(null,1,[$CLJS.W,f],null));}}function d(f,k){return e.j?e.j(f,k,$CLJS.N):e.call(null,f,k,$CLJS.N)}var e=null;e=function(f,k,l,m,t){switch(arguments.length){case 2:return d.call(this,f,k);case 3:return c.call(this,f,k,l);case 4:return b.call(this,f,k,l,m);case 5:return a.call(this,f,k,l,m,t)}throw Error("Invalid arity: "+arguments.length);};e.g=d;e.j=c;e.v=b;e.N=a;return e}();
$CLJS.soa=function(){function a(d,e,f,k){return $CLJS.i2.v(d,e,$CLJS.rZ.j(d,e,f),k)}function b(d,e,f){return c.v?c.v(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();