var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var AL,CL,EL,HL,ML;$CLJS.xL=function(a){return $CLJS.Wh($CLJS.q($CLJS.eC),a,$CLJS.nG)};$CLJS.yL=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);$CLJS.zL=new $CLJS.M(null,"object-id","object-id",-754527291);AL=new $CLJS.M("metabase.lib.schema.ref","aggregation-options","metabase.lib.schema.ref/aggregation-options",-15566487);$CLJS.BL=new $CLJS.M(null,"operators","operators",-2064102509);
CL=new $CLJS.M("metabase.lib.schema.ref","field.options","metabase.lib.schema.ref/field.options",408316031);$CLJS.DL=new $CLJS.M(null,"stage-number","stage-number",-1752729638);EL=new $CLJS.M("metabase.lib.schema.ref","field.literal","metabase.lib.schema.ref/field.literal",-1228060748);$CLJS.FL=new $CLJS.M(null,"min-value","min-value",-1119123315);$CLJS.GL=new $CLJS.M(null,"dimensions","dimensions",-254818097);
HL=new $CLJS.M("metabase.lib.schema.ref","field.id","metabase.lib.schema.ref/field.id",-956546909);$CLJS.IL=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.JL=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.KL=new $CLJS.M(null,"max-value","max-value",687805168);$CLJS.LL=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
ML=new $CLJS.M("metabase.lib.schema.ref","field.literal.options","metabase.lib.schema.ref/field.literal.options",-860446668);$CLJS.X(CL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.yE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pG,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.gE],null)],null)],null));$CLJS.X(ML,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,CL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pB,$CLJS.CE],null)],null)],null));$CLJS.X(EL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.jG],null),ML,$CLJS.tE],null));
$CLJS.X(HL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.jG],null),CL,$CLJS.gL],null));
$CLJS.JG.g($CLJS.jG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.jG],null),CL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.gL,$CLJS.tE],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,2,[$CLJS.Pi,function(a){return $CLJS.wd(a)?($CLJS.I(a,0,null),$CLJS.I(a,1,null),a=$CLJS.I(a,2,null),$CLJS.cC(a)):null},$CLJS.pt,"Invalid :field clause ID or name: must be a string or integer"],null),new $CLJS.P(null,2,
5,$CLJS.Q,[$CLJS.hB,HL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gB,EL],null)],null)],null));$CLJS.rF($CLJS.jG,$CLJS.nG);$CLJS.NE.m(null,$CLJS.jG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Fi,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.$E});$CLJS.yF($CLJS.mB,$CLJS.H([$CLJS.tE]));$CLJS.NE.m(null,$CLJS.mB,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Fi,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.$E});
$CLJS.rF($CLJS.mB,$CLJS.nG);$CLJS.X(AL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.yE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.T,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.tE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.tE],null)],null)],null));$CLJS.JG.g($CLJS.ZF,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.ZF],null),AL,$CLJS.ak],null));
$CLJS.NE.m(null,$CLJS.ZF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.yA($CLJS.Fi,$CLJS.pB)(b);return $CLJS.n(a)?a:$CLJS.$E});$CLJS.rF($CLJS.ZF,$CLJS.nG);$CLJS.yF($CLJS.IF,$CLJS.H([$CLJS.it,$CLJS.Mj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PK],null)],null)]));$CLJS.rF($CLJS.IF,$CLJS.nG);
$CLJS.yF($CLJS.eG,$CLJS.H([$CLJS.it,$CLJS.$E,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NK],null)],null)]));$CLJS.rF($CLJS.eG,$CLJS.nG);
$CLJS.X($CLJS.nG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,$CLJS.PE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.rt,function(){return["Valid reference, must be one of these clauses: ",$CLJS.At(", ",$CLJS.VG($CLJS.Jd,$CLJS.ml.g($CLJS.q($CLJS.eC),$CLJS.nG)))].join("")}],null),function(a){a=$CLJS.I(a,0,null);return $CLJS.xL(a)}],null)],null));