var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var GV,IV,JV,Uka,NV,OV,PV,Wka,QV,RV,SV,UV,VV,WV,XV,YV,ZV,aW,bW,cW,dW,eW,hW,iW,Vka;GV=function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Sk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.me(c)}),a)};
$CLJS.HV=function(a){var b=2>$CLJS.D(a);return b?b:$CLJS.Se.g($CLJS.wC,function(){return function e(d){return new $CLJS.ne(null,function(){for(;;){var f=$CLJS.y(d);if(f){var k=f;if($CLJS.Ad(k)){var l=$CLJS.lc(k),m=$CLJS.D(l),t=$CLJS.qe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.kd(l,v);$CLJS.ue(t,$CLJS.JE(x,function(){return function(z){return $CLJS.Jk.l(GV(z),$CLJS.pB,$CLJS.H([$CLJS.Fi]))}}(v,x,l,m,t,k,f,b)));v+=1}else return!0}()?$CLJS.te($CLJS.ve(t),e($CLJS.mc(k))):$CLJS.te($CLJS.ve(t),
null)}var u=$CLJS.A(k);return $CLJS.ee($CLJS.JE(u,function(){return function(v){return $CLJS.Jk.l(GV(v),$CLJS.pB,$CLJS.H([$CLJS.Fi]))}}(u,k,f,b)),e($CLJS.Lc(k)))}return null}},null,null)}(a)}())};
IV=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Cf,a],null)],null);for(var c=$CLJS.Cf;;){var d=$CLJS.rd(a);if($CLJS.n(d)){var e=d,f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null),l=e,m=$CLJS.sd(a);a=function(t,u,v,x,z,C,G,K){return function(S){return $CLJS.eg.j(v,$CLJS.hf.h(function(V,Z,ha,ra,Na){return function(zb){var Pa=$CLJS.I(zb,0,null);zb=$CLJS.I(zb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.be.g(Na,Pa),zb],null)}}(t,u,v,x,z,C,G,K)),S)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.be.g(c,l)):a=$CLJS.xd(k)?a(k):$CLJS.wd(k)?a($CLJS.fm($CLJS.Js,k)):m}else return c}};JV=function(a,b,c){return $CLJS.zd(c)&&$CLJS.E.g(a,$CLJS.A(c))&&!$CLJS.Id(b,$CLJS.J.g(c,2))};$CLJS.KV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Rk.g($CLJS.sE,$CLJS.hd)),$CLJS.YP.h(a));return IV($CLJS.Jk.l(a,$CLJS.CN,$CLJS.H([$CLJS.mV])),function(c){return JV($CLJS.mB,b,c)})};
$CLJS.LV=function(a){var b=$CLJS.eg.j($CLJS.bh,$CLJS.hf.h($CLJS.Rk.g($CLJS.AE,$CLJS.hd)),$CLJS.ZF.h(a));return IV($CLJS.Jk.l(a,$CLJS.CN,$CLJS.H([$CLJS.mV])),function(c){return JV($CLJS.ZF,b,c)})};Uka=function(a){function b(d){return $CLJS.jf(c,$CLJS.H([$CLJS.CN.h(d)]))}function c(d){return $CLJS.ee($CLJS.XE.h(d),$CLJS.jf(b,$CLJS.H([$CLJS.pL.h(d)])))}return $CLJS.n($CLJS.MV.h(a))?$CLJS.Ue(!0):$CLJS.fh(b(a))};
NV=function(a){if($CLJS.wd(a))for(var b=$CLJS.Ue(!1),c=$CLJS.y(a),d=$CLJS.A(c),e=$CLJS.B(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.y(u),x=$CLJS.A(v),z=$CLJS.B(v),C=x,G=z,K=$CLJS.yA(m,Uka(C));f=$CLJS.A($CLJS.y($CLJS.lf($CLJS.Wa,function(S,V,Z,ha,ra,Na){return function fb(Pa,Za){try{if($CLJS.zd(Za)&&3===$CLJS.D(Za))try{var Ua=$CLJS.F(Za,0);if($CLJS.he(Ua,$CLJS.jG))try{var mb=$CLJS.F(Za,1);if($CLJS.n($CLJS.sP.h(mb)))try{var Ja=$CLJS.F(Za,1);if(null!=Ja?Ja.C&256||$CLJS.Bc===Ja.mf||(Ja.C?0:$CLJS.$a($CLJS.wb,
Ja)):$CLJS.$a($CLJS.wb,Ja))try{var bc=$CLJS.J.j(Ja,$CLJS.sP,$CLJS.BV);if($CLJS.n($CLJS.Te(ha)(bc))){var $d=$CLJS.J.g(Ja,$CLJS.sP);$CLJS.F(Za,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(Na),": no join named ",$CLJS.Mh.l($CLJS.H([$d]))].join("")],null)}throw $CLJS.Y;}catch(qd){if(qd instanceof Error){var Hd=qd;if(Hd===$CLJS.Y)throw $CLJS.Y;throw Hd;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Hd=qd;if(Hd===$CLJS.Y)throw $CLJS.Y;throw Hd;
}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Hd=qd;if(Hd===$CLJS.Y)throw $CLJS.Y;throw Hd;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Hd=qd;if(Hd===$CLJS.Y)throw $CLJS.Y;throw Hd;}throw qd;}else throw $CLJS.Y;}catch(qd){if(qd instanceof Error){Hd=qd;if(Hd===$CLJS.Y)return $CLJS.yV(fb,Pa,Za);throw Hd;}throw qd;}}}(f,k,l,K,m,t,u,v,x,z,C,G,b,0,a,a,c,d,e,d,e)($CLJS.Cf,$CLJS.Jk.l(C,$CLJS.CN,$CLJS.H([Vka]))))));if($CLJS.n(f))return f;if($CLJS.y(G))k=t+1,f=K,l=G;
else return null}else return null};OV=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);PV=new $CLJS.r("metabase.lib.schema.util","distinct-refs?","metabase.lib.schema.util/distinct-refs?",-262561159,null);Wka=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);QV=new $CLJS.r(null,"distinct-refs?","distinct-refs?",-2065255505,null);
RV=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);SV=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);$CLJS.TV=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);UV=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);
VV=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);WV=new $CLJS.r(null,"refs","refs",80480079,null);XV=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);YV=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);ZV=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);
$CLJS.$V=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);aW=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);bW=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);cW=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);dW=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);
eW=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.fW=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.MV=new $CLJS.M(null,"source-card","source-card",-1580820390);$CLJS.gW=new $CLJS.M(null,"filters","filters",974726919);hW=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);iW=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);
$CLJS.jW=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Vka=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.X(XV,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.$V],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hQ,$CLJS.Wa],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.Fl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kO,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.tE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FQ,new $CLJS.h(null,1,[$CLJS.Ks,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eV],null)],null)],null));$CLJS.X(bW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nG],null));
$CLJS.X(OV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),bW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"Breakouts must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.HV},PV,$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.qV,QV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[WV],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",
$CLJS.n($CLJS.HV)?$CLJS.HV.H:null]))],null)],null));
$CLJS.X(cW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,":fields must be distinct"],null),new $CLJS.Gc(function(){return $CLJS.HV},PV,$CLJS.Ig([$CLJS.Xi,$CLJS.T,$CLJS.qk,$CLJS.vi,$CLJS.Ui,$CLJS.oj,$CLJS.Ak,$CLJS.ui,$CLJS.lk,$CLJS.pk],[$CLJS.qV,QV,"metabase/lib/schema/util.cljc",21,1,64,64,$CLJS.U(new $CLJS.P(null,
1,5,$CLJS.Q,[WV],null)),"Is a sequence of `refs` distinct for the purposes of appearing in `:fields` or `:breakouts` (ignoring keys that\n  aren't important such as namespaced keys and type info)?",$CLJS.n($CLJS.HV)?$CLJS.HV.H:null]))],null)],null));$CLJS.X(dW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.IF],null),$CLJS.jk,$CLJS.ak],null)],null));
$CLJS.X(aW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.ao,1],null),dW],null));
var kW=$CLJS.yA(function(a){a=$CLJS.A($CLJS.KV(a));if($CLJS.n(a)){var b=$CLJS.zA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Mh.l($CLJS.H([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null},function(a){a=$CLJS.A($CLJS.LV(a));if($CLJS.n(a)){var b=$CLJS.zA(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.J.g(a,1))].join("")}return null});$CLJS.X(eW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.pt,"Valid references for a single query stage",$CLJS.rt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return kW(a)}],null),$CLJS.Te(kW)],null));
$CLJS.X($CLJS.jW,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.TV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.YF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),OV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZF,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.LG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TE,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),cW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gW,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),aW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NR,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vL],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RK],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MV,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Id(a,$CLJS.HQ)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,
new $CLJS.h(null,1,[$CLJS.pt,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Te($CLJS.hE($CLJS.dP,$CLJS.MV))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eW],null)],null));$CLJS.X(RV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.RK],null)],null)],null)],null));
$CLJS.X(SV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XK],null)],null)],null)],null));$CLJS.X(VV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SV],null)],null));
$CLJS.X(YV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Te($CLJS.yA($CLJS.dP,$CLJS.MV))],null)],null));$CLJS.X(ZV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,$CLJS.$V,$CLJS.TV],null));
$CLJS.X(Wka,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,ZV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.Xt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$V,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jW],null)],null)],null)],null));
$CLJS.X(iW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,ZV],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.Xt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$V,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TV,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VV],null)],null)],null)],null));$CLJS.X(UV,YV);
$CLJS.X(hW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,2,[$CLJS.pt,"Valid references for all query stages",$CLJS.rt,function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.Ej);return NV.h?NV.h(a):NV.call(null,a)}],null),$CLJS.Te(NV)],null));
$CLJS.X($CLJS.lL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,UV],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hW],null)],null));
$CLJS.X($CLJS.LL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.fW],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.YK,$CLJS.dL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.lL],null)],null)],null),$CLJS.Mka],null));