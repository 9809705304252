var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var BY,ema,DY,fma,gma,hma,FY,jma,kma,lma,mma,IY,JY,KY,LY,NY,oma,OY,PY,pma,qma,rma,HY,ima,QY,RY,nma,TY;BY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
ema=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.j?$CLJS.nj.j(k,l,m):$CLJS.nj.call(null,k,l,m),$CLJS.ol.j?$CLJS.ol.j(k,l,m):$CLJS.ol.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.g?$CLJS.nj.g(k,l):$CLJS.nj.call(null,k,l),$CLJS.ol.g?$CLJS.ol.g(k,l):$CLJS.ol.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.h?$CLJS.nj.h(k):$CLJS.nj.call(null,k),$CLJS.ol.h?$CLJS.ol.h(k):$CLJS.ol.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj.o?$CLJS.nj.o():$CLJS.nj.call(null),$CLJS.ol.o?$CLJS.ol.o():$CLJS.ol.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.nj,m,t,u,v),$CLJS.Se.N($CLJS.ol,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.CY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
DY=function(a){return $CLJS.PL($CLJS.jW)(a,$CLJS.Cf,$CLJS.Cf)};fma=function(a,b,c){return $CLJS.eb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.ZF,b),$CLJS.ft(2,2,c))};gma=function(a,b){var c=$CLJS.hm(a,$CLJS.FQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.zt.g?$CLJS.zt.g(c,b):$CLJS.zt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.FQ,b)}return a};hma=function(a){return $CLJS.gf.g($CLJS.KV(a),$CLJS.LV(a))};
$CLJS.EY=function(a,b){return $CLJS.vW(a)&&$CLJS.E.g($CLJS.A(a),b)};FY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ns,null,$CLJS.vl,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.uu(2,c):$CLJS.uu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?BY(2,c):BY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.tX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Sk.h($CLJS.Cl),l):$CLJS.xd(k)?$CLJS.FA($CLJS.Wa,l):l}):$CLJS.Jk.g(a,f)};
jma=function(a){return $CLJS.eb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return FY(b,ima,d)},a,hma(a))};
kma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.pL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(ema(),$CLJS.lf($CLJS.Rk.j(GY,$CLJS.A,$CLJS.ol),$CLJS.Rs.h(DY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=FY(d,f,k),m=$CLJS.IA($CLJS.Dy);if($CLJS.n($CLJS.HA("metabase.lib.convert",m))){var t=$CLJS.yB.l($CLJS.H([$CLJS.yz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.yB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.UL(DY(d));$CLJS.GA("metabase.lib.convert",
m,$CLJS.sW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.yz,z.call(x,$CLJS.H([C]))])),$CLJS.yB.l($CLJS.H([$CLJS.nz,$CLJS.tW($CLJS.A($CLJS.bu(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=jma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.pL.h(a))-1))return a;b+=1}else a=$CLJS.Uk.N(a,$CLJS.pL,$CLJS.R,b,d)}};
lma=function(a){var b=$CLJS.GW();return $CLJS.Tk.g(function(c){return $CLJS.E.g($CLJS.XE.h(c),"__join")?$CLJS.Uk.j(c,$CLJS.XE,b):c},a)};mma=function(a){return"string"===typeof $CLJS.dP.h(a)?$CLJS.Jk.g($CLJS.R.j(a,$CLJS.MV,$CLJS.EW($CLJS.dP.h(a))),$CLJS.dP):a};IY=function(a,b){var c=$CLJS.J.j(a,b,HY);if($CLJS.E.g(c,HY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[nma,a,$CLJS.Uw,b],null));return c};
JY=function(){return $CLJS.Sk.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};KY=function(a){return $CLJS.eg.j($CLJS.N,JY(),a)};LY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Rk.g(JY(),$CLJS.Sk.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Fi)})),a))};
NY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.MY),c);a=$CLJS.E.g(a,$CLJS.TF)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(LY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tM,a,b],null):a};oma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Rk.g($CLJS.hf.h(function(b){return $CLJS.AA(b,$CLJS.DB)}),$CLJS.hf.h($CLJS.MY)),$CLJS.AW.h(a))};
OY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.pL);a=$CLJS.A($CLJS.eb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.MY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.HQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.QO,oma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.mV.h(c)],null)},null,a));return $CLJS.n($CLJS.hQ.h(a))?$CLJS.xV(a,new $CLJS.h(null,1,[$CLJS.hQ,$CLJS.GF],null)):a};
PY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.SW(a,b,$CLJS.Rk.g($CLJS.MY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.SW(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xs],null),$CLJS.hf.h($CLJS.MY),e)}):d;return $CLJS.xV(a,$CLJS.Ee([b,c]))};pma=function(a){var b=$CLJS.MV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Jk.g(a,$CLJS.MV),$CLJS.dP,["card__",$CLJS.p.h(b)].join("")):a};
qma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);rma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);HY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);ima=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);QY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
RY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.SY=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);nma=new $CLJS.M(null,"m","m",1632677161);TY=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var GY,sma,tma,uma,vma,wma,xma,yma,zma,Ama,Bma;$CLJS.UY=$CLJS.N;$CLJS.VY=$CLJS.N;GY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.gW,null,$CLJS.TE,null,$CLJS.CN,null,$CLJS.YP,null,$CLJS.YF,null,$CLJS.NR,null,$CLJS.ZF,null],null),null);sma=$CLJS.Xe($CLJS.N);tma=$CLJS.Xe($CLJS.N);uma=$CLJS.Xe($CLJS.N);vma=$CLJS.Xe($CLJS.N);wma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.eC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.WY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.cC,wma,sma,tma,uma,vma);$CLJS.WY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.nW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.WY),d))}else b=a;return b});$CLJS.WY.m(null,$CLJS.fW,function(a){return a});
$CLJS.WY.m(null,$CLJS.TV,function(a){var b=$CLJS.WY.h($CLJS.ZF.h(a)),c=$CLJS.Fe($CLJS.Tk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.xW($CLJS.WY.h(t),u)},$CLJS.YP.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.im(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.AE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.im(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.AE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.VY,k=$CLJS.UY;$CLJS.VY=d;$CLJS.UY=e;try{var l=fma(mma(a),b,$CLJS.H([$CLJS.YP,c])),m=$CLJS.eb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Uk.j(t,u,$CLJS.WY)},l,$CLJS.Kk.l(GY,$CLJS.ZF,$CLJS.H([$CLJS.YP])));return $CLJS.n($CLJS.CN.h(m))?$CLJS.Uk.j(m,$CLJS.CN,lma):m}finally{$CLJS.UY=k,$CLJS.VY=f}});$CLJS.WY.m(null,$CLJS.$V,function(a){return gma(a,function(b){return $CLJS.SW(b,$CLJS.DF,$CLJS.WY)})});
$CLJS.WY.m(null,$CLJS.kL,function(a){a=$CLJS.Uk.j($CLJS.Uk.j(a,$CLJS.sL,$CLJS.WY),$CLJS.pL,$CLJS.WY);var b=$CLJS.n($CLJS.TE.h(a))?$CLJS.Uk.j(a,$CLJS.TE,function(c){return $CLJS.Ol(c)?$CLJS.Tk.g($CLJS.WY,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.XE.h(a))?$CLJS.R.j(b,$CLJS.XE,"__join"):b});$CLJS.WY.m(null,$CLJS.jB,function(a){return $CLJS.Tk.g($CLJS.WY,a)});
$CLJS.WY.m(null,$CLJS.lB,function(a){return $CLJS.n($CLJS.nj.h(a))?kma($CLJS.R.j($CLJS.Uk.j($CLJS.BW(a),$CLJS.pL,function(b){return $CLJS.Tk.g($CLJS.WY,b)}),$CLJS.SY,!0)):$CLJS.zt(a,$CLJS.WY)});$CLJS.WY.m(null,$CLJS.jG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,a,b],null))});
$CLJS.WY.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xV(a,new $CLJS.h(null,3,[$CLJS.dD,$CLJS.pB,$CLJS.CO,$CLJS.Wj,$CLJS.VR,$CLJS.vE],null));var c=$CLJS.R.j;var d=$CLJS.Fi.h(a);$CLJS.n(d)||(d=$CLJS.pB.h(a),d=$CLJS.n(d)?d:$CLJS.OE(b));a=c.call($CLJS.R,a,$CLJS.Fi,d);return $CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,a,b],null))});
$CLJS.WY.m(null,$CLJS.TF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,$CLJS.Jk.g(c,$CLJS.ci),$CLJS.Tk.g($CLJS.WY,b)],null);b=$CLJS.nW(b);return null!=a?$CLJS.be.g(b,$CLJS.WY.h(a)):b});$CLJS.WY.m(null,$CLJS.mB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.WY.m(null,$CLJS.ZF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,IY($CLJS.VY,c)],null))});$CLJS.WY.m(null,$CLJS.tM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.WY.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.il.l($CLJS.H([c,a]))],null),d)});xma=$CLJS.Xe($CLJS.N);yma=$CLJS.Xe($CLJS.N);
zma=$CLJS.Xe($CLJS.N);Ama=$CLJS.Xe($CLJS.N);Bma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.ij,$CLJS.eC],null),$CLJS.ij,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.MY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.cC,Bma,xma,yma,zma,Ama);
$CLJS.MY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.MY,d);a=LY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.MY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.zt(KY(a),$CLJS.MY):
a;return a});for(var XY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[TY,RY],null)),YY=null,ZY=0,$Y=0;;)if($Y<ZY){var Cma=YY.X(null,$Y);$CLJS.rF(Cma,QY);$Y+=1}else{var aZ=$CLJS.y(XY);if(aZ){var bZ=aZ;if($CLJS.Ad(bZ)){var cZ=$CLJS.lc(bZ),Dma=$CLJS.mc(bZ),Ema=cZ,Fma=$CLJS.D(cZ);XY=Dma;YY=Ema;ZY=Fma}else{var Gma=$CLJS.A(bZ);$CLJS.rF(Gma,QY);XY=$CLJS.B(bZ);YY=null;ZY=0}$Y=0}else break}
for(var dZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Qx,$CLJS.dG,$CLJS.sG,$CLJS.PF,$CLJS.kk,$CLJS.xG,$CLJS.ao,$CLJS.VF,$CLJS.qG,$CLJS.yG,$CLJS.wG,$CLJS.DG],null)),eZ=null,fZ=0,gZ=0;;)if(gZ<fZ){var Hma=eZ.X(null,gZ);$CLJS.rF(Hma,TY);gZ+=1}else{var hZ=$CLJS.y(dZ);if(hZ){var iZ=hZ;if($CLJS.Ad(iZ)){var jZ=$CLJS.lc(iZ),Ima=$CLJS.mc(iZ),Jma=jZ,Kma=$CLJS.D(jZ);dZ=Ima;eZ=Jma;fZ=Kma}else{var Lma=$CLJS.A(iZ);$CLJS.rF(Lma,TY);dZ=$CLJS.B(iZ);eZ=null;fZ=0}gZ=0}else break}
for(var kZ=$CLJS.y($CLJS.yf([$CLJS.Xr,$CLJS.it,$CLJS.Yr,$CLJS.EG,$CLJS.TF,$CLJS.mG,$CLJS.KF,$CLJS.GG,$CLJS.BF,$CLJS.XF,$CLJS.FF,$CLJS.hG,$CLJS.oG,$CLJS.HF,$CLJS.YI,$CLJS.$I,$CLJS.Ey,$CLJS.UI,$CLJS.bJ,$CLJS.OI,$CLJS.fJ,$CLJS.TI,$CLJS.MI,$CLJS.eJ,$CLJS.mJ,$CLJS.iJ,$CLJS.SI,$CLJS.pJ,$CLJS.hJ,$CLJS.nJ,$CLJS.JF,$CLJS.CF,$CLJS.HG,$CLJS.YH,$CLJS.lG,$CLJS.Ox,$CLJS.zG,$CLJS.BG,$CLJS.IG,$CLJS.Ct,$CLJS.It],!0)),lZ=null,mZ=0,nZ=0;;)if(nZ<mZ){var Mma=lZ.X(null,nZ);$CLJS.rF(Mma,RY);nZ+=1}else{var oZ=$CLJS.y(kZ);
if(oZ){var pZ=oZ;if($CLJS.Ad(pZ)){var qZ=$CLJS.lc(pZ),Nma=$CLJS.mc(pZ),Oma=qZ,Pma=$CLJS.D(qZ);kZ=Nma;lZ=Oma;mZ=Pma}else{var Qma=$CLJS.A(pZ);$CLJS.rF(Qma,RY);kZ=$CLJS.B(pZ);lZ=null;mZ=0}nZ=0}else break}$CLJS.MY.m(null,QY,function(a){return NY(a)});$CLJS.MY.m(null,$CLJS.lB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Rk.g(JY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.MY.h(b)],null)})),a)});
$CLJS.MY.m(null,$CLJS.ZF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=LY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ZF,IY($CLJS.UY,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.rV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.MY.m(null,$CLJS.jB,function(a){return $CLJS.Tk.g($CLJS.MY,a)});$CLJS.MY.m(null,$CLJS.jG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jG,$CLJS.MY.h(a),LY(b)],null)});
$CLJS.MY.m(null,$CLJS.Ej,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=LY($CLJS.xV(b,new $CLJS.h(null,3,[$CLJS.pB,$CLJS.dD,$CLJS.Wj,$CLJS.CO,$CLJS.vE,$CLJS.VR],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ej,a,b],null)});
$CLJS.MY.m(null,$CLJS.kL,function(a){var b=KY(a);a=0==$CLJS.XE.h(a).lastIndexOf("__join",0)?$CLJS.Jk.g(b,$CLJS.XE):b;return $CLJS.il.l($CLJS.H([$CLJS.zt($CLJS.Jk.l(a,$CLJS.pL,$CLJS.H([$CLJS.sL])),$CLJS.MY),PY($CLJS.gm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.sL],null)),$CLJS.sL,$CLJS.PR),OY(a)]))});
$CLJS.MY.m(null,$CLJS.TV,function(a){var b=$CLJS.ZF.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.im(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.AE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.im(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.AE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.VY,e=$CLJS.UY;$CLJS.VY=c;$CLJS.UY=b;try{return $CLJS.eb(function(f,k){return $CLJS.SW(f,
k,$CLJS.MY)},PY($CLJS.SW($CLJS.SW(pma(KY(a)),$CLJS.ZF,function(f){return $CLJS.Tk.g(NY,f)}),$CLJS.YP,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.MY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wW(C),$CLJS.E.g($CLJS.Ej,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.MY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wW(x),$CLJS.E.g($CLJS.Ej,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.gW,$CLJS.OF),$CLJS.Kk.l(GY,$CLJS.ZF,$CLJS.H([$CLJS.gW,$CLJS.YP])))}finally{$CLJS.UY=e,$CLJS.VY=d}});$CLJS.MY.m(null,$CLJS.$V,function(a){return $CLJS.zt(KY(a),$CLJS.MY)});
$CLJS.MY.m(null,$CLJS.fW,function(a){try{var b=KY(a),c=$CLJS.tN.h(b),d=OY(b),e=$CLJS.E.g($CLJS.Xt.h($CLJS.id($CLJS.pL.h(a))),$CLJS.$V)?$CLJS.hQ:$CLJS.GF;return $CLJS.il.l($CLJS.H([$CLJS.Jk.l(b,$CLJS.pL,$CLJS.H([$CLJS.tN,$CLJS.SY])),function(){var k=$CLJS.Ee([$CLJS.nj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.tN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.rV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.GF,a],null),f);}});
$CLJS.rZ=function(){function a(d,e,f){f=$CLJS.AY(null,$CLJS.Lz(f,$CLJS.H([$CLJS.zi,!0])));var k=$CLJS.DW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.ZF);k=$CLJS.eg.j($CLJS.N,$CLJS.im(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.AE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.im(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.AE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.VY,t=$CLJS.UY;$CLJS.VY=k;$CLJS.UY=l;try{try{return $CLJS.WY.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.rV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.GF,d,$CLJS.DL,e,rma,f,qma,$CLJS.VY],null),u);}}finally{$CLJS.UY=t,$CLJS.VY=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();