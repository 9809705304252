var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var bV,cV,dV,fV,gV,hV,iV,jV,kV,lV;bV=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);cV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);dV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.eV=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);fV=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);gV=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);hV=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
iV=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);jV=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);kV=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);lV=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.X(bV,$CLJS.eg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ns,new $CLJS.h(null,1,[$CLJS.pt,"Valid template tag :widget-type"],null),$CLJS.dA],null),$CLJS.Xg($CLJS.XU)));$CLJS.X(fV,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ns,$CLJS.FM,$CLJS.uN,$CLJS.DF,$CLJS.Rt,$CLJS.uB,$CLJS.AP],null));
$CLJS.X(iV,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T,$CLJS.tE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oE,$CLJS.tE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$i,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.tE,$CLJS.Fs],null)],null)],null));
$CLJS.X(jV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Fl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WN,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.Gs],null)],null)],null));
$CLJS.X(gV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.DF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.aF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gj,new $CLJS.h(null,1,[$CLJS.Ks,!0],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ps,$CLJS.jk],null)],null)],null)],null));
$CLJS.X(hV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.FM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rM,$CLJS.tE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.fL],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TP,new $CLJS.h(null,1,[$CLJS.Ks,!0],null),$CLJS.YK],null)],null)],null));
$CLJS.X(lV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uk,$CLJS.uN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bR,$CLJS.XK],null)],null)],null));$CLJS.X(kV,$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ns],null),$CLJS.LU));
$CLJS.X(cV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kV],null)],null)],null)],null));
$CLJS.X(dV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fV],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.nj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uN,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lV],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bp,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cV],null)],null)],null)],null));
$CLJS.X($CLJS.eV,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vs,$CLJS.tE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,dV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Si,new $CLJS.h(null,1,[$CLJS.pt,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Qe(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.T.h(b))},a)}],null)],null));